import type { Ref } from '@vue/composition-api';

export enum CarouselMode {
  TIMER_ON_LOAD = 'timer_on_load',
  TIMER_ON_HOVER = 'timer_on_hover',
  DEFAULT = 'default',
}

export interface CarouselProps {
  screenLabel: string;
  snapThreshold?: number;
  mode?: CarouselMode,
  timerLimit?: number;
  slideWidthPercentage?: number;
  infiniteSlidesEnabled?: boolean;
  hideNavigation?: boolean;
  threeSlideView?: boolean;
  displayArrowNavigation?: boolean;
}

export interface CarouselParams {
  snapThreshold: number;
  infiniteSlidesEnabled?: boolean;
  slideWidthPercentage?: number;
  threeSlideView?: boolean;
}

export interface SliderOptions {
  ignoreInfiniteMode?: boolean,
}

export interface NavigationProps {
  slides: Slide[];
  currentSlide: number;
  timerEnabled: boolean;
  timerLimit?: number;
  isHidden?: boolean;
}

export interface NavigationArrowsProps {
  slides: Slide[];
  currentSlide: number;
  isHidden?: boolean;
}

export interface Slide {
  screenLabel: string;
}

export interface CarouselContext {
  currentIndex: Ref<number>;
  isThreeSlideView: boolean;
  getWidthPercentage(): number;
  startSlideDrag(slideId: number, event: Event): void;
  continueSlideDrag(event: Event): void;
  endSlideDrag(): void;
  slideToIndex(index: number, options?: SliderOptions): void;
}
