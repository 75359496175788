export default {
  title: 'FightCamp Family',
  id: 'fightcamp_family',
  price: 134900,
  oldPrice: 214500,
  monthly: 5621,
  months: 24,
  category: 'package',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-products-family-setup-001.jpg',
  description: 'Teamwork makes the dream work. This package is for those who intend to sweat in pairs #CoupleGoals',
  fbid: 'shopify_1921762820150_19070820319286',
  gmc_id: '2402226026519211',

};
