/* eslint-disable func-names */
import { VueConstructor } from 'vue';
import type { PluginObject } from 'vue/types/plugin';
import { SegmentOptions } from './types';
import { SegmentWrapper, getUserTraits } from './segment';
import { TrackView } from './track-view-element';
import { TrackClick } from './track-click';

declare global {
  interface Window {
    analytics: any;
  }
}

export { getUserTraits };

export const FCAnalytics: PluginObject<SegmentOptions> = {
  install(Vue: VueConstructor, options?: SegmentOptions): void {
    if (!options?.skipScriptInjection) {
      // eslint-disable-next-line no-multi-assign
      const analytics = window.analytics = window.analytics || [];

      if (!analytics.initialize) {
        if (analytics.invoked) {
          if (window.console && console.error) {
            console.error('Segment snippet included twice.');
          }
          return;
        }

        analytics.invoked = true;
        analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on', 'addSourceMiddleware', 'addIntegrationMiddleware', 'setAnonymousId', 'addDestinationMiddleware'];

        analytics.factory = function (method: string) {
          return function () {
            // eslint-disable-next-line prefer-rest-params
            const args = Array.prototype.slice.call(arguments);
            console.log(`[Segment Analytics Debug]: ${method} method called with ${args.length} args`);
            args.unshift(method);
            analytics.push(args);
            return analytics;
          };
        };

        for (const key of analytics.methods) {
          analytics[key] = analytics.factory(key);
        }

        analytics.load = function (loadOptions: object) {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.async = true;
          script.src = `https://cdn.segment.com/analytics.js/v1/${ options?.writeKey }/analytics.min.js`;

          const first: HTMLScriptElement = document.getElementsByTagName('script')[0];
          first?.parentNode?.insertBefore(script, first);
          analytics._loadOptions = loadOptions;
        };

        analytics._writeKey = options?.writeKey;
        analytics.SNIPPET_VERSION = '4.13.2';
        analytics.load(options);
      }
    }

    Vue.directive('track-click', TrackClick);
    Vue.directive('track-view', TrackView);

    Object.defineProperty(Vue.prototype, '$analytics', {
      get() { return SegmentWrapper; },
    });
  },
};

export function useAnalytics() {
  return SegmentWrapper;
}
