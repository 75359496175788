export default {
  title: 'FightCamp Tribe',
  id: 'fightcamp_tribe',
  price: 129900,
  affirmPrice: 228500,
  oldPrice: 134900,
  monthly: 5413,
  months: 36,
  category: 'package',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-products-family-setup-001.jpg',
  description: 'Teamwork makes the dream work. This package is for those who intend to sweat in pairs #CoupleGoals',
  fbid: 'shopify_1921762820150_19070820319286',
  gmc_id: '2402226026519211',
  path: '/shop/products/fightcamp-tribe/',
};
