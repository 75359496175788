<template>
  <div v-if="isTotalValid" class="five-stars fc-flex fc-align-center">
    <font-awesome-icon
      v-for="index in totalFilled"
      :key="`filled-star-${index}`"
      :icon="['fc', 'star']"
      :color="filledStarColor"
      :size="size" />
    <font-awesome-icon
      v-for="index in emptyStars"
      :key="`empty-star-${index}`"
      :icon="['fc', 'star']"
      color="#dddfe1"
      :size="size" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, PropType } from '@vue/composition-api';
  import { StarSize, FiveStarsProps } from './types';

  export default defineComponent({
    name: 'FiveStars',
    props: {
      totalFilled: {
        type: Number,
        default: 5,
        required: false,
      },
      size: {
        type: String as PropType<StarSize>,
        default: 'sm',
        required: false,
      },
      filledStarColor: {
        type: String,
        default: '#A82323',
        required: false,
      },
    },
    setup(props: FiveStarsProps) {
      const isTotalValid = computed(() => (props.totalFilled ? props.totalFilled >= 0 && props.totalFilled <= 5 : false));
      const emptyStars = computed(() => (props.totalFilled ? 5 - props.totalFilled : 5));

      return {
        emptyStars,
        isTotalValid,
      };
    },
  });
</script>

<style lang="scss" scoped>
.five-stars {
  svg {
    margin-right: 3px;
  }
}
</style>
