export enum VideoPlayback {
  auto = 'auto',
  onHover = 'onHover',
  onClick = 'onClick',
}

export enum VideoPreload {
  none = 'none',
  metadata = 'metadata',
  auto = 'auto',
}

export interface VideoProps {
  videoKey: string,
  firstFrameImageUrl: string,
  isOverlay: boolean,
  videoMuted: boolean,
  isBackground: boolean,
  videoPlayback: VideoPlayback,
  controls: boolean,
  hasPlayIcon: boolean,
  hasPauseIcon: boolean,
  rounded: boolean,
}
