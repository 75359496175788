export interface AsyncLoadParam {
  id: string;
  url: string;
  attributes?: { [key: string]: string };
  appendOnHead?: boolean;
}

export async function asyncLoadLibrary({
  id,
  url,
  attributes = {},
  appendOnHead,
}: AsyncLoadParam): Promise<void> {
  const hasScript = !!window.document.querySelector(`script[id = '${id}']`);

  if (hasScript) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    const script: HTMLScriptElement = window.document.createElement(
      'script',
    );

    script.async = true;
    script.id = id;
    script.src = url;
    Object.keys(attributes).forEach((key) => {
      script.setAttribute(key, attributes[key]);
    });

    script.onload = () => {
      setTimeout(resolve);
    };

    script.onerror = reject;

    if (appendOnHead) {
      window.document.head.appendChild(script);
    } else {
      window.document.body.appendChild(script);
    }
  });
}
