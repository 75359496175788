export const bundleProtectFloor = {
  title: 'FightCamp Protect Your Floor Bundle',
  id: 'fightcamp_bundle_protect_floor',
  price: 19900,
  category: 'accessory',
  description: 'Protect your home from scuffs & scrapes with heavy-duty flooring. Comes with 8 inter-locking tiles.',
  img_url: '',
  images: [
    'fc-bundle-mat-001.jpg',
    'fc-bundle-mat-002.jpg',
    'fc-bundle-mat-003.jpg',
  ],
  marketplace_img_url: 'v4/fc-bundle-mat.png',
  fbid: '',
};
