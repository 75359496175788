import { fightcampYogaMat } from './fightcamp_yoga_mat';

export const fightcampExerciseMatDeal = {
  title: 'Exercise Mat',
  id: 'fightcamp_exercise_mat_deal',
  price: 0,
  oldPrice: fightcampYogaMat.price,
  category: 'accessory',
  description: 'Lay out this premium exercise mat for comfortable floor workouts. 6ft long, 2 ft wide & a 1/2 in thick.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-yoga-mat-001.png',
  tag: 'Used In Workouts',
};
