//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, computed, watch } from '@vue/composition-api';
import SocialIcons from '../SocialIcons/SocialIcons.vue';
import { EMAIL_VALIDATOR } from './constants';

export default {
  name: 'EmailCapture',
  components: {
    SocialIcons,
  },
  props: {
    ctaText: {
      type: String,
      default: 'Subscribe',
    },
    icons: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    iconSize: {
      type: String,
      default: 'default',
      validator(iconSize) {
        return iconSize === 'default' || iconSize === 'big';
      },
    },
    isSubmitted: {
      type: Boolean,
      default: false,
    },
    isTabletUpColumnOrder: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const email = ref('');
    const internalErrors = ref([]);
    const allValidationErrors = computed(() => internalErrors.value.concat(props.errors || []));

    watch(() => props.isSubmitted, (newVal) => {
      if (newVal) {
        email.value = '';
      }
    });

    function handleSubmit(event) {
      event.preventDefault();
      if (isValidRegexEmail(email.value)) {
        internalErrors.value = [];
        context.emit('submitEmail', email.value);
      } else {
        internalErrors.value = ['invalid email'];
      }
    }

    return {
      email,
      allValidationErrors,
      handleSubmit,
      emailCaptureClass: computed(() => (props.isTabletUpColumnOrder ? 'email-capture__column-order' : 'fc-grid')),
    };
  },
};

function isValidRegexEmail(email) {
  return EMAIL_VALIDATOR.test(String(email).toLowerCase());
}
