const attribution = require('./utils/attribution');

const onlineArticle = {
  id: 'OnlineArticle',
  question: 'Who published the article / review?',
  type: 'input',
  attribution: attribution.PR,
};


module.exports = onlineArticle;
