export default {
  title: 'FightCamp Personal',
  id: 'fightcamp_personal',
  price: 99900,
  affirmPrice: 215500,
  oldPrice: 121900,
  monthly: 4163,
  months: 36,
  category: 'package',
  fbid: 'shopify_737157578806_8880210477110',
  gmc_id: '2377884125618561',
  path: '/shop/products/fightcamp-personal/',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-products-gym-setup-001.jpg',
};
