export enum AnimationMode {
  SLIDE = 'slide',
  FADE = 'fade',
  ZOOM_IN ='zoom-in'
}

export interface ScrollTransitionProps {
  animationType?: AnimationMode;
  threshold?: number[];
  rootMargin?: string;
}
