
import { defineComponent, toRef, reactive } from '@vue/composition-api';
import { useTab } from './useTabs';

export default defineComponent({
  name: 'Tab',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const name = toRef(props, 'name');

    const tabData = reactive({ name });
    const { isActive } = useTab(tabData);

    return {
      isActive,
    };
  },
});
