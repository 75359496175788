import { LOCAL_EXPERIMENT_OVERRIDE_KEY } from '../constants/localStorage';

export function runLocalToggleOverrideInitializeEffect(context, locationQueryString) {
  let sanitizedQuery = locationQueryString && locationQueryString[0] === '?' ? locationQueryString.slice(1) : locationQueryString;
  sanitizedQuery = sanitizedQuery && decodeURIComponent(sanitizedQuery);

  if (sanitizedQuery && !!process.env.NUXT_ENV_ENABLE_OVERRIDABLE_TOGGLES) {
    const toggleOverrideMap = parseExperimentToggles(sanitizedQuery);

    if (Object.keys(toggleOverrideMap).length) {
      window.sessionStorage.setItem(LOCAL_EXPERIMENT_OVERRIDE_KEY, JSON.stringify(toggleOverrideMap));

      Object.values(toggleOverrideMap).forEach((toggle) => {
        context.commit('REGISTER_EXPERIMENT', toggle);
      });
    }
  }
}

const experimentKeyRegex = /fc_experiment\[(.+)\]/;

function parseExperimentToggles(sanitizedQuery) {
  const searchParams = new URLSearchParams(sanitizedQuery);
  const toggleMap = {};

  searchParams.forEach((variant, key) => {
    const match = key.match(experimentKeyRegex);

    if (match) {
      const id = match[1];
      toggleMap[id] = { id, variant };
    }
  });

  return toggleMap;
}
