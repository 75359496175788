export const fightcampGloveFreshener = {
  title: 'Glove Freshener',
  id: 'fightcamp_glove_freshener',
  price: 1900,
  category: 'accessory',
  description: 'Gloves get sweaty. Keep your gloves fresh with sweat-absorbant pouches to combat odor.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-freshners-001.png',
  types: ['Care']
};
