















import {
  ref, computed, defineComponent, PropType,
} from '@vue/composition-api';
import { ValidColors } from './types';

export default defineComponent({
  name: 'AnnouncementSnackBar',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    ctaText: {
      type: String,
      default: '',
      required: false,
    },
    bgColor: {
      type: String as PropType<ValidColors>,
      default: 'red',
      required: false,
    },
    textColor: {
      type: String as PropType<ValidColors>,
      default: 'white',
      required: false,
    },
  },
  setup(props, context) {
    const active = ref<boolean>(true);

    function routeToPage(): void {
      context.emit('onRoutetoPage');
    }

    function closeBar(): void {
      active.value = !active.value;
    }

    return {
      active,
      routeToPage,
      closeBar,
      getAnnouncementSnackBarClasses: computed<string[]>(() => {
        const classes = [];

        if (active.value) {
          classes.push('announcement-snack-bar--active fc-flex fc-padding-x-5 fc-padding-y-2 fc-margin-x-1 fc-margin-y-1');
        }

        classes.push(`fc-bg-${props.bgColor}`);
        classes.push(`fc-text-${props.textColor}`);

        return classes;
      }),
      getTextColor: computed<string>(() => `fc-text-${props.textColor}`),
    };
  },
});
