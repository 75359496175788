import { ref, watchEffect } from '@vue/composition-api';
import {
  desktopBreakpoint,
  mobileBreakpoint,
  tabletOnlyBreakpoint,
  tabletUpBreakpoint,
  // @ts-ignore
} from '@fc/angie-ui/dist/styles/_js_exports.scss';

const match = (query: string) => {
  const matches = ref(false);

  if (process.client) {
    watchEffect((onCleanup: Function) => {
      const media = window.matchMedia(query);

      const onChange = () => {
        matches.value = media.matches;
      };

      onChange();

      if (media.addEventListener) {
        media.addEventListener('change', onChange);
      } else {
        media.addListener(onChange);
      }

      onCleanup(() => {
        if (media.removeEventListener) {
          media.removeEventListener('change', onChange);
        } else {
          media.removeListener(onChange);
        }
      });
    });
  }

  return matches;
};

// NOTE: This pattern provides singular point to get data from
const mobileMatch = match(mobileBreakpoint);
const tabletOnlyMatch = match(tabletOnlyBreakpoint);
const tabletUpMatch = match(tabletUpBreakpoint);
const desktopMatch = match(desktopBreakpoint);

export const isMobileEffect = () => mobileMatch;
export const isTabletOnlyEffect = () => tabletOnlyMatch;
export const isTabletUpEffect = () => tabletUpMatch;
export const isDesktopEffect = () => desktopMatch;
