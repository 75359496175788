import { PascalCasingRegex, RemoveUnwantedCharsRegex, SplitRegex } from './regex';

export function slugify(altText: string): string {
  const sanitizedText = altText.replace(RemoveUnwantedCharsRegex, '');

  return sanitizedText.split(SplitRegex)
    .map((possiblePascals) => possiblePascals.replace(PascalCasingRegex, '$1-$2'))
    .map((matchedWord) => matchedWord.toLowerCase())
    .join('-');
}

export function getFileExtension(fileName: string): string {
  return fileName.slice((Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1);
}
