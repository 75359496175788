export default {
  title: "FightCamp Men's Tank Black",
  id: 'fightcamp_mens_tank_black',
  price: 2200,
  category: 'accessory',
  description: 'Made with 100% combed cotton for a lightweight feel, this regular fit tank showcases the FightCamp logo in the center chest. Regular fit.',
  img_url: 'https://a.storyblok.com/f/152150/400x401/e3c8677207/preview-fc-logo-tank-black-001.jpg',
  fbid: '',
  marketplace_img_url: '',
  types: ['Apparel']
};