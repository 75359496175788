import products from '@/shared/datasets/products';

// THESE ARE PRODUCTS THAT REQUIRE SELECT OPTIONS OR NEED TO OVERRIDE TITLE/DESCRIPTION

export const accessoriesContent = [
  {
    ...products.glovesv2,
    selects: {
      name: ' Size - Pair 1',
      key: 'pair1',
      hint: 'Select size',
      options: [{
        key: 'customGloves_V2_S',
        name: 'Light (12 oz)',
      },
      {
        key: 'customGloves_V2_L',
        name: 'Heavy (16 oz)',
      },
      ],
    },
    title: 'Boxing Gloves',
    description: 'Our premium training gloves are 100% hand-made, ultra comfortable, and super durable. Light gloves are best for low-to-mid power strikes. Heavy gloves offer more padding & add weight for heavy hitters.',
  },
  {
    ...products.quickwrapsv2,
    selects: {
      name: ' Size - Pair 1',
      key: 'pair1',
      hint: 'Select size',
      options: [{
        key: 'quickWraps_V2_S',
        name: 'Small',
      },
      {
        key: 'quickWraps_V2_L',
        name: 'Large',
      },
      ],
    },
    title: 'Quick Wraps',
    description: 'These Quick Wraps are built to hold your Punch Trackers in place with wrist-wrapping & knuckle padding to protect your hands.',
  },
  {
    ...products.quickwraps3pack,
    selects: {
      name: ' Size - Pair 1',
      key: 'pair1',
      hint: 'Select sizes',
      options: [{
        key: 'quickWraps_3p_SSS',
        name: 'All Small',
      },
      {
        key: 'quickWraps_3p_LLL',
        name: 'All Large',
      },
      {
        key: 'quickWraps_3p_SSL',
        name: '2 Small + 1 Large',
      },
      {
        key: 'quickWraps_3p_LLS',
        name: '2 Large + 1 Small',
      },
      ],
    },
    title: 'FightCamp Quick Wraps (3-Pack)',
    description: 'Three times the fun! Stock up on the original FightCamp Quick-Wraps so you always have a fresh pair.',
  },
  {
    ...products.fightcampLogoHoodieCharcoalHeather,
    selects: {
      name: ' Size',
      key: 'pair1',
      hint: 'Select sizes',
      options: [{
        key: 'FC-FCHOL21HD003-S',
        name: 'Small',
      },
      {
        key: 'FC-FCHOL21HD003-M',
        name: 'Medium',
      },
      {
        key: 'FC-FCHOL21HD003-L',
        name: 'Large',
      },
      ],
    },
  },
  {
    ...products.fightcampLogoHoodieOatmeal,
    selects: {
      name: ' Size',
      key: 'pair1',
      hint: 'Select sizes',
      options: [{
        key: 'FC-FCHOL21HD001-XS',
        name: 'X-Small',
      },
      {
        key: 'FC-FCHOL21HD001-S',
        name: 'Small',
      },
      {
        key: 'FC-FCHOL21HD001-M',
        name: 'Medium',
      },
      {
        key: 'FC-FCHOL21HD001-L',
        name: 'Large',
      },
      ],
    },
  },
  {
    ...products.fightcampLogoTeeBlack,
    selects: {
      name: ' Size',
      key: 'pair1',
      hint: 'Select sizes',
      options: [{
        key: 'FC-FCHOL21SS001-XS',
        name: 'X-Small',
      },
      {
        key: 'FC-FCHOL21SS001-S',
        name: 'Small',
      },
      {
        key: 'FC-FCHOL21SS001-M',
        name: 'Medium',
      },
      {
        key: 'FC-FCHOL21SS001-L',
        name: 'Large',
      },
      {
        key: 'FC-FCHOL21SS001-XXL',
        name: 'XX-Large',
      },
      ],
    },
  },
  {
    ...products.fightcampMensTankBlack,
    selects: {
      name: ' Size',
      key: 'pair1',
      hint: 'Select sizes',
      options: [{
        key: 'FC-MCOLOURTNKBLK-S',
        name: 'Small',
      },
      {
        key: 'FC-MCOLOURTNKBLK-M',
        name: 'Medium',
      },
      {
        key: 'FC-MCOLOURTNKBLK-L',
        name: 'Large',
      },
      // Below are sold out
      // {
      //   key: 'FC-MCOLOURTNKBLK-XL',
      //   name: 'X-Large',
      // },
      // {
      //   key: 'FC-MCOLOURTNKBLK-XXL',
      //   name: 'XX-Large',
      // },
      ],
    },
  },
  {
    ...products.fightcampWomensTankBlack,
    selects: {
      name: ' Size',
      key: 'pair1',
      hint: 'Select sizes',
      options: [{
        key: 'FC-WBELLATNKBLK-M',
        name: 'Medium',
      },
      {
        key: 'FC-WBELLATNKBLK-L',
        name: 'Large',
      },
      {
        key: 'FC-WBELLATNKBLK-XL',
        name: 'X-Large',
      },
      ],
    },
  },
  {
    ...products.fightcampFullZipHoodie,
    selects: {
      name: ' Size',
      key: 'pair1',
      hint: 'Select sizes',
      options: [{
        key: 'FC-FCHOL21ZHD001-XS',
        name: 'X-Small',
      },
      {
        key: 'FC-FCHOL21ZHD001-S',
        name: 'Small',
      },
      {
        key: 'FC-FCHOL21ZHD001-M',
        name: 'Medium',
      },
      {
        key: 'FC-FCHOL21ZHD001-L',
        name: 'Large',
      },
      ],
    },
  },
];
