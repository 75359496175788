import Vue from 'vue';
import {
  AngieUI,
  ShortText,
  LinkButton,
  ProductFeatures,
  AnchorLinkButton,
} from '@fc/angie-ui';
import { config } from '@fortawesome/fontawesome-svg-core';

config.autoAddCss = false;

Vue.use(AngieUI, {
  skipCompositionApiInstall: true,
});

Vue.component(ShortText.name, ShortText);
Vue.component('FCLinkButton', LinkButton);
Vue.component('FCAnchorLinkButton', AnchorLinkButton);
Vue.component('FCProductFeatures', ProductFeatures);
