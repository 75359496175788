import product from '@/shared/datasets/products';

export const betterEveryDay = {
  title: 'Better Every Day Bundle',
  text: 'Push your workouts to the max & give yourself an added challenge.',
  product: product.bundleBetterEveryDay,
  img_url: 'v4/fc-bundle-better-every-day-001.png',
  img_alt: 'Set of FightCamp weighted quick wraps, a water bottle, and a heart rate monitor',
  item_id: 'fightcamp_bundle_better_every_day',
  sold_out: false,
  includes: [
    product.fightcampHeartRateMonitor,
    product.fightcampWeightedQuickWraps,
    product.fightcampWaterBottle,
  ],
  discount: 'SAVE 18%',
  types: ['Bundles'],
};
