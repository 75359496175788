import product from '@/shared/datasets/products';

export const putItOnTv = {
  title: 'Put It on TV Bundle',
  text: 'Display your workout on the big screen & get fully immersed in the FightCamp experience.',
  product: product.bundleTv,
  img_url: 'v4/fc-bundle-tv-002.png',
  img_alt: 'Cables to connect FightCamp to the tv',
  item_id: 'fightcamp_bundle_tv',
  sold_out: false,
  includes: [
    product.fightcampHdmiCable,
    product.fightcampShiAppleCable,
  ],
  discount: 'SAVE 5%',
  types: ['Bundles'],
};
