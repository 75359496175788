



























import {
  defineComponent, ref, computed, watch,
} from '@vue/composition-api';
import { useDrift } from '~/composables/useDrift';

export default defineComponent({
  name: 'DriftFacade',
  setup() {
    const loading = ref(false);

    // @ts-ignore
    const { $store } = useNuxtApp();
    const isVariant1 = computed(() => $store.getters.exp_020_chatMessageVariant === '1');
    const isVariant2 = computed(() => $store.getters.exp_020_chatMessageVariant === '2');
    const isControl = computed(() => $store.getters.exp_020_chatMessageVariant === '0');

    const displayIcon = ref(false);
    const displayMessage = ref(false);

    if (process.client) {
      // wait for analytics to be ready before showing the icon, this avoids a flash of the control
      if (window?.analytics) {
        window?.analytics?.ready(async () => {
          displayIcon.value = true;
          displayMessage.value = !!isControl.value; // display the message if is control and the analytics are ready
        });
      } else {
        // if analytics never load, wait 3 seconds then show the icon
        setTimeout(() => {
          displayIcon.value = true;
          displayMessage.value = !!isControl.value;
        }, 3000);
      }
    }

    watch(isControl, (newValue) => {
      if (newValue) {
        displayMessage.value = true;
      }
    });

    const {
      loadDrift, openChat, isDriftLoaded, popUpText,
    } = useDrift();

    function loadAndOpenChat() {
      loading.value = true;
      loadDrift();
      openChat();
    }

    const hideMessage = () => { displayMessage.value = false; };

    return {
      popUpText,
      loadAndOpenChat,
      hideMessage,
      displayMessage,
      loading,
      isDriftLoaded,
      isVariant1,
      isVariant2,
      isControl,
      displayIcon,
    };
  },
});
