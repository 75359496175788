import axios from 'axios';

const fightCampBackendLink = process.env.VUE_APP_API_URL;

export default {
  submitGen2BetaForm(answers, email) {
    const anonymousId = window?.analytics?.user()?.anonymousId();
    return axios.post(`${fightCampBackendLink}/qualifyCustomer`, JSON.stringify({ answers, email, anonymousId }), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  fetchEmbedLink(name, email) {
    return axios.post(`${fightCampBackendLink}/customerSign`, {
      name,
      email,
    }, {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  decline(email) {
    return axios.post(`${fightCampBackendLink}/betaDecline`, {
      email,
    }, {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
};
