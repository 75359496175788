























import {
  defineComponent, ref, onMounted,
} from '@vue/composition-api';
import { NavigationArrowsProps } from './types';

export default defineComponent({
  name: 'NavigationArrows',
  props: {
    slides: {
      type: Array,
      default() {
        return [];
      },
    },
    currentSlide: {
      type: Number,
      default: -1,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
    arrowNavigationTheme: {
      type: String,
      default: 'dark-gray',
    },
  },
  setup(props: NavigationArrowsProps) {
    const isTabletUp = ref(false);
    const slideCount = ref(props.currentSlide);

    function getNextSlide() {
      if (slideCount.value === (props.slides.length - 1)) {
        slideCount.value = 0;
        return 0;
      }
      slideCount.value += 1;
      return slideCount.value;
    }

    function getLastSlide() {
      if (slideCount.value === (props.slides.length - 1)) {
        slideCount.value = 0;
        return 0;
      }

      if (slideCount.value === 0) {
        slideCount.value = props.slides.length -1;
        return slideCount.value;
      }

      slideCount.value -= 1;
      return slideCount.value;
    }

    onMounted(() => {
      isTabletUp.value = window.matchMedia('(min-width: 1025px)').matches;
    });

    return {
      isTabletUp,
      slideCount,
      getNextSlide,
      getLastSlide,
    };
  },
});
