

module.exports = {
  PaidSocial: 'Paid Social',
  PaidYoutube: 'Paid Youtube',
  OrganicYoutube: 'Organic Youtube',
  PaidSearch: 'Paid Search',
  Appstore: 'AppStore',
  Retail: 'Retail',
  UTM: 'UTM',
  Referral: 'Referral',
  Organic: 'Organic',
  PR: 'PR',
  Influencer: 'Influencer',
  OrganicInfluencer: 'Organic Influencer',
  FCBlog: 'FC Blog',
  Unknown: 'Unknown',
  Podcast: 'Podcast',
  Television: 'Television',
  Mail: 'Direct Mail',
  BizDev: 'Biz Dev',
};
