import { betterEveryDay } from '~/components/products/content/fightcamp_bundle_better_everyday';
import { trainFighter } from '~/components/products/content/fightcamp_bundle_train_fighter';
import { protectFloor } from '~/components/products/content/fightcamp_bundle_protect_floor';
import { putItOnTv } from '~/components/products/content/fightcamp_bundle_tv';
import { keepFresh } from '~/components/products/content/fightcamp_bundle_keep_fresh';
import { family } from '~/components/products/content/fightcamp_bundle_family';

export default {
  betterEveryDay,
  trainFighter,
  protectFloor,
  putItOnTv,
  keepFresh,
  family,
};
