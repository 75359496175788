<template>
  <p class="rotating-text fc-title">
    <span
      v-for="text in alteredTextList"
      :key="text"
      class="rotating-text__text fc-flex fc-column">
      {{ text }}
    </span>
  </p>
</template>

<script>
  import {
    onMounted, ref,
  } from '@vue/composition-api';

  export default {
    name: 'RotatingText',
    props: {
      textList: {
        type: Array,
        required: true,
      },
    },
    setup(props) {
      const textIndex = ref(0);
      const alteredTextList = ref([props.textList[0], props.textList[1]]);

      function animateText() {
        setInterval(() => {
          textIndex.value = (textIndex.value + 1) % (props.textList.length);
          updateList(textIndex.value);
        }, 3000);
      }

      function getNextIndex(currentIndex) {
        return (currentIndex + 1) % (props.textList.length);
      }

      function updateList(index) {
        const nextIndex = getNextIndex(index);
        const word = props.textList[nextIndex];
        alteredTextList.value.shift();
        alteredTextList.value.push(word);
      }

      onMounted(() => {
        animateText();
      });

      return {
        alteredTextList,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../styles/exports";

  .rotating-text {
    margin: get-spacing-level(0);
    font-size: get-font-size-level(10);
    overflow: hidden;
    height: 45px;

    @media #{$tablet-up} {
      font-size: get-font-size-level(20);
      height: 88px;
    }

    &__text {
      @media (prefers-reduced-motion: reduce) {
        animation: none;
      }

      @media (prefers-reduced-motion: no-preference) {
        animation: rotate 10s 0.01s infinite;
        -webkit-animation: rotate 10s 0.01s infinite;
      }
    }
  }

  @keyframes rotate {
    30% {
      transform: translateY(-100%);
    }
  }
</style>
