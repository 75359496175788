//
//
//
//
//
//
//
//
//
//
//

import {
  onMounted, ref,
} from '@vue/composition-api';

export default {
  name: 'RotatingText',
  props: {
    textList: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const textIndex = ref(0);
    const alteredTextList = ref([props.textList[0], props.textList[1]]);

    function animateText() {
      setInterval(() => {
        textIndex.value = (textIndex.value + 1) % (props.textList.length);
        updateList(textIndex.value);
      }, 3000);
    }

    function getNextIndex(currentIndex) {
      return (currentIndex + 1) % (props.textList.length);
    }

    function updateList(index) {
      const nextIndex = getNextIndex(index);
      const word = props.textList[nextIndex];
      alteredTextList.value.shift();
      alteredTextList.value.push(word);
    }

    onMounted(() => {
      animateText();
    });

    return {
      alteredTextList,
    };
  },
};
