
function getFbp() {
  const result = /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie);
  if (!(result && result[1])) {
    return null;
  }
  return result[1];
}
function getFbc() {
  const result = /_fbc=(fb\.1\.\d+\..+?(?=;))/.exec(window.document.cookie);
  if (!(result && result[1])) {
    return null;
  }
  return result[1];
}

function computeSessionData() {
  const sessionData = {};
  try {
    sessionData.url = window.location.href;
    sessionData.userAgent = navigator.userAgent;
    sessionData.fbc = getFbc();
    sessionData.fbp = getFbp();
  } catch (e) {
    console.log(e);
  }
  return sessionData;
}

module.exports.get = computeSessionData;
