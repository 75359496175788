<template>
  <div
    class="fc-card fc-flex fc-column"
    :class="cardClasses">
    <slot />
  </div>
</template>

<script lang="ts">
  import { PropType, defineComponent, computed } from '@vue/composition-api';
  import { BackgroundColor, FCCardProps } from './types';

  export default defineComponent({
    name: 'FCCard',
    props: {
      bgColor: {
        type: String as PropType<BackgroundColor>,
        default: 'white',
        required: false,
        validator: (color: BackgroundColor) => typeof color === 'string',

      },
      noShadow: {
        type: Boolean,
        default: false,
        required: false,
      },
      noRadius: {
        type: Boolean,
        default: false,
        required: false,
      },
      noHoverEffect: {
        type: Boolean,
        default: true,
        required: false,
      },
      padding: {
        type: String,
        default: 'fc-padding-8',
        required: false,
      },
    },
    setup(props: FCCardProps) {
      return {
        cardClasses: computed(() => {
          const classes = [];
          classes.push(`fc-bg-${props.bgColor}`);
          classes.push(props.padding);

          if (props.noShadow) classes.push('no-shadow');
          if (props.noRadius) classes.push('no-radius');
          if (props.noHoverEffect) {
            classes.push('no-hover');
          } else {
            classes.push(`fc-border-${props.bgColor}`);
          }

          return classes;
        }),
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import "../../styles/exports";

  .fc-card {
    box-shadow: get-spacing-level(0) get-spacing-level(1) get-spacing-level(2) rgba(15, 30, 60, 0.1);
    border-radius: $card-radius;
    overflow: hidden;

    &:hover {
      border-color: $color-blue;
      cursor: pointer;

      &.no-hover {
        border: none;
        cursor: auto;
      }
    }

    &.no-shadow {
      box-shadow: none;
    }

    &.no-radius {
      border-radius: 0;
    }
  }
</style>
