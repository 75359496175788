






































import {
  computed, defineComponent, provide, Ref, shallowReactive, watch,
} from '@vue/composition-api';
import {
  CarouselContext, CarouselMode, CarouselParams, CarouselProps, Slide, SliderOptions,
} from './types';
import { Tokens } from './tokens';
import Navigation from './Navigation.vue';
import NavigationArrows from './NavigationArrows.vue';
import { useCarouselContext } from './hooks/useCarouselContext';
import { useTimerController } from './hooks/useTimerController';
import { slugify } from '../../utils';

interface CarouselBindings {
  ariaHeadingId: string;
  slider: Ref<HTMLElement | undefined>;
  slides: Slide[];
  currentSlide: Ref<number>;
  handleSlideSelect(index: number): void;
  isTimerEnabled: Ref<boolean>;
  isHidden: Ref<boolean>;
}

export default defineComponent({
  name: 'Carousel',
  components: {
    Navigation,
    NavigationArrows,
  },
  props: {
    screenLabel: {
      type: String,
      required: true,
    },
    snapThreshold: {
      type: Number,
      default: 0.3,
    },
    mode: {
      type: String,
      default: CarouselMode.DEFAULT,
    },
    slideWidthPercentage: {
      type: Number,
      default: 1,
    },
    timerLimit: {
      type: Number,
      default: 2000,
    },
    infiniteSlidesEnabled: {
      type: Boolean,
      default: true,
    },
    hideNavigation: {
      type: Boolean,
      default: false,
    },
    threeSlideView: {
      type: Boolean,
      default: false,
    },
    displayArrowNavigation: {
      type: Boolean,
      default: false,
    },
    arrowNavigationTheme: {
      type: String,
      default: 'dark-gray',
    },
  },
  setup(props: CarouselProps, context): CarouselBindings {
    const {
      carouselContext,
      slider,
    } = useCarouselContext(props as CarouselParams);
    const isTimerEnabled = useTimerController({ slider, mode: props.mode });
    const isHidden = computed<boolean>(() => isTimerEnabled && props.mode === CarouselMode.TIMER_ON_HOVER);
    const slides = shallowReactive<Slide[]>([]);

    provide<Slide[]>(Tokens.slides, slides);
    provide<CarouselContext>(Tokens.carouselContext, carouselContext);

    watch(() => carouselContext.currentIndex.value, (newIndex, oldIndex) => {
      if (newIndex !== oldIndex) {
        context.emit('slideChange', newIndex);
      }
    });

    return {
      ariaHeadingId: `carousel-heading-${slugify(props.screenLabel)}`,
      slider,
      slides,
      currentSlide: carouselContext.currentIndex,
      isHidden,
      isTimerEnabled,
      handleSlideSelect(index: number, options?: SliderOptions): void {
        carouselContext.slideToIndex(index, options);
      },
    };
  },
});
