import type { Ref } from '@vue/composition-api';
import { CarouselParams, SliderOptions } from '../types';

export interface CarouselAnimator {
  initialize(params?: CarouselParams): void;
  teardown(): void;
  startSlideDrag(index: number, event: Event): void;
  setSliderPositionByCurrentIndex(): void;
  continueSlideDrag(event: Event): void;
  endSlideDrag(): void;
  manuallySlideToIndex(index: number, options?: SliderOptions): void;
  getSliderRef(): Ref<HTMLElement | undefined>;
  getCurrentIndexRef(): Ref<number>;
}

const defaultSliderOptions: SliderOptions = {
  ignoreInfiniteMode: false,
};

export class CarouselAnimatorImpl implements CarouselAnimator {
  private initializeHandler: Function | null = null;
  private params!: CarouselParams;
  private isDragging = false;
  private sliderFrameIndex = 0;
  private startPositionPixels = 0;
  private currentTranslatePixels = 0;
  private previousTranslatePixels = 0;
  private animationId = 0;

  constructor(
    private readonly slider: Ref<HTMLElement | undefined>,
    private readonly currentIndex: Ref<number>,
  ) {}

  public initialize(params: CarouselParams): void {
    this.params = params;
    this.setSliderPositionByCurrentIndex();

    this.initializeHandler = this.setSliderPositionByCurrentIndex.bind(this);

    window.addEventListener('resize', this.initializeHandler as EventListener);
  }

  public setSliderPositionByCurrentIndex(): void {
    this.currentTranslatePixels = this.sliderFrameIndex * this.getCarouselSlideWidth() * -1 + this.getCarouselSlideSpacing();
    this.previousTranslatePixels = this.currentTranslatePixels;
    this.setSliderPosition();

    if (this.params.infiniteSlidesEnabled) {
      this.updateBoundarySlides();
    }
  }

  private getCarouselSlideSpacing(): number {
    const spacingCalculation = this.getCarouselSlideWidth() / this.getSlideWidthPercentage() * (1 - this.getSlideWidthPercentage());
    const totalCalculation = this.isThreeSlideView() ? spacingCalculation/2 : spacingCalculation;
    return this.getSlideWidthPercentage() * this.getSlidesLength() >= 1.01
      ? totalCalculation
      : 0;
  }

  private getCarouselSlideWidth(): number {
    let width = 0;

    if (this.slider.value && this.slider.value.parentElement) {
      width = this.slider.value.parentElement.offsetWidth * this.getSlideWidthPercentage();
    }

    return width;
  }

  private getSlideWidthPercentage(): number {
    return this.params.slideWidthPercentage || 1;
  }

  private isThreeSlideView(): boolean {
    return this.params.threeSlideView || false;
  }

  private updateBoundarySlides(): void {
    const nextSlideIndex = this.getNextSlideIndex();
    const prevSlideIndex = this.getPreviousSlideIndex();
    const currentSlide = this.getSlideAtIndex(this.currentIndex.value);
    const nextSlide = this.getSlideAtIndex(nextSlideIndex);
    const prevSlide = this.getSlideAtIndex(prevSlideIndex);

    if (currentSlide && nextSlide && prevSlide) {
      const carouselWidth = this.getCarouselSlideWidth();
      const newCurrentLeft = this.getLeftPositionAtIndex(this.currentIndex.value);
      const newPrevLeft = this.getLeftPositionAtIndex(prevSlideIndex) - carouselWidth;
      const newNextLeft = this.getLeftPositionAtIndex(nextSlideIndex) + carouselWidth;

      currentSlide.style.left = `${newCurrentLeft}px`;
      prevSlide.style.left = `${newPrevLeft}px`;
      nextSlide.style.left = `${newNextLeft}px`;
    }
  }

  private getLeftPositionAtIndex(index: number): number {
    return (-1 * this.currentTranslatePixels) - (this.getCarouselSlideWidth() * index);
  }

  private getNextSlideIndex(): number {
    const nextIndex = this.currentIndex.value + 1;

    return nextIndex >= this.getSlidesLength() ? 0 : nextIndex;
  }

  private getPreviousSlideIndex(): number {
    const prevIndex = this.currentIndex.value - 1;

    return prevIndex < 0 ? this.getSlidesLength() - 1 : prevIndex;
  }

  private getSlidesLength(): number {
    const slides = this.slider.value && this.slider.value.children;

    return slides && slides.length || 0;
  }

  private getSlideAtIndex(index: number): HTMLElement | undefined {
    return this.slider.value && this.slider.value.children[index] as HTMLElement;
  }

  public teardown(): void {
    if (this.initializeHandler) {
      if (global.cancelAnimationFrame) {
        global.cancelAnimationFrame(this.animationId);
      }

      window.removeEventListener('resize', this.initializeHandler as EventListener);
      this.initializeHandler = null;
    }
  }

  public startSlideDrag(index: number, event: Event): void {
    this.updateCurrentIndex(index);
    this.startPositionPixels = this.getCurrentPositionX(event);
    this.isDragging = true;

    if (global.requestAnimationFrame) {
      this.animationId = global.requestAnimationFrame(this.animateSlide);
    }
  }

  private updateCurrentIndex(newIndex: number, options = defaultSliderOptions): void {
    if (this.params.infiniteSlidesEnabled && !options.ignoreInfiniteMode) {
      this.sliderFrameIndex = this.getSliderFrameAtIndex(newIndex);
    } else {
      this.sliderFrameIndex = newIndex;
    }

    this.currentIndex.value = newIndex;
  }

  private getSliderFrameAtIndex(index: number): number {
    let gapVector: number;

    if (index === this.getPreviousSlideIndex()) {
      gapVector = -1;
    } else if (index === this.getNextSlideIndex()) {
      gapVector = 1;
    } else {
      gapVector = index - this.currentIndex.value;
    }

    return this.sliderFrameIndex + gapVector;
  }

  private getCurrentPositionX(event: Event): number {
    return this.isMouseEvent(event) ? event.pageX : (event as TouchEvent).touches[0].clientX;
  }

  private isMouseEvent(event: Event): event is MouseEvent {
    return event.type.includes('mouse');
  }

  private animateSlide = (): void => {
    this.setSliderPosition();

    if (this.isDragging) {
      this.animationId = requestAnimationFrame(this.animateSlide);
    }
  };

  private setSliderPosition() {
    const finalPosition = this.isThreeSlideView() ? this.currentTranslatePixels + this.getCarouselSlideSpacing() : this.currentTranslatePixels;

    if (this.slider.value) {
      this.slider.value.style.transform = `translateX(${finalPosition}px)`;
    }
  }

  public continueSlideDrag(event: Event): void {
    if (this.isDragging) {
      this.currentTranslatePixels = this.previousTranslatePixels + this.getCurrentPositionX(event) - this.startPositionPixels;
    }
  }

  public endSlideDrag(): void {
    if (global.cancelAnimationFrame) {
      global.cancelAnimationFrame(this.animationId);
    }

    this.isDragging = false;
    this.snapToClosestSlide();
  }

  private snapToClosestSlide() {
    const traveledPixels = this.currentTranslatePixels - this.previousTranslatePixels;

    if (this.shouldSnapToNextSlide(traveledPixels)) {
      this.updateCurrentIndex(this.getNextSlideIndex());
    } else if (this.shouldSnapToPreviousSlide(traveledPixels)) {
      this.updateCurrentIndex(this.getPreviousSlideIndex());
    }

    this.setSliderPositionByCurrentIndex();
  }

  private shouldSnapToNextSlide(traveledPixels: number): boolean {
    return traveledPixels < (-1 * this.getSnapThresholdPixels()) && (this.params.infiniteSlidesEnabled || this.currentIndex.value < this.getSlidesLength() - 1);
  }

  private shouldSnapToPreviousSlide(traveledPixels: number): boolean {
    return traveledPixels > this.getSnapThresholdPixels() && (this.params.infiniteSlidesEnabled || this.currentIndex.value > 0);
  }

  private getSnapThresholdPixels(): number {
    return this.params.snapThreshold * (this.getCarouselSlideWidth() + this.getCarouselSlideSpacing());
  }

  public manuallySlideToIndex(index: number, options = defaultSliderOptions): void {
    this.updateCurrentIndex(index, options);
    this.setSliderPositionByCurrentIndex();
  }

  public getCurrentIndexRef(): Ref<number> {
    return this.currentIndex;
  }

  public getSliderRef(): Ref<HTMLElement | undefined> {
    return this.slider;
  }
}
