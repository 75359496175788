import { ActionContext, Commit } from 'vuex';
import {
  CheckoutState, ICartItem, IShopifyItem, IShippingDetails,
} from '~/store/types';
import { formatOrderRequestBody, createOrderTags } from '~/utils/ordersHelpers';
import { IOrdersService } from '~/services/OrdersService';
import { IBugsnagWrapper } from '~/services/BugSnagService';
import { DataError, handleShopifyAdminErrors } from '~/utils/errors';
import EventBus from '@/shared/scripts/eventbus';

interface createOrUpdateOrderParams {
  shippingDetails: IShippingDetails;
  ordersApi: IOrdersService;
  bugsnag: IBugsnagWrapper;
}

export async function createOrUpdateOrder({
  commit, rootGetters, rootState,
}: ActionContext<CheckoutState, any>, payload : createOrUpdateOrderParams): Promise<void> {
  console.log('action - createOrUpdateOrder');
  const { shippingDetails, ordersApi, bugsnag } = payload;
  let setupIntentId:string | null = null;

  try {
    commit('setProcessingTotalCost', true, { root: true });
    const {
      cart, membershipInCart, order, cartProducts, isEligibleGen2Upgrade, includesConsole,
    } = rootGetters;

    const {
      paymentIntentId, customerId, draftOrderId, paymentInformation: { paymentMethodObj, paymentMethod },
    } = rootState.checkout;

    const cartItems = mapCartItems(cart, cartProducts);

    const tags = createOrderTags({ isEligibleGen2Upgrade, includesConsole });
    const isOnlySecondHandMembershipInCart = cart.length === 1 && (membershipInCart?.variants[0]?.sku === 'FC-Secondhand-Membership');
    const isAffirmWithPackage = rootState.checkout.paymentInformation?.paymentMethod === 'affirm' && !!membershipInCart;
    const useSetupIntent = isOnlySecondHandMembershipInCart || isAffirmWithPackage;

    if (useSetupIntent) {
      setupIntentId = await createSetupIntent({
        paymentMethodId: paymentMethodObj.id, commit, ordersApi, customerId,
      });
    }

    const requestBody = formatOrderRequestBody({
      shippingDetails,
      cart: cartItems,
      costBreakdown: order.costBreakdown,
      shippingRateDetails: order.shippingRateDetails,
      setupIntentId,
      draftOrderId,
      paymentMethod,
      ...(customerId ? { customerId } : {}),
      ...(tags ? { tags } : {}),
    });

    if (paymentIntentId) {
      const response = await ordersApi.updateOrder({ paymentIntentId, requestBody });
      await handleOrderResponse(response, rootGetters, commit);
    } else {
      const response = await ordersApi.createOrder({ requestBody });
      const data = await handleOrderResponse(response, rootGetters, commit);
      commit('setOrderIds', { paymentIntentId: data.paymentIntentId, draftOrderId: data.draftOrderId }, { root: true });
    }

    commit('setProcessingTotalCost', false, { root: true });
  } catch (err: any) {
    if (err.name === 'ProductAvailabilityError') {
      commit('SET_SHIPPING_ERROR', err.message, { root: true });
      commit('SET_SHIPPING_INVALID', ['country'], { root: true });
      commit('setProcessingTotalCost', false, { root: true });
      bugsnag.notify(new Error(err.message), 'error', 'createOrUpdateOrder/ProductAvailabilityError');
      return;
    }

    if (err.name === 'DataError') {
      commit('SET_SHIPPING_ERROR', 'One of the products is currently unavailable. Please refresh and try again.', { root: true });
      commit('setProcessingTotalCost', false, { root: true });
      bugsnag.notify(new Error('Product not found in shopifyItems'), 'error', 'createOrUpdateOrder/DataError');
      return;
    }

    let errorMessage: string | string[] = 'An unexpected error occurred. Please try again later.';
    if (err.response) {
      const { name, message, errors } = err.response.data;

      if (name === 'ShopifyError') {
        const messagesToDisplay = handleShopifyAdminErrors(errors, commit);
        errorMessage = messagesToDisplay;
        EventBus.$emit('setTab', 'information');
        bugsnag.notify(new Error(messagesToDisplay.join(' ')), 'error', 'createOrUpdateOrder/ShopifyError');

        commit('SET_SHIPPING_ERROR', errorMessage, { root: true });
        commit('setProcessingTotalCost', false, { root: true });
        return;
      }

      bugsnag.notify(new Error(message), 'error', 'createOrUpdateOrder/Response Error');

      // TODO handle setupintent errors
    }

    commit('SET_SHIPPING_ERROR', errorMessage, { root: true });
    commit('setProcessingTotalCost', false, { root: true });
    if (err instanceof Error) {
      bugsnag.notify(err, 'error', 'createOrUpdateOrder/Error');
    } else {
      bugsnag.notify(new Error(err), 'error', 'createOrUpdateOrder/Error');
    }
  }
}

async function handleOrderResponse(response: any, rootGetters: any, commit: Commit) {
  const costBreakdown = {
    ...rootGetters.order.costBreakdown, // this has coupon data
    ...response.data.finalCostBreakdown, // this had final cost data from shopify
  };
  commit('setFinalCostBreakdown', costBreakdown, { root: true });
  return response.data;
}

function mapCartItems(cart: {id: string, qty: number, type: string}[], cartProducts: {shopifyItems: IShopifyItem[]}): ICartItem[] {
  return cart.map((item) => {
    const uid = cartProducts?.shopifyItems?.find((product) => product.variant.id === item.id)?.variant?.uid;
    if (!uid) { throw new DataError('Product not found in shopifyItems'); }

    return {
      id: item.id,
      qty: item.qty,
      type: item.type,
      uid,
    };
  });
}

interface CreateSetupIntentParams {
  paymentMethodId: string;
  ordersApi: any;
  customerId: string;
  commit: Commit;
}

async function createSetupIntent({
  paymentMethodId, ordersApi, customerId, commit,
}: CreateSetupIntentParams) {
  const setupIntent = await ordersApi.createSetupIntent({ customerId, paymentMethodId });
  const setupIntentId = setupIntent.data.id;
  commit('setSetupIntentId', setupIntentId, { root: true });

  return setupIntentId;
}
