// Add event listeners for OneTrust consent
export function runOneTrustEffect(window: Window) {
  const cookieSettingsButton = window.document.getElementById('ot-sdk-btn');
  if (cookieSettingsButton) {
    cookieSettingsButton.addEventListener('click', () => {
      const oneTrustButtons = [
        document.getElementById('accept-recommended-btn-handler'),
        window.document.querySelector('button.save-preference-btn-handler'),
      ];
      if (oneTrustButtons.length) {
        oneTrustButtons.forEach(button => {
          if (button != null) {
            button.addEventListener('click', () => {
              // refresh page so that the new cookies are loaded
              window.location.reload();
            });
          }
        });
      }
    });
  }
}
