










import { PropType, defineComponent } from '@vue/composition-api';
import { BackgroundTheme } from '../../utils/global';

export default defineComponent({
  name: 'Announcement',
  props: {
    theme: {
      type: String as PropType<BackgroundTheme>,
      default: BackgroundTheme.red,
      required: false,
      validator: (theme: BackgroundTheme) => !!BackgroundTheme[theme],
    },
  },
});
