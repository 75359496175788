
const attribution = require('./utils/attribution');
const otherOption = require('./utils/other');
const apparel = require('./apparel');

const memberQuestion = id => ({
    id,
    question: 'Were they already a member of FightCamp?',
    type: 'select',
    options: [{
      id: `${id} Member`,
      label: ' Yes',
      followup: apparel({ id: `${id} Member`, attribution: attribution.Referral }),
      attribution: attribution.Referral,
    },
    {
      id: `${id} NotMember`,
      label: ' No',
      followup: apparel({ id: `${id} NotMember`, attribution: attribution.Referral }),
      attribution: attribution.Referral,
    }],
  });

  const shared = id => ({
    id: `${id} Share`,
    question: 'What was it?',
    type: 'list',
    options: [
      {
        id: `${id} Share Story`,
        label: 'A story from FightCamp`s page',
        followup: apparel({ id: `${id} Share Story`, attribution: attribution.UTM }),
        attribution: attribution.UTM,
      },
      {
        id: `${id} Share Post`,
        label: 'A post from FightCamp`s page',
        followup: apparel({ id: `${id} Share Post`, attribution: attribution.UTM }),
        attribution: attribution.UTM,
      },
      {
        id: `${id} Share Website`,
        label: 'A link to the FightCamp website',
        followup: apparel({ id: `${id} Share Website`, attribution: attribution.Referral }),
        attribution: attribution.Referral,
      },
      {
        id: `${id} Share App`,
        label: 'A link to the FightCamp App',
        followup: apparel({ id: `${id} Share App`, attribution: attribution.Referral }),
        attribution: attribution.Referral,
      },
      {
        id: `${id} Share Message`,
        label: 'A direct message talking about FightCamp',
        followup: apparel({ id: `${id} Share Message`, attribution: attribution.Referral }),
        attribution: attribution.Referral,
      },
      {
        id: `${id} Share Community`,
        label: 'Invited you to the FightCamp Facebook Community',
        followup: apparel({ id: `${id} Share Community`, attribution: attribution.Referral }),
        attribution: attribution.Referral,
      },
      otherOption({ id: `${id} Share Other`, attribution: attribution.Unknown, followup: apparel({ id: `${id} Share Other`, attribution: attribution.Unknown }) }),
    ],
  });


const FriendFamily = id => ({
  id,
  type: 'list',

  question: 'How did they tell you about it?',
  options: [{
    id: `${id} InPerson`,
    label: 'They told me in person',
    followup: memberQuestion(`${id} InPerson`),
    attribution: attribution.Referral,
  },
  {
    id: `${id} Share`,
    label: 'They shared something with you',
    followup: shared(id),
    attribution: attribution.Referral,

  }, {
    id: `${id} Message`,
    label: 'They sent you a message',
    attribution: attribution.Referral,
    followup: {
      id: `${id} Message`,
      question: 'What platform did they use?',
      type: 'list',
      options: [{
        id: `${id} Messsage Messenger`,
        label: 'Messenger',
        followup: memberQuestion(`${id} Messsage Messenger`),
        attribution: attribution.Referral,

      },
      {
        id: `${id} Messsage DM`,
        label: 'DM',
        followup: memberQuestion(`${id} Messsage DM`),
        attribution: attribution.Referral,

      },
      {
        id: `${id} Messsage Text`,
        label: 'Text',
        followup: memberQuestion(`${id} Messsage Text`),
        attribution: attribution.Referral,

      },
      {
        id: `${id} Messsage Email`,
        label: 'Email',
        followup: memberQuestion(`${id} Messsage Email`),
        attribution: attribution.Referral,

      },
      {
        id: `${id} Message Phone`,
        label: 'Phone call',
        followup: memberQuestion(`${id} Message Phone`),
        attribution: attribution.Referral,

      }],
    },
  }, {
    id: `${id} Page`,
    label: 'Shared their experience on their page',
    followup: memberQuestion(`${id} Page`),
    attribution: attribution.Referral,
  },
  {
    id: `${id} Home`,
    label: 'You saw it in their home and asked',
    followup: memberQuestion(`${id} Home`),
    attribution: attribution.Referral,

  },
  otherOption({ id: `${id} Other`, attribution: attribution.Referral, followup: apparel(`${id} Other`) }),
],
});


module.exports = FriendFamily;
