import { fightcampResistanceBands } from './fightcamp_resistance_bands';

export const fightcampResistanceBandsDeal = {
  title: 'Resistance Bands',
  id: 'fightcamp_resistance_bands_deal',
  price: 0,
  oldPrice: fightcampResistanceBands.price,
  category: 'accessory',
  description: 'Add variety to your workouts with resistance bands. Great for stretching, strength & recovery. 3 resistance levels included.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-resistance-bands-001.png',
  tag: 'Used In Workouts',
  types: ['Training']
};
