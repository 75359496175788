export default {
  title: 'FightCamp Logo Hoodie - Charcoal Heather',
  id: 'fightcamp_logo_hoodie_charcoal_heather',
  price: 6500,
  category: 'accessory',
  description: 'Elevate your training style with our Charcoal Heather FightCamp Logo Hoodie. This lightweight unisex sweatshirt boasts a kangaroo pocket, drawcords, and a cozy blend of 65% cotton and 35% polyester.',
  img_url: 'https://a.storyblok.com/f/152150/400x400/578a5ca9e5/preview-fc-logo-hoodie-gray-001.jpg',
  fbid: '',
  marketplace_img_url: '',
  types: ['Apparel']
};