export default {
  title: 'FightCamp Wraps',
  id: 'fightcamp_quickwraps',
  price: 3900,
  category: 'accessory',
  description: '',
  img_url: '',
  fbid: 'shopify_729274908726_8813415235638',
  marketplace_img_url: '/v4/fc-marketplace-accessory-quickwraps-001.png',
};
