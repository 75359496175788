import {
  ref, onUnmounted,
} from '@vue/composition-api';

const ONLINE_ID = 134105;
const OFFLINE_ID = 137159;

const popUpText = 'Click to chat with a live FIGHTCAMP Pro!';

const isDriftLoaded = ref(false);

export function useDrift() {
  // eslint-disable-next-line no-undef
  const { $ecommAnalytics, $analytics } = useNuxtApp();

  let searchingForDriftId;
  function loadDrift() {
    if (process.client && !window.drift) {
      $ecommAnalytics.triggerDrift();

      searchingForDriftId = setInterval(() => {
        const driftChatElement = document.querySelector('#drift-frame-chat');
        if (driftChatElement) {
          isDriftLoaded.value = true;
          clearInterval(searchingForDriftId);
        }
      }, 1000);
    }
  }

  function openChat() {
    if (process.client && window.drift) {
      window.drift.on('ready', (api, event) => {
        const status = event.data.isOnline ? 'online' : 'offline';
        const id = event.data.isOnline ? ONLINE_ID : OFFLINE_ID;

        api.startInteraction({ interactionId: id });
        $analytics.trackEvent('Chat', { action: 'Open', status });

        window.drift.on('chatClose', () => {
          document.body.classList.add('hide-drift');
          $analytics.trackEvent('Chat', { action: 'Close', status });
        });
      });
    }
  }

  function toggleDriftChat(toggleOff) {
    const driftEl = document.getElementById('drift-frame-controller');
    const driftFacadeEl = document.getElementsByClassName('drift-facade');

    if (toggleOff) {
      if (driftEl) driftEl.style.display = 'none';
      if (driftFacadeEl[0]) driftFacadeEl[0].style.display = 'none';
    } else {
      if (driftEl) driftEl.style.display = '';
      if (driftFacadeEl[0]) driftFacadeEl[0].style.display = '';
    }
  }

  if (searchingForDriftId) {
    onUnmounted(() => {
      clearInterval(searchingForDriftId);
    });
  }

  return {
    loadDrift,
    openChat,
    isDriftLoaded,
    popUpText,
    toggleDriftChat,
  };
}
