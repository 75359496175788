







































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  watch,
} from '@vue/composition-api';
import { SpinnerBindings, SpinnerProps } from './types';

export const DATA_TEST_ID = 'testid--spinner';

export default defineComponent<SpinnerProps, SpinnerBindings>({
  name: 'Spinner',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: 'Processing...',
    },
    withOverlay: {
      type: Boolean,
      default: true,
    },
    fullScreen: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const handleActiveUpdate = (active: any) => {
      if (active) {
        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100vh';
        document.body.style.width = '100vw';
        document.body.style.top = `${window.pageYOffset}px`;
      } else {
        document.documentElement.style.overflow = '';
        document.body.style.overflow = '';
        document.body.style.height = '';
        document.body.style.width = '';
        document.body.style.top = '';
      }
    };

    watch(() => props.active, handleActiveUpdate);
    onMounted(() => handleActiveUpdate(props.active));
    onUnmounted(handleActiveUpdate);

    return {
      testId: DATA_TEST_ID,
      containerClasses: computed(() => ({
        'has-overlay': props.withOverlay,
        'is-full-screen': props.fullScreen,
      })),
    };
  },
});
