import Storyblok, { StoryblokResult, StoriesParams } from 'storyblok-js-client';
import { ConstructorParameters, Version } from './types';

export class CMSService {
  cms: Storyblok;

  version: Version;

  constructor({ cmsService, version = process.env.NUXT_ENV_STORYBLOK_VERSION || 'draft' }: ConstructorParameters) {
    this.cms = cmsService;
    this.version = version as Version;
  }

  async get(slug: String, params?: StoriesParams): Promise<StoryblokResult> {
    const { resolve_relations: resolveRelations, ...restParams } = params || {};

    return this.cms.get(`cdn/stories/${slug}`, {
      version: this.version,
      resolve_relations: ['GlobalReference.reference', resolveRelations || []],
      ...restParams,
    });
  }

  async getAll(folder: string, params?: StoriesParams): Promise<StoryblokResult[]> {
    return this.cms.getAll('cdn/stories', {
      version: this.version,
      starts_with: folder,
      ...params,
    });
  }
}
