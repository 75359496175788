// eslint-disable-next-line import/no-extraneous-dependencies
import { Route } from 'vue-router';
import { Store } from 'vuex';
import bundle from '~/src/shared/scripts/bundle';

export const websiteUrl = 'https://joinfightcamp.com';
export const websiteUrlAppendPath = (path: string) => websiteUrl + path;

export default async function syncCart({ route, store }: { route: Route, store: Store<any> }) {
  const fullUrl = websiteUrlAppendPath(route.fullPath);
  const queryItems = bundle.getBundle(fullUrl);
  const queryCoupon = bundle.getCoupon(fullUrl);
  const queryCustomerId = bundle.getStripeCustomerID(fullUrl);

  if (queryCoupon) { store.commit('SET_COUPON', queryCoupon); }
  if (queryCustomerId) { store.commit('updateCustomerId', queryCustomerId); }

  await store.dispatch('syncCart', queryItems);

  return {
    queryItems,
    fullUrl,
  };
}
