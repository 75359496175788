export const fightcampShiAppleCable = {
  title: 'HDMI-to-Lightning Adapter',
  id: 'fightcamp_shi_apple_cable',
  price: 4900,
  category: 'accessory',
  description: 'Connect an HDMI cable to the lightning port on your Apple device to display your workout on a TV. Charge your device at the same time.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-hdmi-lightning-001.png',
  types: ['Setup']
};
