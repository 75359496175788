export const fightcampHeartRateMonitor = {
  title: 'Heart Rate Monitor',
  id: 'fightcamp_heart_rate_monitor',
  price: 6900,
  category: 'accessory',
  description: 'Monitor your heart rate in the FightCamp app in real time. Strap the low-profile & sweat-resistant HRM around your chest to measure your performance.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-heart-rate-monitor-001.png',
  tag: 'In-App Features',
  types: ['Training']
};
