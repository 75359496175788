export default {
  title: 'FightCamp Logo Tee - Black',
  id: 'fightcamp_logo_tee_black',
  price: 3500,
  category: 'accessory',
  description: 'The essential FightCamp Logo Tee in classic black. Crafted from ultra-soft, 100% combed ring-spun cotton with a center chest logo, wear this unisex piece when working out or hanging out.',
  img_url: 'https://a.storyblok.com/f/152150/400x400/693e578640/preview-fc-logo-tee-black-001.jpg',
  fbid: '',
  marketplace_img_url: '',
  types: ['Apparel']
};