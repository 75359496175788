type MetaDetails = {
  description: string,
  pageUrl: string,
  title: string,
  imageSrc: string,
}

export function createSocialCardsTags(details: MetaDetails) {
  const {
    title, description, pageUrl, imageSrc,
  } = details;

  return [
    { hid: `${title}_description`, name: 'description', content: description },
    { hid: `${title}_og:type`, property: 'og:type', content: 'website' },
    { hid: `${title}_og:url`, property: 'og:url', content: pageUrl },
    { hid: `${title}_og:title`, property: 'og:title', content: title },
    { hid: `${title}_og:description`, property: 'og:description', content: description },
    { hid: `${title}_og:image`, property: 'og:image', content: imageSrc },
    { hid: `${title}_twitter:url`, name: 'twitter:url', content: pageUrl },
    { hid: `${title}_twitter:card`, name: 'twitter:card', content: 'summary_large_image' },
    { hid: `${title}_twitter:description`, name: 'twitter:description', content: description },
    { hid: `${title}_twitter:title`, name: 'twitter:title', content: title },
    { hid: `${title}_twitter:image`, name: 'twitter:image', content: imageSrc },
    { hid: `${title}_twitter:site`, name: 'twitter:site', content: '@joinfightcamp' },
    { hid: `${title}_twitter:creator`, name: 'twitter:creator', content: '@joinfightcamp' },
  ];
}
