export function getUrlQueryParams() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

export function removeQueryParams(keysToRemove: string[]) {
  keysToRemove.forEach((key) => {
    const keyToReplace = `[?&]${key}[^&]+`;
    const reg = new RegExp(keyToReplace);

    if (window.history.replaceState) {
      window.history.replaceState(
        null, '', window.location.pathname + window.location.search.replace(reg, '').replace(/^&/, '?'),
      );
    }
  });
}
