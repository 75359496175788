// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from '@nuxt/types';
import axios from 'axios';
import isoStateCode from '@/shared/datasets/iso_state_code.json';

const fightCampBackendLink = process.env.VUE_APP_API_URL;

const initAppPlugin: Plugin = async (context) => {
  console.log('initAppPlugin');
  try {
    const { data } = await axios.get(`${fightCampBackendLink}/shippingZones`);
    context.store.commit('SET_SHIPPING_ZONES', data);
  } catch (error) {
    const usAndCanada = [
      {
        key: 'US',
        name: 'United States',
        provinces: isoStateCode,
      },
      {
        key: 'CA',
        name: 'Canada',
        provinces: [
          {
            name: 'Alberta',
            key: 'AB',
          },
          {
            name: 'British Columbia',
            key: 'BC',
          },
          {
            name: 'Ontario',
            key: 'ON',
          },
        ],
      },
    ];
    context.store.commit('SET_SHIPPING_ZONES', usAndCanada);
  } finally {
    context.store.dispatch('initializeApp');
  }
};

export default initAppPlugin;
