//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ButtonAction from '@/shared/components/ButtonAction.vue';
import colors from '@/shared/assets/scss/colorsExport.scss';
import GoalDonut from './PunchGameGoalDonut.vue';

export default {
  name: 'PunchGame',
  data: () => ({
    punchCount: 0,
    fire: true,
    colors,
  }),
  components: {
    GoalDonut,
    ButtonAction,
  },
  computed: {
    donutContent() {
      return {
        color: '#50e3c2',
        fontcolor: '#000000',
        bgcolor: this.colors.darkgray,
        title: 'Goal',
        subtitle: 'Left',
        formatter: (value, total) => total - value,
      };
    },
  },
  methods: {
    jab() {
      if (this.fire === true) {
        this.fire = false;
        this.$refs.musubi.classList.add('punch');
        this.punchCount += 1;
        this.bagMove();
        setTimeout(() => {
          this.$refs.musubi.classList.remove('punch');
          this.fire = true;
        }, 250);
      }
    },
    bagMove() {
      this.$refs.bag.classList.add('bagMove');
      setTimeout(() => {
        this.$refs.bag.classList.remove('bagMove');
      }, 250);
    },
    cross() {
      if (this.fire === true) {
        this.fire = false;
        this.$refs.musubi.classList.add('cross');
        this.punchCount += 1;
        this.bagMove();
        setTimeout(() => {
          this.$refs.musubi.classList.remove('cross');
          this.fire = true;
        }, 300);
      }
    },
    kick() {
      if (this.fire === true) {
        this.fire = false;
        this.$refs.musubi.classList.add('kick');
        this.punchCount += 1;
        this.bagMove();
        setTimeout(() => {
          this.$refs.musubi.classList.remove('kick');
          this.fire = true;
        }, 300);
      }
    },
    keymonitor(event) {
      if (event.key === '1') {
        this.jab();
      }

      if (event.key === '2') {
        this.cross();
      }

      if (event.key === 'k') {
        this.kick();
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keymonitor);
  },
  destroyed() {
    window.removeEventListener('keydown', this.keymonitor);
  },
};
