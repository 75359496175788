export const fightcampGloveBag = {
  title: 'Glove Bag',
  id: 'fightcamp_glove_bag',
  price: 1900,
  category: 'accessory',
  description: 'Take your gloves with you anywhere you go. Workout in a gym, at a park or on the beach.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-glove-bag-001.png',
  types: ['Setup'],
};
