/* eslint-disable max-classes-per-file */

type CheckoutUserError = {
  field: string,
  message: string,
  code: string,
}

enum CheckoutUserErrorCodes {
  ProductNotAvailable = 'PRODUCT_NOT_AVAILABLE',
}

export type GraphQLError = {
  message: string
}[]

export class ProductAvailabilityError extends Error {
  constructor(message: string, cause: any) {
    super(message);
    this.name = 'ProductAvailabilityError';
    this.cause = cause;
  }
}

export class ShippingError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ShippingError';
  }
}

export class CouponError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CouponError';
  }
}

export function handleCheckoutUserErrors(errors: CheckoutUserError[]) {
  errors.forEach((error: CheckoutUserError) => {
    if (error?.code?.includes(CheckoutUserErrorCodes.ProductNotAvailable)) {
      throw new ProductAvailabilityError('Product is not available in your area. Please remove it from your cart.', error.code); // TODO what should this error messsge be?
    }
    throw new Error(error.message);
  });
}

export function handleGraphQLRequestErrors(errors: GraphQLError) {
  const errorMessages = (errors ?? []).map(e => e.message);
  errorMessages.forEach((error: string) => {
    if (error !== "You don't have any items that require shipping") { // this is an "error" when there's a digital product in the cart, we can ignore it.
      throw new ShippingError(error);
    }
  });
}
