export default {
  title: 'FightCamp Care Kit',
  id: 'fightcamp_carekit',
  price: 10900,
  category: 'accessory',
  description: '',
  path: '/shop/equipment',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-equipment-carekit-001.jpg',
  fbid: '',
};
