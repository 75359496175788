export default {
  title: 'FightCamp Console + Bag',
  id: 'fightcamp_console_bag',
  price: 99900,
  affirmPrice: 99900,
  monthly: 4163,
  months: 24,
  category: 'package',
  path: '/shop/products/fightcamp-console-bag/',
  internalPath: 'shop/products/fightcamp-console-bag/',
  img_url: 'https://a.storyblok.com/f/152150/2000x2000/b93e958edd/fc-ultimate-bundle-package-001.jpg', // TODO - Update image
  description: 'The ultimiate FightCamp bundle', // TODO - Update description
};
