export default {
  title: 'Sweat Towel',
  id: 'fightcamp_sweat_towel',
  price: 1500,
  category: 'accessory',
  description: 'Stay cool and focused during your workouts with our Hyper-Absorbent Sweat Towel. In a crisp white hue with a striking red FightCamp logo, this 12" W X 34 1/2" L towel, crafted from a high-performance polyester blend, is your ultimate companion for staying refreshed and on top of your game.',
  img_url: 'https://a.storyblok.com/f/152150/900x1350/5e1c128c1b/fightcamp-sweat-towel-001.png',
  fbid: '',
  marketplace_img_url: '',
  types: ['Training']
};