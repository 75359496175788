import round from 'lodash/round';
import { ImageSizes, ImageUnits } from '~/constants/image';
import {
  IMAGE_DIMENSIONS_VALIDATOR,
  IMAGE_PROVIDER_SIZES_VALIDATOR,
  IMAGE_SIZES_QUERY_VALIDATOR,
} from '~/constants/regex';
import { IImageFilters, IMatchedSizesObj, TValueUnitHandlerFn } from './types';

export const getImageSizesMatches = (string: string) => {
  if (typeof string !== 'string') return [];
  return [...(string ?? '').toString().matchAll(IMAGE_SIZES_QUERY_VALIDATOR)];
};

export const getImageDimensionMatches = (string: string) => {
  if (typeof string !== 'string') return [];
  return [...(string ?? '').toString().matchAll(IMAGE_DIMENSIONS_VALIDATOR)];
};

export const getSizesArray = (string: string): IMatchedSizesObj[] => {
  const test = getImageSizesMatches(string);
  if (test.length < 1) return [];

  return test.map((matches: RegExpMatchArray) => {
    const [match] = matches ?? [];
    const [key] = Array.from(match.match(IMAGE_PROVIDER_SIZES_VALIDATOR) ?? []);
    const [value] = Array.from(match.match(IMAGE_DIMENSIONS_VALIDATOR) ?? []);
    return {
      key,
      value,
    };
  });
};

const extractNumber: TValueUnitHandlerFn = (potentialNumber) => {
  if (typeof potentialNumber === 'string' || typeof potentialNumber === 'number') {
    const number = Number(potentialNumber);

    if (Number.isNaN(number)) return null;
    return number;
  }

  return null;
};

const multiplyByOneREM: TValueUnitHandlerFn = (potentialNumber) => {
  const number = extractNumber(potentialNumber, null);
  if (number == null) return null;
  return number * 16;
};

const getPercentageRelativeValue: TValueUnitHandlerFn = (potentialNumber, amount) => {
  const number = extractNumber(potentialNumber, null);
  if (number == null) return null;

  return round((number / 100) * (amount ?? 1), 2);
};

export const finalValueUnitMap: Map<string, TValueUnitHandlerFn> = new Map([
  [ImageUnits.px, extractNumber],
  [ImageUnits.vw, getPercentageRelativeValue],
  [ImageUnits.rem, multiplyByOneREM],
]);

export const getSizeNumberUnit = (size: string) => {
  const [find] = getImageDimensionMatches(size);
  const [, number, unit] = find ?? [];

  return {
    unit,
    number,
  };
};

export const transformSizeUnits = (size: string, assignedWidth: number) => {
  const { unit, number } = getSizeNumberUnit(size);
  const transformedValueHelper = finalValueUnitMap.get(unit);

  return transformedValueHelper
    ? transformedValueHelper(number, assignedWidth)
    : null;
};

export const getImageDimensions = (width: number, sizes: string) => {
  if (extractNumber(width, null) == null) return null;
  if (sizes == null || width == null) return null;

  const clientWidthLimit = Math.min(width, ImageSizes['2xl']);
  const widths = getSizesArray(sizes);
  const limit = widths.find(({ key }) => clientWidthLimit <= ImageSizes[<keyof typeof ImageSizes>key])
    ?? widths[widths.length - 1]
    ?? {};

  const calculation = transformSizeUnits(limit.value, clientWidthLimit);
  if (typeof calculation !== 'number') return null;

  return round(calculation);
};

export const constructImageDimensions = (width: number, sizes: string): string => {
  const value = getImageDimensions(width, sizes) ?? '';
  if (value == null || typeof value !== 'number' || value < 1) return '';

  return `${value}x0`;
};

export const getFiltersParams = (filters: Object): string => {
  if (filters == null || typeof filters !== 'object') return '';
  return Object
    .entries(filters ?? {})
    .reduce((acc, [key, entry]) => `${acc}:${key}(${entry})`, '');
};

export const constructImageFilters = (filters: IImageFilters) => {
  if (filters == null || typeof filters !== 'object') return '';
  const calc = getFiltersParams(filters);

  if (typeof calc !== 'string' || !calc.length) return '';
  return `filters${getFiltersParams(filters)}`;
};
