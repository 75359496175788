/* eslint-disable no-param-reassign */
import Bugsnag, { Client, Event } from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

export interface IBugsnagWrapper {
  notify(error: Error, severity?: 'info' | 'warning' | 'error', context?: string): void;
  setUser(params: { id: string, email: string, name: string }): void;
}

const isProd = process.env.NUXT_ENV_IS_PROD === 'true';
const isStaging = process.env.NUXT_ENV_IS_STAGING === 'true';
// eslint-disable-next-line no-nested-ternary
const releaseStage = isStaging ? 'staging' : isProd ? 'production' : 'development';

type Experiments = {id: string, variant: string}[];
type BugSnagParams = {
  experiments: Experiments;
}

class BugsnagWrapper implements IBugsnagWrapper {
  private static instance: BugsnagWrapper | null = null;

  private bugsnagClient: Client;

  private constructor({ experiments }: BugSnagParams) {
    console.log('Package Version', process.env.appVersion);
    console.log('Release Stage', releaseStage);

    this.bugsnagClient = Bugsnag.start({
      apiKey: process.env.NUXT_ENV_BUGSNAG_SHOP_API_KEY ?? '',
      plugins: [new BugsnagPluginVue()],
      releaseStage,
      appVersion: process.env.appVersion ?? '0.0.0',
      onError: (event) => {
        event.addMetadata('experiments', experiments);
        BugsnagWrapper.ignoreErrorsFromSafariExtension(event);
      },
    });
  }

  public static getInstance({ experiments }: {experiments: Experiments}): BugsnagWrapper {
    if (!BugsnagWrapper.instance) {
      BugsnagWrapper.instance = new BugsnagWrapper({ experiments });
    }
    return BugsnagWrapper.instance;
  }

  public notify(error: any, severity: 'info' | 'warning' | 'error' = 'error', context: string): void {
    const errorToCapture = error instanceof Error ? error : new Error(error);

    this.bugsnagClient.notify(errorToCapture, event => {
      event.severity = severity;
      if (context) {
        event.context = context;
      }
    });
  }

  public setUser({ id, email, name }: {id?: string, email?: string, name?: string}): void {
    this.bugsnagClient.setUser(id, email, name);
  }

  public getPlugin(pluginName: string): any {
    return this.bugsnagClient.getPlugin(pluginName);
  }

  public leaveBreadcrumb(message: string, metadata: any): void {
    this.bugsnagClient.leaveBreadcrumb(message, metadata);
  }

  private static ignoreErrorsFromSafariExtension(event: Event): boolean {
    for (const frame of event.errors[0].stacktrace) {
      if (frame.file && frame.file.startsWith('webkit-masked-url')) {
        return false;
      }
    }
    return true;
  }
}

export default BugsnagWrapper;
