// NOTE: only items with shipping restrictions have this property: `shippingRestricted`.
import products from './products';
import { DELIVERY_AND_INSTALLATION, STANDARD_SHIPPING_49, STANDARD_SHIPPING_9 } from './shipping';

export default [


  /**
   *  FightCamp Personal Variants
   */

  {
    id: 'personal_S',
    product: products.personal,
    qty: 1,
    price: products.personal.price,
    productName: 'FightCamp Personal, Small',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    shippingRestricted: true,
    caShippingRestricted: true,
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Free-Standing Bag',
    },
    {
      name: 'Quick Wraps - Small',
    },
    {
      name: 'Premium Boxing Gloves - Small/12oz ',
    },
    {
      name: 'Bag Ring',
    },
    ],
  },
  {
    id: 'personal_L',
    product: products.personal,
    qty: 1,
    price: products.personal.price,
    productName: 'FightCamp Personal, Large',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    shippingRestricted: true,
    caShippingRestricted: true,
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Free-Standing Bag',
    },
    {
      name: 'Quick Wraps - Large',
    },
    {
      name: 'Premium Boxing Gloves - Large/16oz',
    },
    {
      name: 'Bag Ring',
    },
    ],
  },
  {
    id: 'personal_L_snappy',
    product: products.personalSnappy,
    qty: 1,
    price: products.personalSnappy.price,
    productName: 'FightCamp Snappy - Personal, Large',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    shippingRestricted: true,
    caShippingRestricted: true,
    subscriptionNeeded: false,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Free-Standing Bag',
    },
    {
      name: 'Quick Wraps - Large',
    },
    {
      name: 'Premium Boxing Gloves - Large/16oz',
    },
    {
      name: 'Bag Ring',
    },
    ],
  },

  /**
   *  FightCamp Gym Variants
   */

  {
    id: 'gym_S',
    product: products.gym,
    qty: 1,
    price: products.gym.price,
    productName: 'FightCamp Gym, Small',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    shippingRestricted: true,
    caShippingRestricted: true,
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Free-Standing Bag',
    },
    {
      name: 'Quick Wraps - Small',
    },
    {
      name: 'Premium Boxing Gloves - Small/12oz ',
    },
    {
      name: 'Heavy Workout Mat',
    },
    ],
  },
  {
    id: 'gym_L',
    product: products.gym,
    qty: 1,
    price: products.gym.price,
    productName: 'FightCamp Gym, Large',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    shippingRestricted: true,
    caShippingRestricted: true,
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Free-Standing Bag',
    },
    {
      name: 'Quick Wraps - Large',
    },
    {
      name: 'Premium Boxing Gloves - Large/16oz',
    },
    {
      name: 'Heavy Workout Mat',
    },
    ],
  },

  /**
   *  FightCamp Tribe Variants
   */

  {
    id: 'tribe_LL',
    product: products.tribe,
    qty: 1,
    price: products.tribe.price,
    productName: 'FightCamp Tribe, Large/Large ',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    shippingRestricted: true,
    caShippingRestricted: true,
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Free-Standing Bag',
    },
    {
      name: 'Quick Wraps - Large (2x)',
    },
    {
      name: 'Premium Boxing Gloves - Large/16oz (2x)',
    },
    {
      name: 'Heavy Workout Mat',
    },
    {
      name: 'Kids Boxing Gloves',
    },
    {
      name: 'Bag Ring',
    },
    ],
  },
  {
    id: 'tribe_SL',
    product: products.tribe,
    qty: 1,
    price: products.tribe.price,
    productName: 'FightCamp Tribe, Small/Large',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    shippingRestricted: true,
    caShippingRestricted: true,
    subscriptionNeeded: true,

    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Free-Standing Bag',
    },
    {
      name: 'Quick Wraps - Small',
    },
    {
      name: 'Quick Wraps - Large',
    },
    {
      name: 'Premium Boxing Gloves - Small/12oz',
    },
    {
      name: 'Premium Boxing Gloves - Large/16oz',
    },
    {
      name: 'Heavy Workout Mat',
    },
    {
      name: 'Kids Boxing Gloves',
    },
    {
      name: 'Bag Ring',
    },
    ],
  },
  {
    id: 'tribe_SS',
    product: products.tribe,
    qty: 1,
    price: products.tribe.price,
    productName: 'FightCamp Tribe, Small/Small',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    shippingRestricted: true,
    caShippingRestricted: true,
    subscriptionNeeded: true,

    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Free-Standing Bag',
    },
    {
      name: 'Quick Wraps - Small (2x)',
    },
    {
      name: 'Premium Boxing Gloves - Small/12oz (2x)',
    },
    {
      name: 'Heavy Workout Mat',
    },
    {
      name: 'Kids Boxing Gloves',
    },
    {
      name: 'Bag Ring',
    },
    ],
  },
  {
    id: 'tribe_SL_snappy',
    product: products.tribeSnappy,
    qty: 1,
    price: products.tribeSnappy.price,
    productName: 'FightCamp Snappy Tribe, Small/Large',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    shippingRestricted: true,
    caShippingRestricted: true,
    subscriptionNeeded: false,

    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Free-Standing Bag',
    },
    {
      name: 'Quick Wraps - Small',
    },
    {
      name: 'Quick Wraps - Large',
    },
    {
      name: 'Premium Boxing Gloves - Small/12oz',
    },
    {
      name: 'Premium Boxing Gloves - Large/16oz',
    },
    {
      name: 'Heavy Workout Mat',
    },
    {
      name: 'Kids Boxing Gloves',
    },
    {
      name: 'Bag Ring',
    },
    ],
  },


  /**
   *  FightCamp Family Variants
   */

  {
    id: 'family_LL',
    product: products.family,
    qty: 1,
    price: products.family.price,
    productName: 'FightCamp Family, Large/Large ',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    shippingRestricted: true,
    caShippingRestricted: true,
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Free-Standing Bag',
    },
    {
      name: 'Quick Wraps - Large (2x)',
    },
    {
      name: 'Premium Boxing Gloves - Large/16oz (2x)',
    },
    {
      name: 'Heavy Workout Mat',
    },
    {
      name: 'Kids Boxing Gloves',
    },
    ],
  },
  {
    id: 'family_SL',
    product: products.family,
    qty: 1,
    price: products.family.price,
    productName: 'FightCamp Family, Small/Large',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    shippingRestricted: true,
    caShippingRestricted: true,
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Free-Standing Bag',
    },
    {
      name: 'Quick Wraps - Small',
    },
    {
      name: 'Quick Wraps - Large',
    },
    {
      name: 'Premium Boxing Gloves - Small/12oz',
    },
    {
      name: 'Premium Boxing Gloves - Large/16oz',
    },
    {
      name: 'Heavy Workout Mat',
    },
    {
      name: 'Kids Boxing Gloves',
    },
    ],
  },
  {
    id: 'family_SS',
    product: products.family,
    qty: 1,
    price: products.family.price,
    productName: 'FightCamp Family, Small/Small',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    shippingRestricted: true,
    caShippingRestricted: true,
    subscriptionNeeded: true,

    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Free-Standing Bag',
    },
    {
      name: 'Quick Wraps - Small (2x)',
    },
    {
      name: 'Premium Boxing Gloves - Small/12oz (2x)',
    },
    {
      name: 'Heavy Workout Mat',
    },
    {
      name: 'Kids Boxing Gloves',
    },
    ],
  },


  /**
 *  FightCamp Connect V2 Variants
 */

  {
    id: 'connect_V2_S',
    product: products.connectv2,
    qty: 1,
    price: products.connectv2.price,
    productName: 'FightCamp Connect, Small',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Small',
    },
    ],

  },
  {
    id: 'connect_V2_L',
    product: products.connectv2,
    qty: 1,
    price: products.connectv2.price,
    productName: 'FightCamp Connect, Large',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,

    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Large',
    },
    ],
  },

  /**
   *  FightCamp Connect Variants
   */

  {
    id: 'connect_S',
    product: products.connect,
    qty: 1,
    price: products.connect.price,
    productName: 'FightCamp Connect, Small',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Small',
    },
    ],

  },
  {
    id: 'connect_L',
    product: products.connect,
    qty: 1,
    price: products.connect.price,
    productName: 'FightCamp Connect, Large',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,

    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Large',
    },
    ],
  },

  /**
   *  FightCamp QuickWraps Variants
   */

  {
    id: 'quickWraps_S',
    product: products.quickwraps,
    qty: 1,
    price: products.quickwraps.price,
    productName: 'FightCamp Quick Wraps, Small',
    pricingDetails: '',
  },
  {
    id: 'quickWraps_L',
    product: products.quickwraps,
    qty: 1,
    price: products.quickwraps.price,
    productName: 'FightCamp Quick Wraps, Large',
    pricingDetails: '',
  },

  /**
   *  FightCamp QuickWraps V2 Variants
   */

  {
    id: 'quickWraps_V2_S',
    product: products.quickwrapsv2,
    qty: 1,
    price: products.quickwrapsv2.price,
    productName: 'FightCamp Quick Wraps, Small',
    pricingDetails: '',
  },
  {
    id: 'quickWraps_V2_S_deal',
    product: products.quickwrapsdeal,
    qty: 1,
    price: products.quickwrapsdeal.price,
    oldPrice: products.quickwrapsdeal.oldPrice,
    productName: 'FightCamp Quick Wraps, Small - PROMOTION',
    pricingDetails: '',
  },
  {
    id: 'quickWraps_V2_S_sale',
    product: products.quickwrapsv2sale,
    qty: 1,
    price: products.quickwrapsv2sale.price,
    oldPrice: products.quickwrapsv2sale.oldPrice,
    productName: 'FightCamp Quick Wraps, Small',
    pricingDetails: '',
  },
  {
    id: 'quickWraps_V2_L',
    product: products.quickwrapsv2,
    qty: 1,
    price: products.quickwrapsv2.price,
    productName: 'FightCamp Quick Wraps, Large',
    pricingDetails: '',
  },
  {
    id: 'quickWraps_V2_L_deal',
    product: products.quickwrapsdeal,
    qty: 1,
    price: products.quickwrapsdeal.price,
    oldPrice: products.quickwrapsdeal.oldPrice,
    productName: 'FightCamp Quick Wraps, Large - PROMOTION',
    pricingDetails: '',
  },
  {
    id: 'quickWraps_V2_L_sale',
    product: products.quickwrapsv2sale,
    qty: 1,
    price: products.quickwrapsv2sale.price,
    oldPrice: products.quickwrapsv2sale.oldPrice,
    productName: 'FightCamp Quick Wraps, Large',
    pricingDetails: '',
  },

  /**
   *  FightCamp QuickWraps 3 Pack Variants
   */
  {
    id: 'quickWraps_3p_SSS',
    product: products.quickwraps3pack,
    qty: 1,
    price: products.quickwraps3pack.price,
    productName: 'FightCamp Quick Wraps (3-Pack), All Small',
    pricingDetails: '',
  },
  {
    id: 'quickWraps_3p_LLL',
    product: products.quickwraps3pack,
    qty: 1,
    price: products.quickwraps3pack.price,
    productName: 'FightCamp Quick Wraps (3-Pack), All Large',
    pricingDetails: '',
  },
  {
    id: 'quickWraps_3p_SSL',
    product: products.quickwraps3pack,
    qty: 1,
    price: products.quickwraps3pack.price,
    productName: 'FightCamp Quick Wraps (3-Pack), 2 Small + 1 Large',
    pricingDetails: '',
  },
  {
    id: 'quickWraps_3p_LLS',
    product: products.quickwraps3pack,
    qty: 1,
    price: products.quickwraps3pack.price,
    productName: 'FightCamp Quick Wraps (3-Pack), 2 Large + 1 Small',
    pricingDetails: '',
  },
  {
    id: 'quickWraps_3p_SSS_sale',
    product: products.quickwraps3packsale,
    qty: 1,
    price: products.quickwraps3packsale.price,
    productName: 'FightCamp Quick Wraps (3-Pack), All Small',
    pricingDetails: '',
  },
  {
    id: 'quickWraps_3p_LLL_sale',
    product: products.quickwraps3packsale,
    qty: 1,
    price: products.quickwraps3packsale.price,
    productName: 'FightCamp Quick Wraps (3-Pack), All Large',
    pricingDetails: '',
  },
  {
    id: 'quickWraps_3p_SSL_sale',
    product: products.quickwraps3packsale,
    qty: 1,
    price: products.quickwraps3packsale.price,
    productName: 'FightCamp Quick Wraps (3-Pack), 2 Small + 1 Large',
    pricingDetails: '',
  },
  {
    id: 'quickWraps_3p_LLS_sale',
    product: products.quickwraps3packsale,
    qty: 1,
    price: products.quickwraps3packsale.price,
    productName: 'FightCamp Quick Wraps (3-Pack), 2 Large + 1 Small',
    pricingDetails: '',
  },

  /**
 *  FightCamp Traditional Wraps
 */

  {
    id: 'fightcamp_traditional_wraps',
    product: products.traditionalwraps,
    qty: 1,
    price: products.traditionalwraps.price,
    productName: 'FightCamp Traditional Wraps',
    pricingDetails: '',
  },

  {
    id: 'fightcamp_traditional_wraps_bpi',
    product: products.traditionalwrapsbpi,
    qty: 1,
    price: products.traditionalwrapsbpi.price,
    productName: 'FightCamp Traditional Wraps',
    pricingDetails: '',
  },

  /**
   *  FightCamp Kids Gloves v2 Variants
   */

  {
    id: 'customGloves_V2_K',
    product: products.kidsglovesv2,
    product_id: 'fightcamp_kidsgloves',
    qty: 1,
    price: products.kidsglovesv2.price,
    productName: 'FightCamp Kids Gloves',
    pricingDetails: '',
    pricingDescription: '',
  },
  {
    id: 'customGloves_V2_K_sale',
    product: products.kidsglovesv2sale,
    product_id: 'fightcamp_kidsgloves_sale',
    qty: 1,
    price: products.kidsglovesv2sale.price,
    productName: 'FightCamp Kids Gloves',
    pricingDetails: '',
    pricingDescription: '',
  },
  {
    id: 'customGloves_V2_K_deal',
    product: products.kidsglovesv2deal,
    product_id: 'fightcamp_kidsgloves_deal',
    qty: 1,
    price: products.kidsglovesv2deal.price,
    productName: 'FightCamp Kids Gloves (Gift)',
    pricingDetails: '',
    pricingDescription: '',
  },


  /**
   *  FightCamp Kids Gloves Variants
   */

  {
    id: 'customGloves_K',
    product: products.kidsgloves,

    product_id: 'fightcamp_kidsgloves',
    qty: 1,
    price: products.kidsgloves.price,
    productName: 'FightCamp Kids Gloves',
    pricingDetails: '',
    pricingDescription: '',
  },
  /**
   *  FightCamp Boxing Gloves v2 Variants
   */

  {
    id: 'customGloves_V2_S',
    product: products.glovesv2,
    qty: 1,
    price: products.glovesv2.price,
    productName: 'FightCamp Gloves, Small/12oz',
    pricingDetails: '',
    pricingDescription: '',
  },
  {
    id: 'customGloves_V2_L',
    product: products.glovesv2,
    qty: 1,
    price: products.glovesv2.price,
    productName: 'FightCamp Gloves, Large/16oz',
    pricingDetails: '',
    pricingDescription: '',
  },
  {
    id: 'customGloves_V2_S_deal',
    product: products.glovesv2deal,
    qty: 1,
    price: products.glovesv2deal.price,
    productName: 'FightCamp Gloves, Small/12oz (Gift)',
    pricingDetails: '',
    pricingDescription: '',
  },
  {
    id: 'customGloves_V2_L_deal',
    product: products.glovesv2deal,
    qty: 1,
    price: products.glovesv2deal.price,
    productName: 'FightCamp Gloves, Large/16oz (Gift)',
    pricingDetails: '',
    pricingDescription: '',
  },
  {
    id: 'customGloves_V2_S_sale',
    product: products.glovesv2sale,
    qty: 1,
    price: products.glovesv2sale.price,
    productName: 'FightCamp Gloves, Small/12oz',
    pricingDetails: '',
    pricingDescription: '',
  },
  {
    id: 'customGloves_V2_L_sale',
    product: products.glovesv2sale,
    qty: 1,
    price: products.glovesv2sale.price,
    productName: 'FightCamp Gloves, Large/16oz',
    pricingDetails: '',
    pricingDescription: '',
  },

  /**
   *  FightCamp Boxing Gloves Variants
   */

  {
    id: 'customGloves_S',
    product: products.gloves,
    qty: 1,
    price: products.gloves.price,
    productName: 'FightCamp Gloves, Small/12oz',
    pricingDetails: '',
    pricingDescription: '',
  },
  {
    id: 'customGloves_L',
    product: products.gloves,
    qty: 1,
    price: products.gloves.price,
    productName: 'FightCamp Gloves, Large/16oz',
    pricingDetails: '',
    pricingDescription: '',
  },

  /**
   *  FightCamp Bag Variants
   */

  {
    id: 'freeStandingBag',
    product: products.bag,
    qty: 1,
    price: products.bag.price,
    productName: 'FightCamp Free-Standing Bag ',
    pricingDetails: '',
    pricingDescription: '2018 Design',
    shippingRestricted: true,
    partialCaShippingRestricted: true,
  },
  {
    id: 'freeStandingBag_V2',
    product: products.bagv2,
    qty: 1,
    price: products.bagv2.price,
    productName: 'FightCamp Free-Standing Bag',
    pricingDetails: '',
    pricingDescription: '',
    shippingRestricted: true,
    partialCaShippingRestricted: true,
    subscriptionNeeded: false,
  },
  {
    id: 'freeStandingBag_V3',
    product: products.bagv3,
    qty: 1,
    price: products.bagv3.price,
    productName: 'FightCamp Free-Standing Bag',
    pricingDetails: '',
    pricingDescription: '',
    shippingRestricted: true,
    partialCaShippingRestricted: true,
    subscriptionNeeded: false,
  },

  /**
   *  FightCamp Bag Variants
   */

  {
    id: 'rubberWorkoutMat',
    product: products.rubbermat,
    qty: 1,
    price: products.rubbermat.price,
    productName: 'FightCamp Rubber Workout Mat',
    pricingDetails: '',
    pricingDescription: '',
    shippingRestricted: true,
    caShippingRestricted: true,
  },

  /**
   *  FightCamp Subscription Variants
   */

  {
    id: 'FightCamp_1_MonthC',
    product: products.subscription,
    qty: 1,
    price: 0,
    productName: 'FightCamp Membership',
    subid: 'fightcampplanC',
    pricingDetails: '',
    pricingDescription: 'membership to unlimited FightCamp content. $0 due today. Billed upon equipment delivery.',
  },
  {
    id: 'FightCamp_Affirm',
    product: products.subscription,
    qty: 1,
    price: 93600,
    productName: 'Membership (2 Years @ $39/mo)',
    subid: 'fightcampplanAffirm',
    pricingDetails: '',
    pricingDescription: 'Unlimited access to on-demand workouts & advanced features in the FightCamp app.',
  },
  {
    id: 'FightCamp_1_Year',
    product: products.subscription,
    qty: 1,
    price: 46800,
    productName: 'Membership (1 Years @ $39/mo)',
    subid: 'prepay1year',
    pricingDetails: '',
    pricingDescription: 'Unlimited access to on-demand workouts & advanced features in the FightCamp app.',
  },
  {
    id: 'FightCamp_2_Year',
    product: products.subscription,
    qty: 1,
    price: 93600,
    productName: 'Membership (2 Years @ $39/mo)',
    subid: 'prepay2years',
    pricingDetails: '',
    pricingDescription: 'Unlimited access to on-demand workouts & advanced features in the FightCamp app.',
  },
  {
    id: 'FightCamp_1_Year_Disc',
    product: products.subscription,
    qty: 1,
    price: 42120,
    productName: 'Membership (1 Year @ $35.10/mo)',
    subid: 'incentive1year',
    pricingDetails: '',
    pricingDescription: 'Unlimited access to on-demand workouts & advanced features in the FightCamp app.',
  },
  {
    id: 'FightCamp_2_Years_Disc',
    product: products.subscription,
    qty: 1,
    price: 79560,
    productName: 'Membership (2 Years @ $33.15/mo)',
    subid: 'incentive2years',
    pricingDetails: '',
    pricingDescription: 'Unlimited access to on-demand workouts & advanced features in the FightCamp app.',
  },
{
    id: 'fightcamp_subscription_yearly_included',
    product: products.fightcampSubscriptionYearly,
    qty: 1,
    price: 0,
    productName: 'Membership (1 Year @ $35.10/mo)',
    subid: 'yearlyPlanC',
    pricingDetails: '',
    pricingDescription: 'Unlimited access to on-demand workouts & advanced features in the FightCamp app.',
  },
  {
    id: 'fightcamp_subscription_yearly',
    product: products.fightcampSubscriptionYearly,
    qty: 1,
    price: 0,
    productName: 'Membership (1 Year @ $35.10/mo)',
    subid: 'yearlyPlanCBizDevPos',
    pricingDetails: '',
    pricingDescription: 'Unlimited access to on-demand workouts & advanced features in the FightCamp app.',
  },
  {
    id: 'GQ_FightCamp_1_Month',
    product: products.subscription,
    qty: 1,
    price: 0,
    productName: 'FightCamp Membership',
    subid: 'GQfightcampplan',
    pricingDetails: '',
    pricingDescription: 'membership to unlimited FightCamp content. $0 due today with 45 day trial period.',
  },
  {
    id: 'Partner_FightCamp_1_Month',
    product: products.subscription,
    qty: 1,
    price: 0,
    productName: 'FightCamp Membership',
    subid: 'partnerfightcampplan',
    pricingDetails: '',
    pricingDescription: 'membership to unlimited FightCamp content. $0 due today with 45 day trial period.',
  },

  /**
   *  FightCamp Delivery Variants
   */

  {
    id: 'assembly_deliveright',
    product: products.subscription,
    qty: 1,
    price: 0,
    productName: 'Premium Delivery & Assembly',
    pricingDetails: '',
    pricingDescription: 'Fully assembled by Deliveright Experts, installed wherever you plan to workout',
  },


  /**
   *  FightCamp Care Kit
   */

  {
    id: 'fightcamp_care_kit',
    product: products.carekit,
    qty: 1,
    price: products.carekit.price,
    productName: 'FightCamp Care Kit',
    pricingDetails: '',
    pricingDescription: 'Stay hydrated, towel off and store your FightCamp equipment in style.',
    items: [{
      name: 'Glove Deodorizers',
    },
    {
      name: 'Glove Bag',
    },
    {
      name: 'Quick Wrap Storage Bag',
    },
    {
      name: 'Stainless-Steel Water Bottle',
    },
    {
      name: 'FightCamp towel',
    },
    ],
  },

  {
    id: 'fightcamp_care_kit_deal',
    product: products.carekitdeal,
    qty: 1,
    price: products.carekitdeal.price,
    oldPrice: products.carekitdeal.oldPrice,
    productName: 'FightCamp Care Kit - PROMOTION',
    pricingDetails: '',
    pricingDescription: 'Stay hydrated, towel off and store your FightCamp equipment in style.',
    items: [{
      name: 'Glove Deodorizers',
    },
    {
      name: 'Glove Bag',
    },
    {
      name: 'Quick Wrap Storage Bag',
    },
    {
      name: 'Stainless-Steel Water Bottle',
    },
    {
      name: 'FightCamp towel',
    },
    ],
  },

  /**
   *  FightCamp Conditioning Kit
   */

  {
    id: 'fightcamp_conditioning_kit',
    product: products.conditioningkit,
    qty: 1,
    price: products.conditioningkit.price,
    productName: 'Conditioning Kit',
    pricingDetails: '',
    pricingDescription: 'Everything you need to maximize your training experience.',
    items: [{
      name: 'Hypersphere Mini',
    },
    {
      name: 'Speed Jump Rope',
    },
    {
      name: 'Agility Ladder',
    },
    {
      name: 'Hand Weights',
    },
    ],
  },

  {
    id: 'fightcamp_conditioning_kit_deal',
    product: products.conditioningkitdeal,
    qty: 1,
    price: products.conditioningkitdeal.price,
    oldPrice: products.conditioningkitdeal.oldPrice,
    productName: 'Conditioning Kit - PROMOTION',
    pricingDetails: '',
    pricingDescription: 'Everything you need to maximize your training experience.',
    items: [{
      name: 'Hypersphere Mini',
    },
    {
      name: 'Speed Jump Rope',
    },
    {
      name: 'Agility Ladder',
    },
    {
      name: 'Hand Weights',
    },
    ],
  },
  {
    id: 'fightcamp_endurance_kit_deal',
    product: products.endurancekitdeal,
    qty: 1,
    price: products.endurancekitdeal.price,
    oldPrice: products.endurancekitdeal.oldPrice,
    productName: 'FightCamp Endurance Kit - PROMOTION',
    pricingDetails: '',
    pricingDescription: 'Build strength and endurance in your FightCamp workout.',
    items: [
      {
        name: 'Connected Heart Rate Monitor',
      },
      {
        name: 'Resistance Bands',
      },
      {
        name: 'Premium Jump Rope',
      },
      {
        name: 'Yoga Mat',
      },
    ],
  },
  {
    id: 'fightcamp_endurance_kit',
    product: products.endurancekit,
    qty: 1,
    price: products.endurancekit.price,
    productName: 'FightCamp Endurance Kit',
    pricingDetails: '',
    pricingDescription: 'Build strength and endurance in your FightCamp workout.',
    items: [
      {
        name: 'Connected Heart Rate Monitor',
      },
      {
        name: 'Resistance Bands',
      },
      {
        name: 'Premium Jump Rope',
      },
      {
        name: 'Yoga Mat',
      },
    ],
  },

  /**
   *  FightCamp Bag Ring
   */

  {
    id: 'fightcamp_bag_ring',
    product: products.bagring,
    qty: 1,
    price: products.bagring.price,
    productName: 'FightCamp Bag Ring',
    pricingDetails: '',
    pricingDescription: '',
    shippingRestricted: true,
    partialCaShippingRestricted: true,
  },
  {
    id: 'fightcamp_bag_ring_deal',
    product: products.bagringdeal,
    qty: 1,
    price: products.bagringdeal.price,
    oldPrice: products.bagringdeal.oldPrice,
    productName: 'FightCamp Bag Ring - PROMOTION',
    pricingDetails: '',
    pricingDescription: '',
    shippingRestricted: true,
    partialCaShippingRestricted: true,
  },
  {
    id: 'fightcamp_bag_bag_ring_bundle',
    product: products.bagv2,
    qty: 1,
    price: products.bagv2.price,
    productName: 'FightCamp Free-Standing Bag System',
    pricingDetails: '',
    pricingDescription: 'The best free-standing bag on the market. Designed to look beautiful in your home & built to stand up to the toughest fighters.',
    shippingRestricted: true,
    partialCaShippingRestricted: true,
    items: [
      {
        name: 'Free Standing Bag',
      },
      {
        name: 'Bag Ring',
      }
    ],
  },
  {
    id: 'standard_shipping_99',
    product: {
      title: 'Standard Shipping 99',
      id: 'standard_shipping_99',
      price: 0,
      category: 'shipping',
    },
    qty: 1,
    price: 0,
    productName: 'Standard Shipping',
  },
  {
    id: STANDARD_SHIPPING_49,
    product: {
      title: 'Standard Shipping 49',
      id: STANDARD_SHIPPING_49,
      price: 0,
      category: 'shipping',
    },
    qty: 1,
    price: 0,
    productName: 'Standard Shipping',
  },
  {
    id: 'standard_shipping_14',
    product: {
      title: 'Standard Shipping 14',
      id: 'standard_shipping_14',
      price: 0,
      category: 'shipping',
    },
    qty: 1,
    price: 0,
    productName: 'Standard Shipping',
  },
  {
    id: STANDARD_SHIPPING_9,
    product: {
      title: 'Standard Shipping 9',
      id: STANDARD_SHIPPING_9,
      price: 0,
      category: 'shipping',
    },
    qty: 1,
    price: 0,
    productName: 'Standard Shipping',
  },
  {
    id: DELIVERY_AND_INSTALLATION,
    product: {
      title: 'Delivery and Installation',
      id: DELIVERY_AND_INSTALLATION,
      price: 0,
      category: 'shipping',
    },
    qty: 1,
    price: 0,
    productName: 'Delivery and Installation',
  },
  {
    id: 'trackers_S',
    product: products.fightcampTrackers,
    qty: 1,
    price: products.fightcampTrackers.price,
    productName: 'FightCamp Trackers, Small',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Small',
    },
    ],
  },
  {
    id: 'trackers_L',
    product: products.fightcampTrackers,
    qty: 1,
    price: products.fightcampTrackers.price,
    productName: 'FightCamp Trackers, Large',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Large',
    },
    ],
  },
  {
    id: 'trackers_with_bag_S',
    product: products.fightcampTrackersBag,
    qty: 1,
    price: products.fightcampTrackersBag.price,
    productName: 'FightCamp Trackers + Bag, Small',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,
    shippingRestricted: true,
    partialCaShippingRestricted: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Small',
    },
    {
      name: 'Free-Standing Bag',
    },
    ],
  },
  {
    id: 'trackers_with_bag_L',
    product: products.fightcampTrackersBag,
    qty: 1,
    price: products.fightcampTrackersBag.price,
    productName: 'FightCamp Trackers + Bag, Large',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,
    shippingRestricted: true,
    partialCaShippingRestricted: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Large',
    },
    {
      name: 'Free-Standing Bag',
    },
    ],
  },
  {
    id: 'trackers_S_399',
    product: products.fightcampTrackers399,
    qty: 1,
    price: products.fightcampTrackers399.price,
    productName: 'FightCamp Trackers, Small',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Small',
    },
    ],
  },
  {
    id: 'trackers_L_399',
    product: products.fightcampTrackers399,
    qty: 1,
    price: products.fightcampTrackers399.price,
    productName: 'FightCamp Trackers, Large',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Large',
    },
    ],
  },
  {
    id: 'trackers_with_bag_S_749',
    product: products.fightcampTrackersBag749,
    qty: 1,
    price: products.fightcampTrackersBag749.price,
    productName: 'FightCamp Trackers + Bag, Small',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,
    shippingRestricted: true,
    partialCaShippingRestricted: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Small',
    },
    {
      name: 'Free-Standing Bag',
    },
    ],
  },
  {
    id: 'trackers_with_bag_L_749',
    product: products.fightcampTrackersBag749,
    qty: 1,
    price: products.fightcampTrackersBag749.price,
    productName: 'FightCamp Trackers + Bag, Large',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,
    shippingRestricted: true,
    partialCaShippingRestricted: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Large',
    },
    {
      name: 'Free-Standing Bag',
    },
    ],
  },
  {
    id: 'fightcamp_bundle_better_every_day',
    product: products.bundleBetterEveryDay,
    qty: 1,
    price: products.bundleBetterEveryDay.price,
    productName: 'FightCamp Better Every Day Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    partialCaShippingRestricted: true,
  },
  {
    id: 'fightcamp_bundle_better_every_day_sale',
    product: products.bundleBetterEveryDaySale,
    qty: 1,
    price: products.bundleBetterEveryDaySale.price,
    productName: 'FightCamp Better Every Day Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    partialCaShippingRestricted: true,
  },
  {
    id: 'fightcamp_bundle_train_fighter',
    product: products.bundleTrainFighter,
    qty: 1,
    price: products.bundleTrainFighter.price,
    productName: 'FightCamp Train Like a Fighter Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    partialCaShippingRestricted: true,
  },
  {
    id: 'fightcamp_bundle_train_fighter_sale',
    product: products.bundleTrainFighterSale,
    qty: 1,
    price: products.bundleTrainFighterSale.price,
    productName: 'FightCamp Train Like a Fighter Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    partialCaShippingRestricted: true,
  },
  {
    id: 'fightcamp_bundle_ultimate_S',
    product: products.bundleUltimate,
    qty: 1,
    price: products.bundleUltimate.price,
    items: [
      {
        name: '1 Pair of Gloves - Small (12oz)',
      },
      {
        name: 'Bag Ring',
      },
      {
        name: 'Put It On TV Bundle',
      },
      {
        name: 'Premium Flooring',
      },
    ],
    productName: 'FightCamp The Works Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    caShippingRestricted: true
  },
  {
    id: 'holiday_2023_fightcamp_bundle_ultimate_S',
    product: products.fightcamp2023HolidayBundleUltimate,
    qty: 1,
    price: products.fightcamp2023HolidayBundleUltimate.price,
    items: [
      {
        name: '1 Pair of Gloves - Small (12oz)',
      },
      {
        name: 'Bag Ring',
      },
      {
        name: 'Put It On TV Bundle',
      },
      {
        name: 'Premium Flooring',
      },
    ],
    productName: 'FightCamp The Works Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    caShippingRestricted: true
  },
  {
    id: 'fightcamp_bundle_ultimate_L',
    product: products.bundleUltimate,
    qty: 1,
    price: products.bundleUltimate.price,
    items: [
      {
        name: '1 Pair of Gloves - Large (16oz)',
      },
      {
        name: 'Bag Ring',
      },
      {
        name: 'Put It On TV Bundle',
      },
      {
        name: 'Premium Flooring',
      },
    ],
    productName: 'FightCamp The Works Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    caShippingRestricted: true
  },
  {
    id: 'holiday_2023_fightcamp_bundle_ultimate_L',
    product: products.fightcamp2023HolidayBundleUltimate,
    qty: 1,
    price: products.fightcamp2023HolidayBundleUltimate.price,
    items: [
      {
        name: '1 Pair of Gloves - Large (16oz)',
      },
      {
        name: 'Bag Ring',
      },
      {
        name: 'Put It On TV Bundle',
      },
      {
        name: 'Premium Flooring',
      },
    ],
    productName: 'FightCamp The Works Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    caShippingRestricted: true
  },
  {
    id: 'fightcamp_bundle_keep_fresh_SSS',
    product: products.bundleKeepFresh,
    qty: 1,
    price: products.bundleKeepFresh.price,
    productName: 'FightCamp Keep It Fresh Bundle, Small',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    partialCaShippingRestricted: true,
  },
  {
    id: 'fightcamp_bundle_keep_fresh_LLL',
    product: products.bundleKeepFresh,
    qty: 1,
    price: products.bundleKeepFresh.price,
    productName: 'FightCamp Keep It Fresh Bundle, Large',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    caShippingRestricted: true
  },
  {
    id: 'fightcamp_bundle_keep_fresh_SSL',
    product: products.bundleKeepFresh,
    qty: 1,
    price: products.bundleKeepFresh.price,
    productName: 'FightCamp Keep It Fresh Bundle, 2 Small + 1 Large',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    partialCaShippingRestricted: true,
  },
  {
    id: 'fightcamp_bundle_keep_fresh_SLL',
    product: products.bundleKeepFresh,
    qty: 1,
    price: products.bundleKeepFresh.price,
    productName: 'FightCamp Keep It Fresh Bundle, 1 Small + 2 Large',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    partialCaShippingRestricted: true,
  },
  {
    id: 'fightcamp_bundle_keep_fresh_SSS_sale',
    product: products.bundleKeepFreshSale,
    qty: 1,
    price: products.bundleKeepFreshSale.price,
    productName: 'FightCamp Keep It Fresh Bundle, Small',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    partialCaShippingRestricted: true,
  },
  {
    id: 'fightcamp_bundle_keep_fresh_LLL_sale',
    product: products.bundleKeepFreshSale,
    qty: 1,
    price: products.bundleKeepFreshSale.price,
    productName: 'FightCamp Keep It Fresh Bundle, Large',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    caShippingRestricted: true
  },
  {
    id: 'fightcamp_bundle_keep_fresh_SSL_sale',
    product: products.bundleKeepFreshSale,
    qty: 1,
    price: products.bundleKeepFreshSale.price,
    productName: 'FightCamp Keep It Fresh Bundle, 2 Small + 1 Large',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    partialCaShippingRestricted: true,
  },
  {
    id: 'fightcamp_bundle_keep_fresh_SLL_sale',
    product: products.bundleKeepFreshSale,
    qty: 1,
    price: products.bundleKeepFreshSale.price,
    productName: 'FightCamp Keep It Fresh Bundle, 1 Small + 2 Large',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    partialCaShippingRestricted: true,
  },
  {
    id: 'fightcamp_bundle_protect_floor',
    product: products.bundleProtectFloor,
    qty: 1,
    price: products.bundleProtectFloor.price,
    productName: 'FightCamp Protect Your Floor Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    shippingRestricted: true,
    caShippingRestricted: true,
  },
  {
    id: 'fightcamp_bundle_protect_floor_sale',
    product: products.bundleProtectFloorSale,
    qty: 1,
    price: products.bundleProtectFloorSale.price,
    productName: 'FightCamp Protect Your Floor Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    shippingRestricted: true,
    caShippingRestricted: true,
  },
  {
    id: 'fightcamp_bundle_tv',
    product: products.bundleTv,
    qty: 1,
    price: products.bundleTv.price,
    productName: 'FightCamp Put It On the TV Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    partialCaShippingRestricted: true,
  },
  {
    id: 'fightcamp_family_bundle',
    product: products.bundleFamily,
    qty: 1,
    price: products.bundleFamily.price,
    productName: 'FightCamp Family Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    partialCaShippingRestricted: true,
  },
  {
    id: 'fightcamp_tracker_sleeves',
    product: products.trackerSleeve,
    qty: 1,
    price: products.trackerSleeve.price,
    productName: 'Punch Tracker Sleeve',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_hdmi_cable',
    product: products.fightcampHdmiCable,
    qty: 1,
    price: products.fightcampHdmiCable.price,
    productName: '6-ft HDMI Cable',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_shi_apple_cable',
    product: products.fightcampShiAppleCable,
    qty: 1,
    price: products.fightcampShiAppleCable.price,
    productName: 'HDMI-to-Lightning Adapter',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_heart_rate_monitor',
    product: products.fightcampHeartRateMonitor,
    qty: 1,
    price: products.fightcampHeartRateMonitor.price,
    productName: 'Heart Rate Monitor',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_heart_rate_monitor_deal',
    product: products.fightcampHeartRateMonitorDeal,
    qty: 1,
    price: products.fightcampHeartRateMonitorDeal.price,
    productName: 'Heart Rate Monitor (Gift)',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_heart_rate_monitor_sale',
    product: products.fightcampHeartRateMonitorSale,
    qty: 1,
    price: products.fightcampHeartRateMonitorSale.price,
    productName: 'Heart Rate Monitor',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_jump_rope',
    product: products.fightcampJumpRope,
    qty: 1,
    price: products.fightcampJumpRope.price,
    productName: 'Premium Jump Rope',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_jump_rope_deal',
    product: products.fightcampJumpRopeDeal,
    qty: 1,
    price: products.fightcampJumpRopeDeal.price,
    productName: 'Premium Jump Rope (Gift)',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_jump_rope_sale',
    product: products.fightcampJumpRopeSale,
    qty: 1,
    price: products.fightcampJumpRopeSale.price,
    productName: 'Premium Jump Rope',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_resistance_bands',
    product: products.fightcampResistanceBands,
    qty: 1,
    price: products.fightcampResistanceBands.price,
    productName: 'Resistance Bands',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_resistance_bands_deal',
    product: products.fightcampResistanceBandsDeal,
    qty: 1,
    price: products.fightcampResistanceBandsDeal.price,
    productName: 'Resistance Bands (Gift)',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_weighted_quick_wraps',
    product: products.fightcampWeightedQuickWraps,
    qty: 1,
    price: products.fightcampWeightedQuickWraps.price,
    productName: 'Weighted Quick Wraps',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_agility_ladder',
    product: products.fightcampAgilityLadder,
    qty: 1,
    price: products.fightcampAgilityLadder.price,
    productName: 'Agility Ladder',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_glove_freshener',
    product: products.fightcampGloveFreshener,
    qty: 1,
    price: products.fightcampGloveFreshener.price,
    productName: 'Glove Freshener',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_glove_bag',
    product: products.fightcampGloveBag,
    qty: 1,
    price: products.fightcampGloveBag.price,
    productName: 'Glove Bag',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_laundry_bag',
    product: products.fightcampLaundryBag,
    qty: 1,
    price: products.fightcampLaundryBag.price,
    productName: 'Wrap Laundry Bag',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_water_bottle',
    product: products.fightcampWaterBottle,
    qty: 1,
    price: products.fightcampWaterBottle.price,
    productName: 'Premium Water Bottle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_yoga_mat',
    product: products.fightcampYogaMat,
    qty: 1,
    price: products.fightcampYogaMat.price,
    productName: 'Premium Exercise Mat',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_exercise_mat_deal',
    product: products.fightcampExerciseMatDeal,
    qty: 1,
    price: products.fightcampExerciseMatDeal.price,
    productName: 'Premium Exercise Mat (Gift)',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_premium_flooring',
    product: products.fightcampPremiumFlooring,
    qty: 1,
    price: products.fightcampPremiumFlooring.price,
    productName: 'Premium Flooring',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    shippingRestricted: true,
    caShippingRestricted: true,
  },
  {
    id: 'fightcamp_premium_flooring_sale',
    product: products.fightcampPremiumFlooringSale,
    qty: 1,
    price: products.fightcampPremiumFlooringSale.price,
    productName: 'Premium Flooring',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    shippingRestricted: true,
    caShippingRestricted: true,
  },
  {
    id: 'fightcamp_bag_ring_bpi',
    product: products.fightcampBagRingBpi,
    qty: 1,
    price: products.fightcampBagRingBpi.price,
    productName: 'FightCamp Bag Ring',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
    shippingRestricted: true,
    partialCaShippingRestricted: true,
  },
  {
    id: 'transferredPlan',
    product: products.transferredPlan,
    qty: 1,
    price: products.transferredPlan.price,
    productName: 'FightCamp Transferred Membership',
    pricingDetails: '',
    pricingDescription: 'Your membership gives you access to the full library of workouts & advanced features. See real-time stats, measure progress over time, and compete against others. Each membership supports up to 5 user profiles. FightCamp Punch Trackers are required. $39/month + applicable tax.',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_trackers_bag_ext_warranty_79',
    product: products.fightcampTrackersBagExtWarranty79,
    qty: 1,
    price: products.fightcampTrackersBagExtWarranty79.price,
    productName: 'FightCamp Trackers + Bag Extended Warranty',
    pricingDetails: 'Warranty extended to 36 months to protect your Trackers or Bag.',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_trackers_ext_warranty_19',
    product: products.fightcampTrackersExtWarranty19,
    qty: 1,
    price: products.fightcampTrackersExtWarranty19.price,
    productName: 'FightCamp Trackers Extended Warranty',
    pricingDetails: 'Warranty extended to 36 months to protect your Trackers.',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21HD003-S',
    product: products.fightcampLogoHoodieCharcoalHeather,
    qty: 1,
    price: products.fightcampLogoHoodieCharcoalHeather.price,
    productName: 'FightCamp Logo Hoodie - Charcoal Heather Small',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21HD003-M',
    product: products.fightcampLogoHoodieCharcoalHeather,
    qty: 1,
    price: products.fightcampLogoHoodieCharcoalHeather.price,
    productName: 'FightCamp Logo Hoodie - Charcoal Heather Medium',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21HD003-L',
    product: products.fightcampLogoHoodieCharcoalHeather,
    qty: 1,
    price: products.fightcampLogoHoodieCharcoalHeather.price,
    productName: 'FightCamp Logo Hoodie - Charcoal Heather Large',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21HD001-XS',
    product: products.fightcampLogoHoodieOatmeal,
    qty: 1,
    price: products.fightcampLogoHoodieOatmeal.price,
    productName: 'FightCamp Logo Hoodie - Oatmeal X-Small',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21HD001-S',
    product: products.fightcampLogoHoodieOatmeal,
    qty: 1,
    price: products.fightcampLogoHoodieOatmeal.price,
    productName: 'FightCamp Logo Hoodie - Oatmeal Small',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21HD001-M',
    product: products.fightcampLogoHoodieOatmeal,
    qty: 1,
    price: products.fightcampLogoHoodieOatmeal.price,
    productName: 'FightCamp Logo Hoodie - Oatmeal Medium ',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21HD001-L',
    product: products.fightcampLogoHoodieOatmeal,
    qty: 1,
    price: products.fightcampLogoHoodieOatmeal.price,
    productName: 'FightCamp Logo Hoodie - Oatmeal Large',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21SS001-XS',
    product: products.fightcampLogoTeeBlack,
    qty: 1,
    price: products.fightcampLogoTeeBlack.price,
    productName: 'FightCamp Logo Tee - Black X-Small',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21SS001-S',
    product: products.fightcampLogoTeeBlack,
    qty: 1,
    price: products.fightcampLogoTeeBlack.price,
    productName: 'FightCamp Logo Tee - Black Small',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21SS001-M',
    product: products.fightcampLogoTeeBlack,
    qty: 1,
    price: products.fightcampLogoTeeBlack.price,
    productName: 'FightCamp Logo Tee - Black Medium',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21SS001-L',
    product: products.fightcampLogoTeeBlack,
    qty: 1,
    price: products.fightcampLogoTeeBlack.price,
    productName: 'FightCamp Logo Tee - Black Large',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21SS001-XXL',
    product: products.fightcampLogoTeeBlack,
    qty: 1,
    price: products.fightcampLogoTeeBlack.price,
    productName: 'FightCamp Logo Tee - Black XX-Large',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_traditional_wraps_blkwht',
    product: products.fightcampTraditionalWrapsBlkwht,
    qty: 1,
    price: products.fightcampTraditionalWrapsBlkwht.price,
    productName: 'FightCamp Traditional Hand Wrap FC BlkWh',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'fightcamp_sweat_towel',
    product: products.fightcampSweatTowel,
    qty: 1,
    price: products.fightcampSweatTowel.price,
    productName: 'FightCamp Sweat Towel',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  //
  {
    id: 'FC-MCOLOURTNKBLK-S',
    product: products.fightcampMensTankBlack,
    qty: 1,
    price: products.fightcampMensTankBlack.price,
    productName: "FightCamp Men's Tank - Black Small",
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-MCOLOURTNKBLK-M',
    product: products.fightcampMensTankBlack,
    qty: 1,
    price: products.fightcampMensTankBlack.price,
    productName: "FightCamp Men's Tank - Black Medium",
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-MCOLOURTNKBLK-L',
    product: products.fightcampMensTankBlack,
    qty: 1,
    price: products.fightcampMensTankBlack.price,
    productName: "FightCamp Men's Tank - Black Large",
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  // Below are sold out
  // {
  //   id: 'FC-MCOLOURTNKBLK-XL',
  //   product: products.fightcampMensTankBlack,
  //   qty: 1,
  //   price: products.fightcampMensTankBlack.price,
  //   productName: "FightCamp Men's Tank - Black X-Large",
  //   pricingDetails: '',
  //   pricingDescription: '',
  //   subscriptionNeeded: false,
  // },
  // {
  //   id: 'FC-MCOLOURTNKBLK-XXL',
  //   product: products.fightcampMensTankBlack,
  //   qty: 1,
  //   price: products.fightcampMensTankBlack.price,
  //   productName: "FightCamp Men's Tank - Black XX-Large",
  //   pricingDetails: '',
  //   pricingDescription: '',
  //   subscriptionNeeded: false,
  // },
  {
    id: 'FC-WBELLATNKBLK-M',
    product: products.fightcampWomensTankBlack,
    qty: 1,
    price: products.fightcampWomensTankBlack.price,
    productName: "FightCamp Women's Tank - Black Medium",
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-WBELLATNKBLK-L',
    product: products.fightcampWomensTankBlack,
    qty: 1,
    price: products.fightcampWomensTankBlack.price,
    productName: "FightCamp Women's Tank - Black Large",
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-WBELLATNKBLK-XL',
    product: products.fightcampWomensTankBlack,
    qty: 1,
    price: products.fightcampWomensTankBlack.price,
    productName: "FightCamp Women's Tank - Black X-Large",
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21ZHD001-XS',
    product: products.fightcampFullZipHoodie,
    qty: 1,
    price: products.fightcampFullZipHoodie.price,
    productName: "FightCamp Full Zip Hoodie - X-Small",
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21ZHD001-S',
    product: products.fightcampFullZipHoodie,
    qty: 1,
    price: products.fightcampFullZipHoodie.price,
    productName: "FightCamp Full Zip Hoodie - Small",
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21ZHD001-M',
    product: products.fightcampFullZipHoodie,
    qty: 1,
    price: products.fightcampFullZipHoodie.price,
    productName: "FightCamp Full Zip Hoodie - Medium",
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  {
    id: 'FC-FCHOL21ZHD001-L',
    product: products.fightcampFullZipHoodie,
    qty: 1,
    price: products.fightcampFullZipHoodie.price,
    productName: "FightCamp Full Zip Hoodie - Large",
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: false,
  },
  // the works size variants for BizDev and POS
  {
    id: 'the_works_SS',
    product: products.fightcampTheWorks,
    qty: 1,
    price: products.fightcampTheWorks.price,
    productName: "FightCamp The Works - Small Quick Wraps & Small Gloves",
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: true,
    caShippingRestricted: true,
  },
  {
    id: 'the_works_LL',
    product: products.fightcampTheWorks,
    qty: 1,
    price: products.fightcampTheWorks.price,
    productName: "FightCamp The Works - Large Quick Wraps & Large Gloves",
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: true,
    caShippingRestricted: true,
  },
  {
    id: 'the_works_SL',
    product: products.fightcampTheWorks,
    qty: 1,
    price: products.fightcampTheWorks.price,
    productName: "FightCamp The Works - Small Quick Wraps & Large Gloves",
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: true,
    caShippingRestricted: true,
  },
  {
    id: 'the_works_LS',
    product: products.fightcampTheWorks,
    qty: 1,
    price: products.fightcampTheWorks.price,
    productName: "FightCamp The Works - Large Quick Wraps & Small Gloves",
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: true,
    caShippingRestricted: true,
  },

  {
    id: 'fightcamp_holiday_bundle_SSSS',
    product: products.fightcampHolidayBundle,
    qty: 1,
    price: products.fightcampHolidayBundle.price,
    productName: 'FightCamp All-in-One Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: true,
    caShippingRestricted: true,
    items: [
      {
        name: 'Trackers',
      },
      {
        name: '2 Small Quick Wraps',
      },
      {
        name: 'Boxing Bag',
      },
      {
        name: '2 Small Pair of Gloves',
      },
      {
        name: 'Bag Ring',
      },
      {
        name: 'Put It On TV Bundle',
      },
      {
        name: 'Premium Flooring',
      },
      {
        name: '36-Month Extended Warranty',
      },
    ],
  },
  {
    id: 'fightcamp_holiday_bundle_SSSL',
    product: products.fightcampHolidayBundle,
    qty: 1,
    price: products.fightcampHolidayBundle.price,
    productName: 'FightCamp All-in-One Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: true,
    caShippingRestricted: true,
    items: [
      {
        name: 'Trackers',
      },
      {
        name: '2 Small Quick Wraps',
      },
      {
        name: 'Boxing Bag',
      },
      {
        name: '1 Small and 1 Large Pair of Gloves',
      },
      {
        name: 'Bag Ring',
      },
      {
        name: 'Put It On TV Bundle',
      },
      {
        name: 'Premium Flooring',
      },
      {
        name: '36-Month Extended Warranty',
      },
    ],
  },
  {
    id: 'fightcamp_holiday_bundle_SSLL',
    product: products.fightcampHolidayBundle,
    qty: 1,
    price: products.fightcampHolidayBundle.price,
    productName: 'FightCamp All-in-One Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: true,
    caShippingRestricted: true,
     items: [
      {
        name: 'Trackers',
      },
      {
        name: '2 Small Quick Wraps',
      },
      {
        name: 'Boxing Bag',
      },
      {
        name: '2 Large Pair of Gloves',
      },
      {
        name: 'Bag Ring',
      },
      {
        name: 'Put It On TV Bundle',
      },
      {
        name: 'Premium Flooring',
      },
      {
        name: '36-Month Extended Warranty',
      },
    ],
  },
  {
    id: 'fightcamp_holiday_bundle_SLSS',
    product: products.fightcampHolidayBundle,
    qty: 1,
    price: products.fightcampHolidayBundle.price,
    productName: 'FightCamp All-in-One Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: true,
    caShippingRestricted: true,
     items: [
      {
        name: 'Trackers',
      },
      {
        name: '1 Small and 1 Large Quick Wraps',
      },
      {
        name: 'Boxing Bag',
      },
      {
        name: '2 Small Pair of Gloves',
      },
      {
        name: 'Bag Ring',
      },
      {
        name: 'Put It On TV Bundle',
      },
      {
        name: 'Premium Flooring',
      },
      {
        name: '36-Month Extended Warranty',
      },
    ],
  },
  {
    id: 'fightcamp_holiday_bundle_SLSL',
    product: products.fightcampHolidayBundle,
    qty: 1,
    price: products.fightcampHolidayBundle.price,
    productName: 'FightCamp All-in-One Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: true,
    caShippingRestricted: true,
     items: [
      {
        name: 'Trackers',
      },
      {
        name: '1 Small and 1 Large Quick Wraps',
      },
      {
        name: 'Boxing Bag',
      },
      {
        name: '1 Small and 1 Large Pair of Gloves',
      },
      {
        name: 'Bag Ring',
      },
      {
        name: 'Put It On TV Bundle',
      },
      {
        name: 'Premium Flooring',
      },
      {
        name: '36-Month Extended Warranty',
      },
    ],
  },
  {
    id: 'fightcamp_holiday_bundle_SLLL',
    product: products.fightcampHolidayBundle,
    qty: 1,
    price: products.fightcampHolidayBundle.price,
    productName: 'FightCamp All-in-One Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: true,
    caShippingRestricted: true,
     items: [
      {
        name: 'Trackers',
      },
      {
        name: '1 Small and 1 Large Quick Wraps',
      },
      {
        name: 'Boxing Bag',
      },
      {
        name: '2 Large Pair of Gloves',
      },
      {
        name: 'Bag Ring',
      },
      {
        name: 'Put It On TV Bundle',
      },
      {
        name: 'Premium Flooring',
      },
      {
        name: '36-Month Extended Warranty',
      },
    ],
  },
  {
    id: 'fightcamp_holiday_bundle_LLSS',
    product: products.fightcampHolidayBundle,
    qty: 1,
    price: products.fightcampHolidayBundle.price,
    productName: 'FightCamp All-in-One Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: true,
    caShippingRestricted: true,
     items: [
      {
        name: 'Trackers',
      },
      {
        name: '2 Large Quick Wraps',
      },
      {
        name: 'Boxing Bag',
      },
      {
        name: '2 Small Pair of Gloves',
      },
      {
        name: 'Bag Ring',
      },
      {
        name: 'Put It On TV Bundle',
      },
      {
        name: 'Premium Flooring',
      },
      {
        name: '36-Month Extended Warranty',
      },
    ],
  },
  {
    id: 'fightcamp_holiday_bundle_LLSL',
    product: products.fightcampHolidayBundle,
    qty: 1,
    price: products.fightcampHolidayBundle.price,
    productName: 'FightCamp All-in-One Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: true,
    caShippingRestricted: true,
     items: [
      {
        name: 'Trackers',
      },
      {
        name: '2 Large Quick Wraps',
      },
      {
        name: 'Boxing Bag',
      },
      {
        name: '1 Small and 1 Large Pair of Gloves',
      },
      {
        name: 'Bag Ring',
      },
      {
        name: 'Put It On TV Bundle',
      },
      {
        name: 'Premium Flooring',
      },
      {
        name: '36-Month Extended Warranty',
      },
    ],
  },
  {
    id: 'fightcamp_holiday_bundle_LLLL',
    product: products.fightcampHolidayBundle,
    qty: 1,
    price: products.fightcampHolidayBundle.price,
    productName: 'FightCamp All-in-One Bundle',
    pricingDetails: '',
    pricingDescription: '',
    subscriptionNeeded: true,
    caShippingRestricted: true,
     items: [
      {
        name: 'Trackers',
      },
      {
        name: '2 Large Quick Wraps',
      },
      {
        name: 'Boxing Bag',
      },
      {
        name: '2 Large Pair of Gloves',
      },
      {
        name: 'Bag Ring',
      },
      {
        name: 'Put It On TV Bundle',
      },
      {
        name: 'Premium Flooring',
      },
      {
        name: '36-Month Extended Warranty',
      },
    ],
  },
  {
    id: 'gq_fightcamp_trackers_S',
    product: products.gqFightcampTrackers,
    qty: 1,
    price: products.gqFightcampTrackers.price,
    productName: 'GQ X FightCamp Trackers, Small',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Small',
    },
    { name: '45 day trial'}
    ],
  },
  {
    id: 'gq_fightcamp_trackers_L',
    product: products.gqFightcampTrackers,
    qty: 1,
    price: products.gqFightcampTrackers.price,
    productName: 'GQ X FightCamp Trackers, Large',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Large',
    },
    { name: '45 day trial'}
    ],
  },
  // partner offer
  {
    id: 'pt_fightcamp_trackers_S',
    product: products.ptFightcampTrackers,
    qty: 1,
    price: products.ptFightcampTrackers.price,
    productName: '(Partner Promo) FightCamp Trackers, Small',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Small',
    },
    { name: '45 day trial'}
    ],
  },
  {
    id: 'pt_fightcamp_trackers_L',
    product: products.ptFightcampTrackers,
    qty: 1,
    price: products.ptFightcampTrackers.price,
    productName: '(Partner Promo) FightCamp Trackers, Large',
    pricingDetails: '',
    pricingDescription: '12-Month Limited Warranty @ $0.00',
    subscriptionNeeded: true,
    items: [{
      name: 'Punch Trackers',
    },
    {
      name: 'Quick Wraps - Large',
    },
    { name: '45 day trial'}
    ],
  },
]
