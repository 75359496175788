export default {
  title: 'Free Standing Bag',
  id: 'freeStandingBag_V3',
  price: 49900,
  monthly: 4158,
  months: 12,
  category: 'accessory',
  description: '',
  img_url: 'https://a.storyblok.com/f/152150/1000x1000/7da74119d4/bag_whitebackground.jpg',
  types: ['Setup']
};
