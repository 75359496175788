import connect from './fightcamp_connect';
import connectv2 from './fightcamp_connect_v2';
import fightcampTrackers from './fightcamp_trackers';
import fightcampTrackersBag from './fightcamp_trackers_with_bag';
import fightcampTrackers399 from './fightcamp_trackers_399';
import fightcampTrackersBag749 from './fightcamp_trackers_with_bag_749';
import fightcampTheWorks from './fightcamp_the_works';
import { fightcampHolidayBundle } from './fightcamp_holiday_bundle';
import gqFightcampTrackers from './fightcamp_gq_trackers';
import ptFightcampTrackers from './fightcamp_pt_trackers';

import fightcampConsole from './fightcamp_console';
import fightcampConsoleBag from './fightcamp_console_bag';
import fightcampConsoleTheWorks from './fightcamp_console_the_works';
import fightcampConsoleAllInOne from './fightcamp_console_all_in_one';

import personal from './fightcamp_personal';
import personalSnappy from './fightcamp_personal_snappy';
import gym from './fightcamp_gym';
import family from './fightcamp_family';
import tribe from './fightcamp_tribe';
import tribeSnappy from './fightcamp_tribe_snappy';
import bag from './fightcamp_bag';
import bagv2 from './fightcamp_bag_V2';
import bagv3 from './fightcamp_bag_V3';

import quickwraps from './fightcamp_quickwraps';
import quickwrapsv2 from './fightcamp_quickwraps_v2';
import quickwrapsv2sale from './fightcamp_quickwraps_v2_sale';
import quickwrapsdeal from './fightcamp_quickwraps_deal';
import quickwraps3pack from './fightcamp_quickwraps_3pack';
import quickwraps3packsale from './fightcamp_quickwraps_3pack_sale';
import traditionalwraps from './fightcamp_traditional_wraps';
import traditionalwrapsbpi from './fightcamp_traditional_wraps_bpi';
import gloves from './fightcamp_gloves';
import glovesv2 from './fightcamp_gloves_v2';
import glovesv2deal from './fightcamp_gloves_v2_deal';
import glovesv2sale from './fightcamp_gloves_v2_sale';
import kidsgloves from './fightcamp_kid_gloves';
import kidsglovesv2 from './fightcamp_kid_gloves_v2';
import kidsglovesv2sale from './fightcamp_kid_gloves_v2_sale';
import kidsglovesv2deal from './fightcamp_kid_gloves_v2_deal';
import rubbermat from './fightcamp_rubbermat';
import subscription from './fightcamp_subscription';
import { fightcampSubscriptionYearly } from './fightcamp_subscription_yearly';
import carekit from './fightcamp_care_kit';
import carekitdeal from './fightcamp_care_kit_deal';
import bagring from './fightcamp_bag_ring';
import bagringdeal from './fightcamp_bag_ring_deal';
import conditioningkitdeal from './fightcamp_conditioning_kit_deal';
import conditioningkit from './fightcamp_conditioning_kit';
import endurancekitdeal from './fightcamp_endurance_kit_deal';
import endurancekit from './fightcamp_endurance_kit';
import trackerSleeve from './fightcamp_tracker_sleeve';


import { fightcampHeartRateMonitor } from './fightcamp_heart_rate_monitor';
import { fightcampHeartRateMonitorDeal } from './fightcamp_heart_rate_monitor_deal';
import { fightcampHeartRateMonitorSale } from './fightcamp_heart_rate_monitor_sale';
import { fightcampWaterBottle } from './fightcamp_water_bottle';
import { fightcampWeightedQuickWraps } from './fightcamp_weighted_quick_wraps';
import { fightcampResistanceBands } from './fightcamp_resistance_bands';
import { fightcampResistanceBandsDeal } from './fightcamp_resistance_bands_deal';
import { fightcampJumpRope } from './fightcamp_jump_rope';
import { fightcampJumpRopeDeal } from './fightcamp_jump_rope_deal';
import { fightcampJumpRopeSale } from './fightcamp_jump_rope_sale';
import { fightcampAgilityLadder } from './fightcamp_agility_ladder';
import { fightcampYogaMat } from './fightcamp_yoga_mat';
import { fightcampExerciseMatDeal } from './fightcamp_exercise_mat_deal';
import { fightcampLaundryBag } from './fightcamp_laundry_bag';
import { fightcampGloveFreshener } from './fightcamp_glove_freshener';
import { fightcampGloveBag } from './fightcamp_glove_bag';
import { fightcampPremiumFlooring } from './fightcamp_premium_flooring';
import { fightcampPremiumFlooringSale } from './fightcamp_premium_flooring_sale';
import { fightcampHdmiCable } from './fightcamp_hdmi_cable';
import { fightcampShiAppleCable } from './fightcamp_shi_apple_cable';
import { fightcampBagRingBpi } from './fightcamp_bag_ring_bpi';


import { bundleBetterEveryDay } from './fightcamp_bundle_better_every_day';
import { bundleTrainFighter } from './fightcamp_bundle_train_fighter';
import { bundleKeepFresh } from './fightcamp_bundle_keep_fresh';
import { bundleProtectFloor } from './fightcamp_bundle_protect_floor';
import { bundleTv } from './fightcamp_bundle_tv';
import { bundleUltimate } from './fightcamp_bundle_ultimate';
import { bundleFamily } from './fightcamp_bundle_family';
import { fightcamp2023HolidayBundleUltimate } from './fightcamp_2023_holiday_bundle_ultimate';
import { transferredPlan } from './fightcamp_transferredPlan';
import { bundleBetterEveryDaySale } from './fightcamp_bundle_better_every_day_sale';
import { bundleTrainFighterSale } from './fightcamp_bundle_train_fighter_sale';
import { bundleKeepFreshSale } from './fightcamp_bundle_keep_fresh_sale';
import { bundleProtectFloorSale } from './fightcamp_bundle_protect_floor_sale';

import fightcampTrackersBagExtWarranty79 from './fightcamp_trackers_bag_ext_warranty_79';
import fightcampTrackersExtWarranty19 from './fightcamp_trackers_ext_warranty_19';


import fightcampLogoHoodieCharcoalHeather from './fightcamp_logo_hoodie_charcoal_heather';
import fightcampLogoHoodieOatmeal from './fightcamp_logo_hoodie_oatmeal';
import fightcampLogoTeeBlack from './fightcamp_logo_tee_black';
import fightcampTraditionalWrapsBlkwht from './fightcamp_traditional_wraps_blkwht';
import fightcampSweatTowel from './fightcamp_sweat_towel';
import fightcampMensTankBlack from './fightcamp_mens_tank_black';
import fightcampWomensTankBlack from './fightcamp_womens_tank_black';
import fightcampFullZipHoodie from './fightcamp_full_zip_hoodie';

export default {
  connect,
  connectv2,
  fightcampTrackers,
  fightcampTrackersBag,
  fightcampTrackers399,
  fightcampTrackersBag749,
  fightcampTheWorks,
  gqFightcampTrackers,
  ptFightcampTrackers,
  fightcampTrackersExtWarranty19,
  fightcampTrackersBagExtWarranty79,
  gym,
  personal,
  personalSnappy,
  family,
  tribe,
  tribeSnappy,
  fightcampHolidayBundle,
  bag,
  bagv2,
  bagv3,
  quickwraps,
  quickwrapsv2,
  quickwrapsdeal,
  quickwrapsv2sale,
  quickwraps3pack,
  quickwraps3packsale,
  traditionalwraps,
  traditionalwrapsbpi,
  gloves,
  glovesv2,
  glovesv2deal,
  glovesv2sale,
  kidsgloves,
  kidsglovesv2,
  kidsglovesv2sale,
  kidsglovesv2deal,
  rubbermat,
  subscription,
  fightcampSubscriptionYearly,
  trackerSleeve,
  carekit,
  carekitdeal,
  bagring,
  bagringdeal,
  conditioningkit,
  conditioningkitdeal,
  endurancekitdeal,
  endurancekit,
  fightcampBagRingBpi,
  fightcampAgilityLadder,
  fightcampExerciseMatDeal,
  fightcampFullZipHoodie,
  fightcampGloveBag,
  fightcampGloveFreshener,
  fightcampHdmiCable,
  fightcampHeartRateMonitor,
  fightcampHeartRateMonitorDeal,
  fightcampHeartRateMonitorSale,
  fightcampJumpRope,
  fightcampJumpRopeDeal,
  fightcampJumpRopeSale,
  fightcampLaundryBag,
  fightcampLogoHoodieCharcoalHeather,
  fightcampLogoHoodieOatmeal,
  fightcampLogoTeeBlack,
  fightcampMensTankBlack,
  fightcampPremiumFlooring,
  fightcampPremiumFlooringSale,
  fightcampResistanceBands,
  fightcampResistanceBandsDeal,
  fightcampShiAppleCable,
  fightcampSweatTowel,
  fightcampTraditionalWrapsBlkwht,
  fightcampWaterBottle,
  fightcampWeightedQuickWraps,
  fightcampWomensTankBlack,
  fightcampYogaMat,
  bundleBetterEveryDay,
  bundleTrainFighter,
  bundleKeepFresh,
  bundleProtectFloor,
  bundleTv,
  bundleUltimate,
  bundleFamily,
  fightcamp2023HolidayBundleUltimate, // two ??
  transferredPlan,
  bundleBetterEveryDaySale,
  bundleTrainFighterSale,
  bundleKeepFreshSale,
  bundleProtectFloorSale,
  fightcampConsole,
  fightcampConsoleBag,
  fightcampConsoleTheWorks,
  fightcampConsoleAllInOne,
};
