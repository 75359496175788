const defaultQuestionOther = 'Enter your answer';

const otherOption = (optionObj) => ({
  id: optionObj.id,
  label: 'Other',
  attribution: optionObj.attribution,
  followup: optionObj.followup ? optionObj.followup : defaultFollowup(optionObj),
});

const defaultFollowup = (optionObj) => ({
  id: optionObj.id,
  question: optionObj.question || defaultQuestionOther,
  type: 'input',
  attribution: optionObj.attribution
})

module.exports = otherOption;
