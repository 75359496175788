import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import products from '../datasets/variants';
import EventBus from '../scripts/eventbus';
import { cartSynced } from '../scripts/events';
import apiClient from '../scripts/api_client';
import { Actions } from './actionsTypes';
import { EMAIL_VALIDATOR, PHONE_NUMBER_VALIDATOR, US_POSTAL_CODE_VALIDATOR, CA_POSTAL_CODE_VALIDATOR } from '../utils/regex';
import { couponsByUrl } from '../datasets/coupons';
import { MembershipIds } from '@/shared/constants/MembershipIds';
import { DELIVERY_AND_INSTALLATION, STANDARD_SHIPPING_49, STANDARD_SHIPPING_9 } from '../datasets/shipping';


Vue.use(Vuex);

function getCartObj(state, getters) {
  if (getters.isShopifyOn && !getters.isGQOrder) {
    return getters.cartProducts.shopifyItems;
  }
  return state.cart
    .map(item => products.find(product => product.id === item.id))
    .map(variant => ({ ...variant, price: parseFloat(variant.price / 100.0).toFixed(2) }));
}

const shippingIds = {
  [STANDARD_SHIPPING_49]: STANDARD_SHIPPING_49,
  [STANDARD_SHIPPING_9]: STANDARD_SHIPPING_9,
  [DELIVERY_AND_INSTALLATION]: DELIVERY_AND_INSTALLATION,
};

const gqIds = ['gq_fightcamp_trackers_S', 'gq_fightcamp_trackers_L', 'pt_fightcamp_trackers_S', 'pt_fightcamp_trackers_L'];

const foundShippingItems = cart => (cart || [])
  .filter(({ id }) => id in shippingIds)
  .map(({ id }) => id)

export default {

  /**
   * syncCart
   * @param {*} param
   */
  async syncCart({ commit, state, dispatch, getters }, queryItems) {
    if (getters.isShopifyOn && !getters.isGQOrder) {
      await dispatch('syncCartV2', queryItems);
      return;
    }

    if (state.initStarted) {
      return;
    }
    commit('setInitStarted');

    try {
      const res = await axios.get('/api/cart', {
        withCredentials: true,
      });

      let cart = res.data.items || [];
      const email = res.data.email;
      const shipping = res.data.shippingInfo;

      if (email) {
        commit('IDENTIFY_BY_EMAIL', email);
      }

      if (shipping) {
        commit('SET_SHIPPING_INFO', shipping);
      }

      if (queryItems) {
        queryItems.forEach((item) => {
          commit('insertItemToCart', item);
        });
        commit('setCart', state.cart);
        await dispatch('updateCart');
      } else if (res.data.version === state.CURRENT_VERSION) {
        if (res.data.coupon) {
          commit('updateCoupon', res.data.coupon);
        }

        // Clean the cart to get the promotion automatically
        const isPackageInCart = !!cart.find((item) => {
          const desc = products.find(product => item.id === product.id);
          return desc.subscriptionNeeded;
        });
        const isCareKitDealInCart = !!cart.find(item => item.id === 'fightcamp_care_kit_deal');

        if (isPackageInCart && isCareKitDealInCart) {
          // Remove any care kit deal that might have been set in the previous deal
          cart = cart.filter(item => item.id !== 'fightcamp_care_kit_deal');
          commit('setCart', cart);
          await dispatch('updateCart');
        } else {
          commit('setCart', cart);
        }
      } else {
        commit('setCart', []);
      }
      await dispatch('setShippingMethod', '');
    } catch (err) {
      commit('setCart', []);
      console.error(err);
    }

    dispatch('trackCartSync');
  },

  async updateCart({ state, dispatch }) {
    dispatch('trackCartSync');
    try {
      return await axios.post('/api/cart', {
        version: state.CURRENT_VERSION,
        email: state.email,
        shippingInfo: state.shippingInfo,
        coupon: state.cartCoupon,
        items: state.cart,
      }, {
        withCredentials: true,
      });
    } catch (err) {
      console.error(err);
    }
  },
  trackCartSync({ state, getters }) {
    EventBus.$emit('trackCartSync', {
      email: state.email,
      shipping: state.shippingInfo || state.shipping,
      cart: getCartObj(state, getters),
      coupon: state.cartCoupon,
    });
    cartSynced.emit({
      email: state.email,
      shipping: state.shippingInfo || state.shipping,
      cart: getCartObj(state, getters),
      coupon: state.cartCoupon,
    });
  },

  async insertItemToCart({ commit, dispatch, getters, state }, opts) {
    const isGQItem = gqIds.includes(opts.id)

    if (getters.isShopifyOn && !getters.isGQOrder && !isGQItem) {
      return dispatch('insertItemToCartV2', opts);
    } else {
      try {
        let { delay, ...item } = opts || {}
        if (delay) {
          commit('SET_PROCESSING_ADD_TO_CART', true)
          await new Promise((resolve) => {
            setTimeout(() => {
              commit('SET_PROCESSING_ADD_TO_CART', false)
              resolve()
            }, 500);
          })
        }
        const secondhandInCart = state.cart.find(i => i.id === MembershipIds.TRANSFERRED);
        const productObj = products.find(product => item.id === product.id);
        const packgeToAdd = productObj.subscriptionNeeded === true;

        if (secondhandInCart && packgeToAdd) {
          await dispatch('removeItemFromCart', secondhandInCart);
        }

        const secondhandToAdd = item.id === MembershipIds.TRANSFERRED;
        const isPackageInCart = getters.packageInCart;
        const isGQPackageInCart = getters.isGQOrder;

        if (isGQPackageInCart && (secondhandToAdd || packgeToAdd)) {
          await dispatch('clearCart');
        } else if (secondhandToAdd && isPackageInCart || isPackageInCart && packgeToAdd) {
          await dispatch('removeItemFromCart', isPackageInCart);
        }

        commit('insertItemToCart', item);
        dispatch(Actions.TRACK_PRODUCT_ADDED, item.id);
        return await dispatch('updateCart');
      } catch (err) {
        console.error(err);
      }
    }
  },

  async updateShippingItem({ commit, dispatch, getters }) {
    try {
      return await Promise.allSettled(foundShippingItems(getters.cart).map(async (id) => {
        await dispatch('removeItemFromCart', { id });
      })).then(async () => {
        const hasShipping = foundShippingItems(getters.cart).length > 0
        if (hasShipping) return Promise.resolve(commit('updateOrder', null));

        if (getters.hasPackageWithBag || getters.isFreeStandingBag) {
          return await dispatch('insertItemToCart', {
            id: STANDARD_SHIPPING_49,
            type: 'shipping',
          });
        } else if (getters.hasSecondhandSubOnly) {
          commit('updateOrder', null);
        } else if (getters.cartqty > 0) {
          return await dispatch('insertItemToCart', {
            id: STANDARD_SHIPPING_9,
            type: 'shipping',
          });
        } else {
          return Promise.resolve(commit('updateOrder', null));
        }
      })

    } catch (err) {
      console.error(err);
    }
  },

  async removeItemFromCart({ commit, dispatch, getters }, opts) {
    const isGQItem = gqIds.includes(opts.id)

    if (getters.isShopifyOn && !getters.isGQOrder && !isGQItem) {
      return dispatch('removeItemFromCartV2', opts);
    } else {
      try {
        const { delay, ...item } = opts || {}

        if (delay) {
          commit('SET_PROCESSING_ADD_TO_CART', true)
          await new Promise((resolve) => {
            setTimeout(() => {
              commit('SET_PROCESSING_ADD_TO_CART', false)
              resolve()
            }, 500);
          })
        }

        commit('removeItemFromCart', item);
        dispatch(Actions.TRACK_PRODUCT_REMOVED, item.id);
        return await dispatch('updateCart');
      } catch (err) {
        console.error(err);
      }
    }
  },

  async clearCart({ commit, dispatch }, item) {
    try {
      commit('clearCart');
      return await dispatch('updateCart');
    } catch (err) {
      console.error(err);
    }
  },

  async clearCoupon({ commit, dispatch, state }) {
    try {
      commit('updateCoupon', null);
      commit('updateAmountOff', null);
      commit('updatePercentageOff', null);
      commit('updateUrlCouponMessage', null);

      return await dispatch('updateCart');
    } catch (err) {
      console.error(err);
    }
  },
  [Actions.CHECK_COUPON_VALID]: async function ({ state }, coupon) {
    console.log('store.js - action - checkCouponValid');
    const email = state.contactEmail || state.checkoutEmail;
    try {
      return await apiClient.validateCoupon(coupon, state.cart, email);
    } catch (err) {
      console.error(err);
    }
  },
  async updateCoupon({ dispatch, getters }, coupon) {
    console.log('store.js - action - updateCoupon');
    let data;
    console.log('store.js - action - updateCoupon', getters.isShopifyOn, coupon)
    if (!getters.isShopifyOn || getters.isGQOrder) { // do this for sub coupons too
      data = await dispatch('validateCoupon', coupon);
    }
    await dispatch('updateCart');
    return data;
  },
  async validateCoupon({ commit, dispatch, getters }, coupon) {
    console.log('store.js - action - validateCoupon');
    let data = {};
    const isCheckout = getters.currentRoute && getters.currentRoute.path === '/shop/checkout';

    try {
      commit('setProcessingTotalCost', true);
      data = await dispatch('checkCouponValid', coupon);
      if (data.couponValid && couponsByUrl.includes(coupon)) {
        commit('updateAmountOff', null);
        commit('updatePercentageOff', null);

        commit('updateCoupon', coupon);
        commit('updateUrlCouponMessage', `Promo applied.`);
        if (!isCheckout) commit('openDrawer', true);
      } else if (data.couponValid) {
        commit('updateAmountOff', data.amountOff);
        commit('updatePercentageOff', data.percentageOff);

        commit('updateCoupon', coupon);
        commit('updateCouponError', '');
      } else {
        commit('updateAmountOff', null);
        commit('updatePercentageOff', null);

        const errorMessage = data.error || 'Promo code not valid'
        commit('updateCouponError', errorMessage);
        if (!isCheckout) commit('openDrawer', true);
      }
    } catch (err) {
      console.error(err);
    }
    commit('setProcessingTotalCost', false);
    return data;
  },
  setPaymentMethod({ commit, getters, state }, paymentMethod) {
    if (!getters.isSubsInCart) {
      return;
    }

    if (!paymentMethod || state.paymentMethod === paymentMethod) {
      return;
    }

    commit('SET_PAYMENT_METHOD', paymentMethod);
  },
  async setMembershipType({ commit, getters, state, dispatch }, membership) {
    if (!getters.isSubsInCart) {
      return;
    }

    if (getters.isShopifyOn) {
      // remove present sub in cart
      const subscription = getters.cart.find(item => item.type === "subs_only");
      if (subscription) {
        await dispatch('removeItemFromCartV2', subscription)
      }

      await dispatch('insertItemToCartV2', {
        id: membership.variants[0].id,
        type: 'subs_only',
        disableDrawer: true,
        delay: true,
      });
    } else {
      commit('SET_MEMBERSHIP_TYPE', membership);
      const email = getters.shipping.email || state.checkoutEmail;
  
      // There's 2 sources of shipping - newStore.js and checkout getters. We will refactor to 1 after payment gateway V2
      let shippingInfo = !!state.shipping.firstName ? state.shipping : getters.shipping;
  
      const totalCost = await apiClient.calculateTotalCost(shippingInfo, email, getters.cart, getters.cartCoupon, membership.type)
      commit('updateOrder', totalCost);
    }

  },
  resetMembershipType({ commit, getters }, membership) {
    if (!getters.isSubsInCart) {
      return;
    }

    commit('SET_MEMBERSHIP_TYPE', membership);
  },
  async createOrder({
    commit,
    dispatch,
    getters,
    state,
  }, shipping) {
    console.log('store.js - action - createOrder');
    let orderObject = {};
    try {
      commit('setProcessingTotalCost', true);
      const membershipType = state.membershipType;

      if (getters.cartCoupon) {
        const email = state.contactEmail || state.checkoutEmail;
        const dataCoupon = await apiClient.validateCoupon(getters.cartCoupon, state.cart, email);
        if (dataCoupon.couponValid) {
          commit('updateAmountOff', dataCoupon.amountOff);
          commit('updatePercentageOff', dataCoupon.percentageOff);

          commit('updateCoupon', getters.cartCoupon);
          commit('updateCouponError', '');
        } else {
          commit('updateAmountOff', null);
          commit('updatePercentageOff', null);

          commit('updateCouponError', dataCoupon.error);
          commit('openDrawer', true);
          throw new Error(dataCoupon.error);
        }
      }

      const taxObject = await apiClient.getTaxesForOrder(shipping, membershipType);

      if (taxObject) {
        commit('updateMembershipTaxes', taxObject.data.tax.amount_to_collect);
      } else {
        commit('updateMembershipTaxes', null);
      }

      const isShippingVariant = getters.exp_025_isCaShippingVariant;

      orderObject = await apiClient.calculateTotalCost(shipping, shipping.email, getters.cart, getters.cartCoupon, membershipType.type, isShippingVariant)
        .catch((err) => {
          // TODO fix shippingState, that's not always the problem
          if (err.message !== 'Something went wrong, missing parameters') {
            commit('updateshippingError', { message: err.message, element: 'shippingState' });
          }
        });
      if (orderObject) {
        commit('updateOrder', orderObject);
        commit('updateShippingAddress', shipping);
        if (!orderObject.eligibleDeliveright) {
          await dispatch('setShippingMethod', 'freeDelivery');
        }
        const result = await axios.post(`${process.env.VUE_APP_API_URL}/customercheck`, {
          email: shipping.email,
        }).catch(console.error);
        if (result) {
          commit('setNewCustomer', result.data.new_customer);
        }
      }
    } catch (err) {
      console.error(err);
    }
    commit('setProcessingTotalCost', false);
    return orderObject;
  },


  async validateShipping({ state, commit, getters }) {
    const isPostalCodeValid = US_POSTAL_CODE_VALIDATOR.test(state.shippingPostalCode) || CA_POSTAL_CODE_VALIDATOR.test(state.shippingPostalCode.toString().replace(/\W+/g, ''));

    commit('updateShippingValid', {
      firstName: state.shippingFirstName !== '',
      lastName: state.shippingLastName !== '',
      address: state.shippingAddress1 !== '',
      city: state.shippingCity !== '',
      state: state.shippingState !== '' && !getters.shippingRestricted,
      postalCode: state.shippingPostalCode !== '' && isPostalCodeValid,
      country: state.shippingCountry !== '',
    });


    const errorid = getters.checkFieldsNotFilled;
    if (getters.shippingRestricted) {
      commit('updateshippingError', { message: 'Unfortunately, items in your cart cannot be shipped to Alaska or Hawaii. Only the FightCamp Connect and FightCamp Accessories are eligible.', element: 'shippingState' });
      return 'shippingState';
    }
    if (errorid) {
      commit('updateshippingError', { message: 'Some fields are required.', element: errorid });
      return errorid;
    }
    if (!isPostalCodeValid) {
      commit('updateshippingError', { message: 'Please check your zip code.', element: 'shippingPostalCode' });
      return 'shippingPostalCode';
    }

    const supportedCanadaProvinces = new Set(['ON', 'AB', 'BC'])
    if (state.shippingCountry === 'CA' && !supportedCanadaProvinces.has(state.shippingState)) {
      commit('updateshippingError', { message: 'Unfortunately we do not ship to this province at this time.', element: 'shippingState' });
      return 'shippingState';
    }

    // if not in ON range for large bag items throw error
    const pattern = /^[KLMN]/;
    const isValidZip = pattern.test(state.shippingPostalCode.toUpperCase());
    if (state.shippingCountry === 'CA' && (state.shippingState !== 'ON' || !isValidZip) && getters.hasPartialCanadaRestrictedItemsInCart) {
      commit('updateshippingError', { message: 'Unfortunately, items in your cart cannot be shipped to Canada. Only the FightCamp Trackers and some FightCamp Accessories are eligible.', element: 'shippingState' });
      return 'shippingState';
    }

    this.addressValidated = true;
    commit('clearshippingError');
    return '';
  },

  async validateContact({ state, commit }) {
    const emailValid = EMAIL_VALIDATOR.test(state.contactEmail);
    const phoneValid = PHONE_NUMBER_VALIDATOR.test(String(state.contactPhoneNumber).toLowerCase());

    commit('updateContactValid', {
      email: state.contactEmail !== '' && emailValid,
      phoneNumber: state.contactPhoneNumber !== '' && phoneValid,
    });

    if (state.contactEmail === '') {
      commit('updatecontactError', { message: 'Email required', element: 'contactEmail' });
      return 'contactEmail';
    }
    if (state.contactPhoneNumber === '') {
      commit('updatecontactError', { message: 'Phone Number required', element: 'contactPhoneNumber' });
      return 'contactPhoneNumber';
    }
    if (!EMAIL_VALIDATOR.test(state.contactEmail)) {
      commit('updatecontactError', { message: 'Email format invalid.', element: 'contactEmail' });
      return 'contactEmail';
    }
    if (!PHONE_NUMBER_VALIDATOR.test(String(state.contactPhoneNumber).toLowerCase())) {
      commit('updatecontactError', { message: 'Phone Number format invalid', element: 'contactPhoneNumber' });
      return 'contactPhoneNumber';
    }
    commit('clearcontactError');
    return '';
  },

  async validateShippingMethod({ state, commit, getters }) {
    if (state.shippingMethod === '' && !getters.isShopifyOn) {
      commit('updateShippingMethodError', { message: 'Please select a shipping method', element: '' });
      return 'Checkout_ShippingMethods';
    }
    commit('clearShippingMethodError');

    return '';
  },


  async setShippingMethod({ commit }, method) {
    commit('updateShippingMethod', method);
  },

  async identifyByEmail({ commit, dispatch }, email) {
    commit('IDENTIFY_BY_EMAIL', email);
    return await dispatch('updateCart');
  },

  async updateShippingInfo({ commit, dispatch }, shippingInfo) {
    commit('SET_SHIPPING_INFO', shippingInfo);
    return await dispatch('updateCart');
  },
  [Actions.OPEN_MODAL]: function (_, { component, config = [] }) {
    this._vm.$modal.show(component, ...config);
  },
  [Actions.TRACK_PRODUCT_ADDED]: function (_, productId) {
    const { $ecommAnalytics, $segmentAnalytics } = this;
    if (!$ecommAnalytics || !$segmentAnalytics) return;

    const fullVariant = products.find(v => v.id === productId);
    this.$ecommAnalytics.trackAddToCart([fullVariant]);
    this.$segmentAnalytics.productAdded(fullVariant);
  },
  [Actions.TRACK_PRODUCT_REMOVED]: function (_, productId) {
    const { $ecommAnalytics, $segmentAnalytics } = this;
    if (!$ecommAnalytics || !$segmentAnalytics) return;

    const fullVariant = products.find(v => v.id === productId);
    this.$ecommAnalytics.trackRemoveFromCart([fullVariant]);
    this.$segmentAnalytics.productRemoved(fullVariant);
  },
};
