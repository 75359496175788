<template>
  <div
    v-if="active"
    :data-testid="testId"
    :class="containerClasses"
    class="spinner fc-flex-col-center fc-padding-4">
    <p class="spinner-text">
      {{ message }}
    </p>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="66.5"
      height="60.155"
      viewBox="0 0 66.5 60.155">
      <defs>
        <clipPath id="clip-path">
          <rect
            id="trackersContainer"
            width="66.5"
            height="60.155"
            fill="#fff" />
        </clipPath>
      </defs>
      <g id="trackers">
        <path
          id="Path_368"
          d="M15.212,0C7.869,0,0,5.209,0,8.867V56.608a3.491,3.491,0,0,0,3.464,3.547h23.5a3.491,3.491,0,0,0,3.519-3.547V7.98C30.479,4.683,22.555,0,15.212,0Zm12.5,56.608a.831.831,0,0,1-.8.887H3.464a.831.831,0,0,1-.693-.887V8.867c0-1.469,5.68-6.1,12.441-6.1s12.33,3.935,12.5,5.209Z"
          transform="translate(0)"
          fill="#0f1e3c" />
        <path
          id="Path_369"
          d="M11.3,8.084a1.358,1.358,0,0,0-1.884.249,1.33,1.33,0,0,0,.3,1.856l3.6,2.771V21.8C5.01,27.064,5.01,27.341,5.01,28.089V36.4a1.33,1.33,0,0,0,1.33,1.33,1.358,1.358,0,0,0,1.413-1.358V28.643c1.136-.831,4.572-3.076,7.7-5.015a1.357,1.357,0,0,0,.61-1.136V12.212a1.3,1.3,0,0,0-.61-1.108Z"
          transform="translate(8.872 13.834)"
          fill="#0f1e3c" />
        <path
          id="Path_370"
          d="M4.789,30.847V21.62C13.1,15.857,13.1,15.69,13.1,14.859V4.053a1.358,1.358,0,0,0-1.33-1.33,1.33,1.33,0,0,0-1.441,1.33V14.277c-1.108.887-4.6,3.38-7.758,5.542a1.358,1.358,0,0,0-.582,1.108V31.512a1.385,1.385,0,0,0,.554,1.081L6.7,35.64a1.441,1.441,0,0,0,.8.249,1.33,1.33,0,0,0,.776-2.411Z"
          transform="translate(3.524 4.814)"
          fill="#0f1e3c" />
        <path
          id="Path_371"
          class="pulse pulse-blue"
          d="M4,13.859a4.1,4.1,0,1,0,.307-1.584A4.1,4.1,0,0,0,4,13.859Zm5.542,0a1.441,1.441,0,1,1-.1-.56A1.441,1.441,0,0,1,9.542,13.859Z"
          transform="translate(7.083 17.23)"
          fill="#0f1e3c" />
        <path
          id="Path_372"
          d="M28.3,0C20.925,0,13,5.209,13,8.867V56.608a3.491,3.491,0,0,0,3.464,3.547h23.5a3.491,3.491,0,0,0,3.519-3.547V7.98C43.479,4.683,35.61,0,28.3,0ZM40.709,56.608a.831.831,0,0,1-.8.887H16.519a.832.832,0,0,1-.8-.887V8.867c.055-1.469,5.791-6.1,12.58-6.1S40.709,6.705,40.709,7.98Z"
          transform="translate(23.021)"
          fill="#0f1e3c" />
        <path
          id="Path_373"
          d="M24.3,8.075a1.338,1.338,0,1,0-1.579,2.161l3.63,2.771v8.839c-8.312,5.265-8.312,5.542-8.312,6.29v8.312a1.358,1.358,0,0,0,1.33,1.33,1.33,1.33,0,0,0,1.33-1.33V28.634c1.136-.831,4.6-3.076,7.7-5.015a1.385,1.385,0,0,0,.637-1.136V12.2a1.33,1.33,0,0,0-.582-1.108Z"
          transform="translate(31.946 13.843)"
          fill="#0f1e3c" />
        <path
          id="Path_374"
          d="M17.771,30.846V21.619c8.313-5.763,8.313-5.93,8.313-6.761V4.052a1.33,1.33,0,0,0-1.33-1.33,1.358,1.358,0,0,0-1.441,1.33V14.276c-1.108.887-4.572,3.38-7.758,5.542A1.331,1.331,0,0,0,15,20.926V31.511a1.358,1.358,0,0,0,.526,1.081l4.184,3.048a1.3,1.3,0,0,0,.776.249,1.414,1.414,0,0,0,1.081-.526,1.357,1.357,0,0,0-.3-1.884Z"
          transform="translate(26.562 4.815)"
          fill="#0f1e3c" />
        <path
          id="Path_375"
          class="pulse pulse-red"
          d="M17,13.859a4.1,4.1,0,1,0,.3-1.578A4.1,4.1,0,0,0,17,13.859Zm5.542,0a1.442,1.442,0,1,1-.1-.56A1.44,1.44,0,0,1,22.542,13.859Z"
          transform="translate(30.104 17.23)"
          fill="#d73c4b" />
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    watch,
  } from '@vue/composition-api';
  import { SpinnerBindings, SpinnerProps } from './types';

  export const DATA_TEST_ID = 'testid--spinner';

  export default defineComponent<SpinnerProps, SpinnerBindings>({
    name: 'Spinner',
    props: {
      active: {
        type: Boolean,
        default: false,
      },
      message: {
        type: String,
        default: 'Processing...',
      },
      withOverlay: {
        type: Boolean,
        default: true,
      },
      fullScreen: {
        type: Boolean,
        default: true,
      },
    },
    setup(props) {
      const handleActiveUpdate = (active: any) => {
        if (active) {
          document.documentElement.style.overflow = 'hidden';
          document.body.style.overflow = 'hidden';
          document.body.style.height = '100vh';
          document.body.style.width = '100vw';
          document.body.style.top = `${window.pageYOffset}px`;
        } else {
          document.documentElement.style.overflow = '';
          document.body.style.overflow = '';
          document.body.style.height = '';
          document.body.style.width = '';
          document.body.style.top = '';
        }
      };

      watch(() => props.active, handleActiveUpdate);
      onMounted(() => handleActiveUpdate(props.active));
      onUnmounted(handleActiveUpdate);

      return {
        testId: DATA_TEST_ID,
        containerClasses: computed(() => ({
          'has-overlay': props.withOverlay,
          'is-full-screen': props.fullScreen,
        })),
      };
    },
  });
</script>

<style scoped lang="scss">
  @import "../../styles/exports";

  .spinner {
    width: 100%;
    overflow: hidden;
    transition: background-color 0.3s ease;
    background: rgba(255, 255, 255, 0.25);

    &.is-full-screen {
      position: absolute;
      z-index: 1000;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    &.has-overlay {
      background: rgba(255, 255, 255, 0.866);
    }

    &-text {
      font-size: get-font-size-level(8);
      font-family: $font-stack-regular;
      margin: get-spacing-level(3);
    }

    &.has-overlay &-text {
      color: $color-black;
    }

    @keyframes pulse {
      0% {
        stroke-width: 2;
        stroke: var(--startColor);
      }

      100% {
        stroke-width: 25;
        stroke: var(--endColor);
      }
    }

    .pulse {
      animation: pulse 2.5s infinite;
      transform-origin: center center;
      opacity: 1;

      &-red {
        --startColor: #{$color-red};
        --endColor: rgba(#{$color-red--r}, #{$color-red--g}, #{$color-red--b}, 0);
      }

      &-blue {
        --startColor: #{$color-blue};
        --endColor: rgba(#{$color-blue--r}, #{$color-blue--g}, #{$color-blue--b}, 0);
      }
    }
  }
</style>
