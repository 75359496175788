export default {
  title: 'Traditional Hand Wraps - Black/White',
  id: 'fightcamp_traditional_wraps_blkwht',
  price: 1500,
  category: 'accessory',
  description: 'Experience the ultimate support and protection with our Traditional Handwraps. 180" in length, these wraps feature a secure thumb loop and FightCamp logo patch with hook and loop closure, all crafted from a durable blend of 65% Nylon and 35% Cotton. Perfectly suited for heavy bag workouts, speed bag sessions, and sparring, these machine washable wraps come as a set of 2.',
  img_url: 'https://a.storyblok.com/f/152150/600x600/86ed26dffa/fc-hand-wraps-blk-white-001.jpg',
  marketplace_img_url: '',
  fbid: '',
  types: ['Essentials']
};