import { productFields, pageInfoFields, checkoutFields } from './fragments';

export const applyCouponQuery = `
  mutation checkoutDiscountCodeApplyV2($discountCode: String!, $checkoutId: ID!) {
    checkoutDiscountCodeApplyV2(discountCode: $discountCode, checkoutId: $checkoutId) {
      checkout {
        ...checkoutFields
      }
      checkoutUserErrors {
        field
        message
      }
    }
  }
  ${checkoutFields}
`;

export const removeCouponQuery = `
  mutation checkoutDiscountCodeRemove($checkoutId: ID!) {
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      checkout {
        ...checkoutFields
      }
      checkoutUserErrors {
        field
        message
      }
    }
  }
  ${checkoutFields}
`;

export const collectionQuery = `
  query getCollectionById($id: ID!, $numProducts: Int!, $cursor: String) {
      collection(id: $id) {
      products(first: $numProducts, after: $cursor) {
        edges {
          node {
            ...productFields
          }
        }
        pageInfo {
          ...pageInfoFields
        }
      }
    }
  }
  ${productFields}
  ${pageInfoFields}
`;

export const productsQuery = `
  query getProducts($numProducts: Int!, $cursor: String) {
    products(first: $numProducts, after: $cursor) {
      edges {
        node {
          ...productFields
        }
      }
      pageInfo {
       ...pageInfoFields
      }
    }
  }
  ${productFields}
  ${pageInfoFields}
`;

export const checkoutCreateMutation = `
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        ...checkoutFields
      }
      checkoutUserErrors {
        field
        message
        code
      }
    }
  }
  ${checkoutFields}
`;

export const checkoutQuery = `
query getCheckout($id: ID!) {
  node(id: $id) {
    ... on Checkout {
      ...checkoutFields
      discountApplications(first: 10) {
        edges {
          node {
            ... on DiscountCodeApplication {
              allocationMethod
              applicable
              targetType
              targetSelection
              code
              value {
                ... on MoneyV2 {
                  amount
                  currencyCode
                }
                ... on PricingPercentageValue {
                  percentage
                }
              }
            }
          }
        }
      }
    }
  }
}
${checkoutFields}
`;

export const checkoutShippingAddressUpdateMutation = `
  mutation checkoutShippingAddressUpdateV2($shippingAddress: MailingAddressInput!, $checkoutId: ID!) {
    checkoutShippingAddressUpdateV2(shippingAddress: $shippingAddress, checkoutId: $checkoutId) {
      checkout {
        ...checkoutFields
        discountApplications(first: 10) {
          edges {
            node {
              ... on DiscountCodeApplication {
                allocationMethod
                applicable
                targetType
                targetSelection
                code
                value {
                  ... on MoneyV2 {
                    amount
                    currencyCode
                  }
                  ... on PricingPercentageValue {
                    percentage
                  }
                }
              }
            }
          }
        }
      }
      checkoutUserErrors {
        field
        message
        code
      }
    }
  }
  ${checkoutFields}
`;

export const checkoutShippingLineUpdateMutation = `
mutation checkoutShippingLineUpdate($checkoutId: ID!, $shippingRateHandle: String!) {
  checkoutShippingLineUpdate(checkoutId: $checkoutId, shippingRateHandle: $shippingRateHandle) {
    checkout {
     ...checkoutFields
    }
    checkoutUserErrors {
      field
      message
      code
    }
  }
}
${checkoutFields}
`;

export const checkoutLineItemsUpdateMutation = `
mutation checkoutLineItemsReplace($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
  checkoutLineItemsReplace(checkoutId: $checkoutId, lineItems: $lineItems) {
    checkout {
      ...checkoutFields
      discountApplications(first: 10) {
        edges {
          node {
            ... on DiscountCodeApplication {
              allocationMethod
              applicable
              targetType
              targetSelection
              code
              value {
                ... on MoneyV2 {
                  amount
                  currencyCode
                }
                ... on PricingPercentageValue {
                  percentage
                }
              }
            }
          }
        }
      }
    }
    userErrors {
      field
      message
    }
  }
}
${checkoutFields}
`;

export const checkoutEmailUpdateMutation = `
mutation checkoutEmailUpdateV2($checkoutId: ID!, $email: String!) {
  checkoutEmailUpdateV2(checkoutId: $checkoutId, email: $email) {
    checkout {
      ...checkoutFields
    }
    checkoutUserErrors {
      field
      message
    }
  }
}
${checkoutFields}
`;
