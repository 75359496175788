<script lang="ts">
  import { defineComponent, onMounted } from '@vue/composition-api';
  import { useTabs, Tabs } from './useTabs';

  export default defineComponent({
    name: 'Tabs',
    setup(_, context) {
      const { tabs, setActiveTab, activeTab } = useTabs(context);

      onMounted(() => {
        const firstTab = Object.keys(tabs[0])[0];
        setActiveTab(firstTab);
      });

      return {
        tabs,
        setActiveTab,
        activeTab,
        getTabIdentifier: (tab: Tabs) => Object.keys(tab)[0],
      };
    },
  });
</script>

<template>
  <div class="fc-container">
    <div class="fc-tabs__tab-container fc-flex fc-justify-center fc-flex-wrap fc-padding-x-2 fc-padding-bottom-12 md:fc-padding-x-0">
      <button
        v-for="tab in tabs"
        :key="getTabIdentifier(tab)"
        class="fc-tabs__tab fc-padding-y-2"
        :class="{ 'fc-tabs__tab--active': activeTab === getTabIdentifier(tab) }"
        :aria-pressed="activeTab === getTabIdentifier(tab)"
        @click="setActiveTab(getTabIdentifier(tab))">
        <p class="fc-bold fc-margin-0">
          {{ Object.values(tab)[0].name }}
        </p>
      </button>
    </div>
    <div class="tabs__tabs">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import "../../styles/exports";

.fc-tabs {
  &__tab-container {
    gap: get-block-size-level(3);
  }

  &__tab {
    background: $color-white;
    color: $color-blue;
    border: $color-white;
    border-radius: $container-radius;
    cursor: pointer;
    outline: inherit;
    min-width: 116px;

    @media #{$tablet-up} {
      min-width: 140px;
    }

    &:hover,
    &--active {
      background: $color-blue;
      color: $color-white;
      border: $color-blue;
    }
  }
}

</style>
