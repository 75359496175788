export default {
  title: 'FightCamp Connect',
  id: 'fightcamp_connect',
  price: 43900,
  affirmPrice: 137500,
  oldPrice: 49900,
  monthly: 3658,
  months: 24,
  category: 'package',
  path: '/shop/products/fightcamp-connect/',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-products-connect-setup-001.jpg',
  description: 'If you already have access to good Boxing equipment, this package will get you what you need.',
  fbid: 'shopify_1924427087926_19085405028406',
  gmc_id: '2276347125729748',

};
