export const bundleBetterEveryDay = {
  title: 'FightCamp Better Every Day Bundle',
  id: 'fightcamp_bundle_better_every_day',
  price: 12900,
  fullPrice: 15700,
  category: 'accessory',
  description: '',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-bundle-better-every-day-001.png',
  images: [
    'fc-bundle-better-002.jpg',
    'fc-bundle-better-001.jpg',
    'fc-bundle-better-003.jpg',
  ],
  fbid: '',
};
