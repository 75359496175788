export default {
  title: 'FightCamp Wraps',
  id: 'fightcamp_quickwraps_deal',
  price: 0,
  oldPrice: 3900,
  category: 'accessory',
  description: '',
  img_url: '',
  fbid: '',
};
