export default {
  title: 'FightCamp Gym',
  id: 'fightcamp_gym',
  price: 121900,
  oldPrice: 199500,
  monthly: 5079,
  months: 24,
  category: 'package',
  fbid: 'shopify_737157578806_8880210477110',
  gmc_id: '2377884125618561',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-products-gym-setup-001.jpg',
};
