export const fightcampPremiumFlooring = {
  title: 'Premium Flooring',
  id: 'fightcamp_premium_flooring',
  price: 19900,
  category: 'accessory',
  description: 'Protect your home from scuffs & scrapes with heavy-duty flooring. 8 inter-locking tiles provide shock absorption & friction to keep you safe.',
  img_url: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-flooring-001.png',
  types: ['Setup'],
};
