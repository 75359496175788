<template>
  <div
    :class="getContainerClass"
    @click="handleClick"
    @mouseover="handleMouseOver"
    @mouseout="handleMouseOut">
    <video
      v-bind="videoBindings"
      ref="videoRef"
      :autoplay="isAutoPlayback"
      class="video"
      width="100%"
      height="100%"
      playsinline
      controlslist="nodownload"
      loop
      @pause="handleVideoPlaying"
      @playing="handleVideoPlaying">
      <slot />
    </video>

    <div v-if="showPlayIcon" class="video__play">
      <font-awesome-icon
        class="video__play-icon"
        :icon="['fc', 'play']"
        height="80"
        width="80" />
    </div>

    <div v-if="hasPauseIcon" class="video__play-pause-container">
      <!-- TODO create this as a button type in Angie UI? -->
      <button
        type="button"
        :aria-label="showPauseIcon ? 'pause video' : 'play video'"
        class="video__play-pause-button button-layout button-secondary"
        @click="handleVideo">
        <svg
          v-if="showPauseIcon"
          class="video__pause-icon-svg"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          height="16"
          viewBox="0 0 16 16"
          width="16"><g><path d="m12 2v12h-1.6v-12z" /><path d="m5.6 2v12h-1.6v-12z" /></g>
        </svg>
        <svg
          v-else
          class="video__play-icon-svg"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none">
          <path d="M7.5 3L19.5 12L7.5 21V3Z" />
        </svg>
      </button>
    </div>

    <div
      v-if="isOverlay"
      aria-hidden="true"
      class="video__overlay fc-flex fc-column" />
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    computed,
    ref,
    PropType,
  } from '@vue/composition-api';
  import { VideoPlayback, VideoPreload } from './types';

  // TODO refactor component and remove props/attrs not being used
  export default defineComponent({
    name: 'Video',
    props: {
      videoKey: {
        type: String,
        default: null,
      },
      firstFrameImageUrl: {
        type: String,
        required: true,
        default: null,
      },
      isOverlay: {
        type: Boolean,
        default: false,
      },
      videoMuted: {
        type: Boolean,
        default: true,
      },
      isBackground: {
        type: Boolean,
        default: false,
      },
      videoPlayback: {
        type: String as PropType<VideoPlayback>,
        default: VideoPlayback.auto,
      },
      controls: {
        type: Boolean,
        default: false,
      },
      hasPlayIcon: {
        type: Boolean,
        default: false,
      },
      hasPauseIcon: {
        type: Boolean,
        default: false,
      },
      rounded: {
        type: Boolean,
        default: false,
      },
      preload: {
        type: String as PropType<VideoPreload>,
        default: 'metadata',
      },
    },
    setup(props) {
      const videoRef = ref<HTMLVideoElement>();
      const videoPaused = ref(true);

      const playVideo = async () => {
        if (!videoRef.value?.paused) return;

        await videoRef.value?.play();
      };

      const pauseVideo = () => {
        videoRef.value?.pause();
      };

      const videoBindings = computed(() => ({
        poster: props.firstFrameImageUrl,
        preload: props.preload,
        ...props.videoKey ? { key: props.videoKey } : {},
        ...props.controls ? { controls: true } : {},
        ...props.videoMuted ? { muted: true } : {},
      }));

      return {
        handleVideo() {
          if (videoPaused.value) {
            playVideo();
          } else {
            pauseVideo();
          }
          videoPaused.value = !!videoRef.value?.paused;
        },
        handleVideoPlaying() {
          videoPaused.value = !!videoRef.value?.paused;
        },
        async handleClick() {
          if (props.videoPlayback === VideoPlayback.onClick) {
            await playVideo();
          }
        },
        async handleMouseOver() {
          if (props.videoPlayback === VideoPlayback.onHover) {
            await playVideo();
          }
        },
        handleMouseOut() {
          if (props.videoPlayback === VideoPlayback.onHover) {
            pauseVideo();
          }
        },
        videoRef,
        videoBindings,
        showPlayIcon: computed(() => (!videoPaused.value ? false : props.hasPlayIcon)),
        showPauseIcon: computed(() => (videoPaused.value ? false : props.hasPauseIcon)),
        getContainerClass: computed(() => {
          const wrapperClass= props.isBackground ? 'video__wrapper--background' : 'video__wrapper';
          return {
            [wrapperClass]: true,
            'video-rounded': props.rounded,
          };
        }),
        isAutoPlayback: computed(() => (props.videoPlayback === VideoPlayback.auto ? 'true' : null)),
      };
    },
  });
</script>

<style scoped lang="scss">
  @import "../../styles/exports";

  .video__wrapper {
    display: flex;
    position: relative;
    overflow: hidden;
    width: 100%;

    &--background {
      position: relative;
      height: 100%;
      width: 100%;
      overflow: hidden;
      display: flex;

    }
  }

  .video-rounded {
    border-radius: 18px;
  }

  .video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    // position: absolute;

    &__overlay {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      align-items: stretch;
      justify-content: stretch;
      background-image: radial-gradient(ellipse at center, transparent 20%, $color-black 80%);
    }

    &__play {
      position: absolute;
      height: 5rem;
      width: 5rem;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;

      &-icon {
        color: $color-white;
        height: 100%;
        width: 100%;
      }
    }

    &__play-pause-container {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(-26%, -26%);
      display: flex;
      justify-content: center;
      align-items: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__play-pause-button {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      padding: 0 !important;
    }

    &__pause-icon-svg {
      g {
        fill: $fc-blue-primary-800;
      }
    }

    &__play-icon-svg {
      path {
        fill: $fc-blue-primary-800;
      }
    }
  }
</style>
