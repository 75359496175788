//
//
//
//
//
//
//
//

import buttonStyleMixin from '../mixins/button-style';

export default {
  name: 'ButtonAction',
  mixins: [buttonStyleMixin],
  props: {
    loading: Boolean,
  },
};
