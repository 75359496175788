export const fightcampAgilityLadder =  {
  title: 'Agility Ladder',
  id: 'fightcamp_agility_ladder',
  price: 1900,
  category: 'accessory',
  description: 'Improve your speed and coordination with this 12-rung ladder.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-agility-ladder-001.png',
  types: ['Training'],
  sold_out: true,
};
