import { Experiment, OptimizeOptions } from './types';
import { EXPERIMENT_KEY_REGEX, LOCAL_EXPERIMENT_OVERRIDE_KEY } from './constants';

export function installLocalToggleOverrideHandler(options: OptimizeOptions): void {
  if (!options.localOverrideEnabled) {
    return;
  }

  const queryString = window.location.search;
  let sanitizedQuery = queryString && queryString[0] === '?' ? queryString.slice(1) : queryString;
  sanitizedQuery = sanitizedQuery && decodeURIComponent(sanitizedQuery);
  const toggleOverrideMap = sanitizedQuery && parseExperimentToggles(sanitizedQuery);

  if (toggleOverrideMap && Object.keys(toggleOverrideMap).length) {
    window.sessionStorage.setItem(LOCAL_EXPERIMENT_OVERRIDE_KEY, JSON.stringify(toggleOverrideMap));
  }
}

function parseExperimentToggles(sanitizedQuery: string): Record<string, Experiment> {
  const searchParams = new URLSearchParams(sanitizedQuery);
  const toggleMap: Record<string, Experiment> = {};

  searchParams.forEach((variant, key) => {
    const match = key.match(EXPERIMENT_KEY_REGEX);

    if (match) {
      const id = match[1];
      toggleMap[id] = { id, variant };
    }
  });

  return toggleMap;
}
