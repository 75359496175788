export default {
  title: 'FightCamp Kid Gloves',
  id: 'fightcamp_kidgloves',
  price: 4900,
  category: 'accessory',
  description: '',
  img_url: '',
  fbid: 'shopify_1948387803190_19346090229814',
  marketplace_img_url: '/v4/fc-marketplace-accessory-kids-gloves-001.png',
  types: ['Essentials'],
};
