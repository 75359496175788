export const fightcampYogaMat = {
  title: 'Premium Exercise Mat',
  id: 'fightcamp_yoga_mat',
  price: 2900,
  category: 'accessory',
  description: 'Lay out this premium yoga mat for comfortable floor workouts. 6ft long, 2 ft wide & a 1/2 in thick.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-yoga-mat-001.png',
  tag: 'Used In Workouts',
  types: ['Setup']
};
