














import { defineComponent, computed, PropType } from '@vue/composition-api';
import {
  ButtonSizes, ButtonAlignment, ButtonThemes, LinkButtonElements,
} from './types';

export const BUTTON_TEXT_SIZES: ButtonSizes = {
  small: 'button-small',
  med: 'button-med',
  default: '',
};

const BUTTON_ALIGNMENT: ButtonAlignment = {
  left: 'link-button--left',
  center: '',
  right: 'link-button--right',
};

export default defineComponent({
  name: 'LinkButton',
  props: {
    ctaText: {
      type: String,
      default: '',
      required: false,
    },
    ctaLink: {
      type: String,
      required: true,
    },
    buttonTheme: {
      type: String as PropType<ButtonThemes>,
      default: 'button-primary',
    },
    buttonSize: {
      type: String,
      default: 'default',
    },
    buttonAlignment: {
      type: String,
      default: 'center',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    elementName: {
      type: String as PropType<LinkButtonElements>,
      default: 'a',
    },
    mobileAlignmentCenter: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isAnchorDisabled = computed(() => props.buttonTheme === 'button-disabled');
    const isLinkComponent = computed(() => props.elementName === 'NuxtLink' || props.elementName === 'router-link');

    const getNavAttribute = computed(() => {
      // TODO remove / from nuxtlink and router-link
      if (isLinkComponent.value) {
        if (props.ctaLink.includes('#')) {
          const [page, id] = props.ctaLink.split('#');
          return { to: { path: page, hash: `#${id}` } };
        }
        return { to: `/${props.ctaLink}` };
      }
      return { href: props.ctaLink };
    });
    return {
      isAnchorDisabled,
      getNavAttribute,
      getContainerClass: computed(() => (props.fullWidth ? 'link-button__container--block' : 'fc-flex link-button__container')),
      getButtonClasses: computed<string[]>(() => {
        const classes = [] as string[];

        if (!props.fullWidth) {
          classes.push((BUTTON_ALIGNMENT)[props.buttonAlignment as keyof ButtonAlignment]);
          if (props.mobileAlignmentCenter) {
            classes.push('link-button--mobile-center');
          }
        }

        classes.push(`button-layout ${props.buttonTheme} ${BUTTON_TEXT_SIZES[props.buttonSize as keyof ButtonSizes]}`);

        if (isAnchorDisabled.value) {
          classes.push('link-button--disabled');
        }

        return classes;
      }),
    };
  },
});
