import { getUrlQueryParams, removeQueryParams } from '../../utils/url';
import { REFERRER_KEYS } from './constants';
import {
  TrackProduct,
  Product,
  ProductContentList,
  ConfirmationProps,
  CheckoutProps,
  CustomerName,
  ProductListViewedProps,
  SpendDetails,
  IAnalytics,
  PageContent,
} from './types';

export function getUserTraits(): object {
  if (!window.analytics || !window.analytics.user) {
    return {};
  }

  const user = window.analytics.user();
  if (!user || !user.traits) {
    return {};
  }

  return user.traits();
}

function getSegment() {
  return {
    track: (name: string, props: object, options?: object) => window.analytics.track(name, props, { ...options, traits: getUserTraits() }),
    page: window.analytics.page,
    identify: window.analytics.identify,
  };
}

export const SegmentWrapper: IAnalytics = {
  elementViewed: (name: string) => {
    getSegment().track('Element Viewed', { name });
  },

  elementClicked: (name: string) => {
    getSegment().track('Element Clicked', { name });
  },

  trackEvent: (eventName: string, props: any) => {
    getSegment().track(eventName, { ...props });
  },

  trackError: (props: {caller: string, error: any}) => {
    getSegment().track('Internal Error', { ...props });
  },

  pageViewed: (details?: PageContent) => {
    getSegment().page(details?.name, { googleOptimize: details?.optimizeProperties || [], ...getUrlQueryParams() });
    removeQueryParams(REFERRER_KEYS);
  },

  productListViewed: ({ originListID, productList, category }: ProductListViewedProps) => {
    getSegment().track('Product List Viewed', {
      list_id: originListID,
      category,
      products: formatProductList(productList),
    });
  },

  productViewed: (product: Product) => {
    getSegment().track('Product Viewed', formatProduct(product));
  },

  productClicked: (product: Product) => {
    getSegment().track('Product Clicked', formatProduct(product));
  },

  productAdded: (product: Product) => {
    getSegment().track('Product Added', formatProduct(product));
  },

  productRemoved: (product: Product) => {
    getSegment().track('Product Removed', formatProduct(product));
  },

  // Coupons
  couponEntered: (coupon: string) => {
    getSegment().track('Coupon Entered', {
      coupon_id: coupon,
    });
  },

  couponApplied: (coupon: string) => {
    getSegment().track('Coupon Applied', {
      coupon_id: coupon,
    });
  },

  couponDenied: (coupon: string) => {
    getSegment().track('Coupon Denied', {
      coupon_id: coupon,
    });
  },

  couponRemoved: (coupon: string) => {
    getSegment().track('Coupon Removed', {
      coupon_id: coupon,
    });
  },

  // Campaigns
  campaignViewed: (data: object) => {
    getSegment().track('Campaign Viewed', data);
  },
  campaignSubscribed: (data: object) => {
    getSegment().track('Campaign Subscribed', data);
  },
  campaignDismissed: (data: object) => {
    getSegment().track('Campaign Dismissed', data);
  },

  // Identifications
  UIDIdentified: (uid: string) => {
    getSegment().identify(uid);
  },

  emailIdentified: (email: string) => {
    getSegment().identify({
      email,
    });
  },

  nameIdentified: ({ firstName, lastName }: CustomerName) => {
    getSegment().identify({
      firstName,
      lastName,
    });
  },

  phoneNumberIdentified: (phone: string) => {
    getSegment().identify({
      phone,
    });
  },

  // Cart
  cartViewed: (products: ProductContentList) => {
    getSegment().track('Cart Viewed', {
      products: formatProductList(products),
    });
  },

  cartAbandoned: (products: ProductContentList) => {
    getSegment().track('Cart Abandoned', {
      products: formatProductList(products),
    });
  },

  checkoutStarted: ({
    value, revenue, tax, shipping, discount, coupon, products,
  }: CheckoutProps) => {
    getSegment().track('Checkout Started', {
      value: formatPrice(value),
      revenue: formatPrice(revenue),
      currency: 'USD',
      coupon,
      tax: formatPrice(tax),
      shipping: formatPrice(shipping),
      discount: formatPrice(discount),
      products: formatProductList(products),
    });
  },

  orderCompleted: (confirmation: ConfirmationProps) => {
    const costBreakdown = confirmation.order.costBreakdown;

    getSegment().track('Order Completed', {
      coupon: costBreakdown.couponName,
      currency: 'USD',
      discount: formatPrice(costBreakdown.discountAmount),
      order_id: confirmation.orderNumber,
      payment_method: confirmation.method,
      revenue: formatPrice(costBreakdown.totalCost),
      shipping: formatPrice(costBreakdown.productShipping),
      subtotal: formatPrice(costBreakdown.productCost - (costBreakdown?.discountAmount || 0)),
      tax: formatPrice(costBreakdown.productTax),
      total: formatPrice(costBreakdown.productCost),
      products: formatProductList(confirmation.products),
      is_package: confirmation.packageInCart,
    });
  },

  marketingSpendUpdated: (details: SpendDetails) => {
    getSegment().track('Marketing Spend Updated', details);
  },

  // blog
  adClicked: (details: object) => {
    getSegment().track('Ad Clicked', details);
  },

  // customer dashboard
  reactivateUser: (email: string) => {
    getSegment().track('Customer Dashboard | Reactivation', { email });
  },
};

function formatPrice(value: number): string {
  return (value / 100.0).toFixed(2);
}

// TODO remove check for categor, title, img_url. This was added to support the old product schema https://github.com/fightcamp/fightcamp-web-monorepo/pull/1098/files/7b7d887bcbf4f906eea3ab1007de25692a6c32a4#r1761745909
function formatProduct(product: Product): TrackProduct {
  return {
    product_id: product?.id,
    category: product?.productType ?? product?.category,
    name: product?.productName ?? product?.title,
    brand: 'FightCamp',
    price: formatPrice(product?.price),
    value: formatPrice(product?.price),
    currency: 'USD',
    quantity: 1,
    url: `https://${window.location.hostname}${product.pathId}`,
    img_url: product?.primaryImg?.src ?? product?.img_url,
    variant: product?.variantId || null,
    coupon: null,
    sku: null,
  };
}

function formatProductList(productList: ProductContentList) {
  return productList.map((product, pos) => ({
    ...formatProduct(product),
    position: pos + 1,
  }));
}
