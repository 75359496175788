import { asyncLoadLibrary } from '../../utils/asyncLoadLibrary';
import { OptimizeOptions } from './types';

export async function installOptimize(options: OptimizeOptions): Promise<void> {
  window.dataLayer = window.dataLayer || [];
  try {
    await asyncLoadLibrary({
      id: 'fc-google-optimize',
      url: `https://www.googleoptimize.com/optimize.js?id=${options.optimizeId}`,
      appendOnHead: true,
    });
  } catch (error) {
    console.error(error);
  }
}
