const attribution = require('./utils/attribution');

const Podcast = {
  id: 'Podcast',
  type: 'input',
  question: 'Which Podcast?',
  attribution: attribution.Podcast,
};


module.exports = Podcast;
