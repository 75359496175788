import {
  onBeforeUnmount,
  onMounted,
  ref,
  Ref,
  watch,
} from '@vue/composition-api';
import { CarouselContext, CarouselParams, SliderOptions } from '../types';
import { CarouselAnimatorImpl } from '../services/CarouselAnimator';

interface CarouselContextReturn {
  slider: Ref<HTMLElement | undefined>,
  carouselContext: CarouselContext;
}

export function useCarouselContext(params: CarouselParams): CarouselContextReturn {
  const animator = new CarouselAnimatorImpl(
    ref<HTMLElement>(),
    ref<number>(0),
  );

  onMounted(() => {
    animator.initialize(params);
  });

  onBeforeUnmount(() => {
    animator.teardown();
  });

  watch(() => params.slideWidthPercentage, (newPercentage, oldPercentage) => {
    if (newPercentage !== oldPercentage) {
      animator.setSliderPositionByCurrentIndex();
    }
  });

  const carouselContext: CarouselContext = {
    currentIndex: animator.getCurrentIndexRef(),
    isThreeSlideView: params.threeSlideView || false,
    getWidthPercentage: () => params.slideWidthPercentage || 1,
    startSlideDrag(slideIndex: number, event: Event): void {
      animator.startSlideDrag(slideIndex, event);
    },
    continueSlideDrag(event: Event): void {
      animator.continueSlideDrag(event);
    },
    endSlideDrag(): void {
      animator.endSlideDrag();
    },
    slideToIndex(index: number, options?: SliderOptions): void {
      animator.manuallySlideToIndex(index, options);
    },
  };

  return {
    carouselContext,
    slider: animator.getSliderRef(),
  };
}
