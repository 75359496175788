export default {
  title: 'FightCamp The Works',
  id: 'fightcamp_the_works',
  price: 99900,
  monthly: 4163,
  months: 24,
  category: 'package',
  img_url: 'https://a.storyblok.com/f/152150/2000x2000/b93e958edd/fc-ultimate-bundle-package-001.jpg',
  description: 'The ultimiate FightCamp bundle',
};
