const attribution = require('./utils/attribution');
const otherOption = require('./utils/other');

const newspaperAd = {
  id: 'Newspaper Ad',
  question: 'What newspaper was the ad in?',
  type: 'list',
  options: [
    {
      id: 'Newspaper NYT',
      label: "New York Times",
      attribution: attribution.Unknown,
    },
    {
      id: 'Newspaper LAT',
      label: "Los Angeles Times",
      attribution: attribution.UTM,
    },
    {
      id: 'Newspaper WSJ',
      label: "Wall Street Journal",
      attribution: attribution.UTM,
    },
    {
      id: 'Newspaper USAT',
      label: "USA Today",
      attribution: attribution.UTM,
    },
    otherOption({ id: 'Other', attribution: attribution.Unknown }),
  ],
};

const newspaperArticle = {
  id: 'Newspaper Article',
  question: 'What newspaper was the article written in?',
  type: 'list',
  options: [
    {
      id: 'Newspaper NYT',
      label: "New York Times",
      attribution: attribution.PR,
    },
    {
      id: 'Newspaper LAT',
      label: "Los Angeles Times",
      attribution: attribution.PR,
    },
    {
      id: 'Newspaper WSJ',
      label: "Wall Street Journal",
      attribution: attribution.PR,
    },
    {
      id: 'Newspaper USAT',
      label: "USA Today",
      attribution: attribution.PR,
    },
    otherOption({ id: 'Other', attribution: attribution.Unknown }),
  ],
};

const newspaper = {
  id: 'Newspaper',
  question: 'What did you see in the Newspaper?',
  type: 'list',
  options: [
    {
      id: 'Newspaper Ad',
      label: 'Advertisement',
      followup: newspaperAd,
      attribution: attribution.Unknown,
    },
    {
      id: 'Newspaper Article',
      label: 'An article written about FightCamp',
      followup: newspaperArticle,
      attribution: attribution.PR,
    },
    otherOption({ id: 'Newspaper Other', attribution: attribution.Unknown }),
  ],
};

module.exports = newspaper;
