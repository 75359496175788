const attribution = require('./utils/attribution');

const appStore = {
  id: 'AppStore',
  question: 'What did you search?',
  type: 'input',
  attribution: attribution.Appstore,
};

module.exports = appStore;
