const enum storeMutations {
  SET_NAV_TYPE = 'SET_NAV_TYPE',
  SET_NAV_DATA = 'SET_NAV_DATA',
  SET_MODAL_SUPPRESSION = 'SET_MODAL_SUPPRESSION',
  SET_SLIDER_VISIBLE = 'SET_SLIDER_VISIBLE',
  TOGGLE_SHOW_EMAIL_INPUT = 'TOGGLE_SHOW_EMAIL_INPUT',
  SET_SHOW_EMAIL_INPUT = 'SET_SHOW_EMAIL_INPUT',
  REGISTER_EXPERIMENT = 'REGISTER_EXPERIMENT',
  HIDE_SHOP_BUTTON = 'HIDE_SHOP_BUTTON',
  ADD_JOB_POSTINGS = 'ADD_JOB_POSTINGS',
  IS_IN_STORYBLOK = 'IS_IN_STORYBLOK',
  SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE',
  SET_PACKAGE_PAGE_TOTAL = 'SET_PACKAGE_PAGE_TOTAL',
  SET_GEOLOCATION_COUNTRY = 'SET_GEOLOCATION_COUNTRY',
  REGISTER_GROWTHBOOK_EXPERIMENTS = 'REGISTER_GROWTHBOOK_EXPERIMENTS',
  SET_GROWTHBOOK_INITIALIZED = 'SET_GROWTHBOOK_INITIALIZED',
  SET_FEATURE_FLAG = 'SET_FEATURE_FLAG',
}

export default storeMutations;
