import Vue from 'vue';

import ClickOutside from '@/shared/directives/click-outside';

export const divideByOneHundred = (val: string | undefined, fixed?: number) => parseFloat((+(val ?? '') / 100.0).toString()).toFixed(fixed ?? 0);
export const currency = (val: number | null) => `$${divideByOneHundred(val?.toString(), 2)}`;
export const currency2 = (val: number | null) => `$${divideByOneHundred(val?.toString())}`;

Vue.filter('currency', currency);
Vue.filter('currency2', currency2);
Vue.filter('currencyDigitsOnly', divideByOneHundred);

Vue.directive('click-outside', ClickOutside);
