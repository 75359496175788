import round from 'lodash/round';

export enum ImageUnits {
  'rem' = 'rem',
  'vw' = 'vw',
  'px' = 'px',
}

export enum ImageSizes {
  'xs' = 320,
  'sm' = 640,
  'md' = 768,
  'lg' = 1024,
  'xl' = 1280,
  'xxl' = 1536,
  '2xl' = 1536,
}

export const orderedImageWidths = [
  ImageSizes.xs,
  ImageSizes.sm,
  ImageSizes.md,
  ImageSizes.lg,
  ImageSizes.xl,
  ImageSizes.xxl,
  ImageSizes['2xl'],
];

export const finalValueUnitMap: Map<ImageUnits | string, (n: number, x: number) => number> = new Map([
  [ImageUnits.px, (number: number, _: number) => number],
  [ImageUnits.vw, (number: number, currentWidth: number) => (+round(number / 100, 2).toFixed(2)) * currentWidth],
  [ImageUnits.rem, (number: number, _: number) => number * 16],
]);
