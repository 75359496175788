export default {
  title: 'PARTNER FIGHTCAMP STARTER PACKAGE',
  id: 'pt_fightcamp_trackers',
  price: 23800,
  affirmPrice: 0,
  oldPrice: 0,
  monthly: 0,
  months: 0,
  category: 'package',
  path: '/partner-offer/',
  internalPath: 'partner-offer/',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-fightcamp-base-001.png',
  description: 'Our essentials package',
  fbid: '',
  gmc_id: '',
};