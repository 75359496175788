export default {
  title: 'FightCamp Trackers',
  id: 'fightcamp_trackers_399',
  price: 39900,
  affirmPrice: 39900,
  oldPrice: 39900,
  monthly: 1663,
  months: 24,
  category: 'package',
  path: '/shop/products/fightcamp-trackers/',
  internalPath: 'shop/products/fightcamp-trackers/',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-fightcamp-base-001.png',
  description: 'Our essentials package',
  fbid: 'shopify_1924427087926_19085405028406',
  gmc_id: '2276347125729748',
};
