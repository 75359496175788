<template>
  <div
    class="collapsible-preheader fc-flex fc-align-start fc-column"
    :class="getCollapsiblePreHeaderClasses">
    <div
      v-if="active"
      class="collapsible-preheader--close"
      @click="toggleCollapsible">
      <FontAwesomeIcon
        class="collapsible-preheader__close-icon"
        :icon="['fas', 'times']" />
    </div>
    <a
      class="collapsible-preheader__banner fc-flex-row-center"
      :class="getTextColor"
      @click="toggleCollapsible">
      <slot name="header" />
    </a>
    <div v-if="active" class="collapsible-preheader__panel">
      <slot name="body" />
      <FontAwesomeIcon
        class="collapsible-preheader__scroll-icon fc-block"
        :icon="['fas', 'chevron-down']"
        @click="scrollDown()" />
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed, defineComponent, PropType,
  } from '@vue/composition-api';
  // https://fightcamp.atlassian.net/browse/FLX-1221
  // TODO: Reconcile ValidColors with BackgroundTheme global
  import { ValidColors } from './types';

  export default defineComponent({
    name: 'CollapsiblePreHeader',
    props: {
      id: {
        type: String,
        default: '',
        required: true,
      },
      active: {
        type: Boolean,
        default: false,
        required: true,
      },
      bgColor: {
        type: String as PropType<ValidColors>,
        default: 'red',
        required: false,
      },
      textColor: {
        type: String as PropType<ValidColors>,
        default: 'white',
        required: false,
      },
    },
    setup(props, context) {
      function scrollDown(): void {
        context.emit('onScrollDown');
      }

      function toggleCollapsible(): void {
        context.emit('update:active', !props.active);
      }

      return {
        scrollDown,
        toggleCollapsible,
        getTextColor: computed<string>(() => `fc-text-${props.textColor}`),
        getCollapsiblePreHeaderClasses: computed<string[]>(() => {
          const classes = [];

          if (props.active) {
            classes.push('collapsible-preheader--active');
          }

          classes.push(`fc-bg-${props.bgColor}`);
          classes.push(`fc-text-${props.textColor}`);

          return classes;
        }),
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import "../../styles/exports";

  .collapsible-preheader {
    z-index: 101;
    transition: all 0.5s;
    min-height: 0;

    @media #{$tablet-up} {
      justify-content: center;
    }

    &__banner {
      text-decoration: none;
      width: 100%;
    }

    &__panel {
      margin: get-spacing-level(0) auto;
      width: 100%;
      text-align: left;
      max-width: 580px;

      @media #{$tablet-up} {
        max-width: 660px;
      }
    }

    &--close {
      width: 100%;
      max-width: 660px;
      font-size: get-font-size-level(8);
      margin: get-spacing-level(2) auto;
      text-align: right;
    }

    &__close-icon {
      margin-right: get-spacing-level(2);
      cursor: pointer;
    }

    &--active {
      min-height: 100vh;
    }

    &__scroll-icon {
      font-size: get-font-size-level(12);
      margin: get-spacing-level(4) auto;
      cursor: pointer;
    }
  }
</style>
