














import {
  defineComponent, computed, PropType,
} from '@vue/composition-api';
import {
  ButtonSizes, ButtonAlignment, ButtonThemes,
} from './types';

export const BUTTON_TEXT_SIZES: ButtonSizes = {
  small: 'button-small',
  big: 'button-big',
  default: '',
};

const BUTTON_ALIGNMENT: ButtonAlignment = {
  left: 'link-button--left',
  center: '',
  right: 'link-button--right',
};

export default defineComponent({
  name: 'AnchorLinkButton',
  props: {
    ctaText: {
      type: String,
      default: '',
      required: false,
    },
    linkId: {
      type: String,
      required: true,
    },
    buttonTheme: {
      type: String as PropType<ButtonThemes>,
      default: 'button-primary',
    },
    buttonTextSize: {
      type: String,
      default: 'default',
    },
    buttonAlignment: {
      type: String,
      default: 'center',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    mobileAlignmentCenter: {
      type: Boolean,
      default: false,
    },
    offSet: {
      type: String,
      default: '0',
    },
  },
  setup(props) {
    const isAnchorDisabled = computed(() => props.buttonTheme === 'button-disabled');

    function smoothScroll() {
      const element = document.getElementById(props.linkId);
      if (element) {
        window.scrollTo({
          top: element.offsetTop + Number(props.offSet) || 0,
          behavior: 'smooth',
        });
      }
    }

    return {
      isAnchorDisabled,
      getContainerClass: computed(() => (props.fullWidth ? 'link-button__container--block' : 'fc-flex link-button__container')),
      getButtonClasses: computed<string[]>(() => {
        const classes = [] as string[];

        if (!props.fullWidth) {
          classes.push((BUTTON_ALIGNMENT)[props.buttonAlignment as keyof ButtonAlignment]);
          if (props.mobileAlignmentCenter) {
            classes.push('link-button--mobile-center');
          }
        }

        classes.push(`button-layout ${props.buttonTheme} ${BUTTON_TEXT_SIZES[props.buttonTextSize as keyof ButtonSizes]}`);

        if (isAnchorDisabled.value) {
          classes.push('link-button--disabled');
        }

        return classes;
      }),
      smoothScroll,
    };
  },
});
