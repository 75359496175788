export const fightcampJumpRopeSale = {
  title: 'Premium Jump Rope',
  id: 'fightcamp_jump_rope_sale',
  price: 3900,
  oldPrice: 4900,
  category: 'accessory',
  description: 'Every fighter trains with a jump rope. Upgrade your game and get the jump rope our trainers love most. 128in long and easily adjustable.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-jump-rope-001.png',
  tag: '20% Off',
  types: ['Training']
};
