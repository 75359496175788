<template>
  <div class="email-capture" :class="emailCaptureClass">
    <div class="email-capture__text-container sm:fc-col-span-5" :class="{'email-capture__column-order': isTabletUpColumnOrder}">
      <slot />
    </div>
    <div class="email-capture__social-container fc-flex fc-column fc-justify-center sm:fc-col-span-5" :class="{'email-capture__column-order': isTabletUpColumnOrder}">
      <p v-if="isSubmitted" class="email-capture__sucesss-text fc-text-eggshell fc-text">
        Thanks for signing up!
      </p>
      <form
        v-show="!isSubmitted"
        class="email-capture__form"
        @submit="handleSubmit($event)">
        <input
          id="emailCapture"
          v-model="email"
          placeholder=" "
          maxlength="100"
          type="email"
          class="email-capture__input"
          :class="{ 'email-capture__error-color' : allValidationErrors.length}"
          @keyup.enter="$emit('handleSubmit')">
        <label
          for="emailCapture"
          class="email-capture__label"
          :class="{ 'email-capture__error-color' : allValidationErrors.length}">Email Address</label>
        <p v-if="allValidationErrors.length" class="email-capture__error">
          {{ allValidationErrors[0] }}
        </p>
        <button
          type="submit"
          class="button-layout button-tertiary email-capture__button">
          {{ ctaText }}
        </button>
      </form>
      <div v-if="icons.length" class="email-capture__icon-container">
        <SocialIcons
          class="email-capture__social-icons"
          :icon-size="iconSize"
          :icons="icons" />
      </div>
      <slot name="mobileContent" class="md:fc-hidden" />
    </div>
  </div>
</template>

<script>
  import { ref, computed, watch } from '@vue/composition-api';
  import SocialIcons from '../SocialIcons/SocialIcons.vue';
  import { EMAIL_VALIDATOR } from './constants';

  export default {
    name: 'EmailCapture',
    components: {
      SocialIcons,
    },
    props: {
      ctaText: {
        type: String,
        default: 'Subscribe',
      },
      icons: {
        type: Array,
        default: () => [],
      },
      errors: {
        type: Array,
        default: () => [],
      },
      iconSize: {
        type: String,
        default: 'default',
        validator(iconSize) {
          return iconSize === 'default' || iconSize === 'big';
        },
      },
      isSubmitted: {
        type: Boolean,
        default: false,
      },
      isTabletUpColumnOrder: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, context) {
      const email = ref('');
      const internalErrors = ref([]);
      const allValidationErrors = computed(() => internalErrors.value.concat(props.errors || []));

      watch(() => props.isSubmitted, (newVal) => {
        if (newVal) {
          email.value = '';
        }
      });

      function handleSubmit(event) {
        event.preventDefault();
        if (isValidRegexEmail(email.value)) {
          internalErrors.value = [];
          context.emit('submitEmail', email.value);
        } else {
          internalErrors.value = ['invalid email'];
        }
      }

      return {
        email,
        allValidationErrors,
        handleSubmit,
        emailCaptureClass: computed(() => (props.isTabletUpColumnOrder ? 'email-capture__column-order' : 'fc-grid')),
      };
    },
  };

  function isValidRegexEmail(email) {
    return EMAIL_VALIDATOR.test(String(email).toLowerCase());
  }
</script>

<style lang="scss" scoped>
  @import "../../styles/exports";

  .email-capture {
    width: 100%;

    &__text-container {
      &:not(.email-capture__column-order) {
        @media #{$tablet-up} {
          grid-column-start: 2;
          grid-column-end: 7;
        }
      }

      @media #{$tablet-up} {
        grid-column-start: 2;
        grid-column-end: 12;
      }
    }

    &__social-container {
      width: 100%;
      margin-top: get-spacing-level(4);

      &:not(.email-capture__column-order) {
        margin: get-spacing-level(0);

        @media #{$tablet-up} {
          grid-column-start: 7;
          grid-column-end: 12;
        }
      }

      @media #{$tablet-up} {
        grid-column-start: 2;
        grid-column-end: 12;
      }
    }

    &__form {
      width: 100%;
      position: relative;
    }

    &__input {
      position: relative;
      margin-bottom: get-spacing-level(6);
      width: 100%;
      border-radius: $input-radius;
      background-color: $color-white;
      box-sizing: border-box;
      border: $border-light-outline;
      font-size: get-font-size-level(6);
      padding: get-spacing-level(3) get-spacing-level(4);
      color: $color-black;
      -webkit-appearance: none;

      @media #{$tablet-up} {
        align-self: flex-start;
        margin: 0 0 get-spacing-level(6);
        max-width: 22rem;
      }
    }

    &__label {
      position: absolute;
      pointer-events: none;
      left: 20px;
      top: 16px;
      transition: 0.2s ease all;
      color: $color-dark-gray-3;
      font-size: get-font-size-level(5);
    }

    input:focus ~ &__label,
    input:not(:placeholder-shown) ~ &__label {
      top: 4px;
      bottom: 10px;
      font-size: get-font-size-level(3);
      opacity: 1;
    }

    input:focus {
      border: 1px solid $color-black;
      outline: none;
    }

    &__error-color {
      color: $color-red;
    }

    &__error {
      margin: 0 get-spacing-level(12) get-spacing-level(8);
      text-align: center;
      font-size: get-font-size-level(4);

      @media #{$tablet-up} {
        margin: 0 0 get-spacing-level(8);
        align-self: flex-start;
      }
    }

    &__button {
      @media #{$tablet-up} {
        margin-left: 0;
      }
    }

    &__icon-container {
      @media screen and (min-width: 400px) and (max-width: 767px) {
        width: 55%;
        align-self: center;
      }

      @media #{$tablet-up} {
        width: 100%;
        align-self: flex-start;
        margin-top: get-spacing-level(10);
      }
    }

    &__social-icons {
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      padding-top: get-spacing-level(9);

      @media #{$tablet-up} {
        gap: get-spacing-level(10);
        padding: 0;
        justify-content: flex-start;
      }
    }

    &__sucesss-text {
      text-align: center;
      margin-bottom: get-spacing-level(4);
      font-size: get-font-size-level(4);

      @media #{$tablet-up} {
        text-align: left;
      }
    }
  }
</style>
