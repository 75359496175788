// https://image.nuxtjs.org/advanced/custom-provider/
// eslint-disable-next-line import/no-extraneous-dependencies
import { ProviderGetImage } from '@nuxt/image';
import { FCImageProviderTypes } from '~/utils/helpers.types';
import { getImage as getStoryblokImage } from './storyblokProvider';
import { getImage as getFCImage } from './defaultProvider';
import { IFCImageOptions } from './types';

const fcProviderHelpers: Record<FCImageProviderTypes, ProviderGetImage> = {
  [FCImageProviderTypes.fcProvider]: getFCImage,
  [FCImageProviderTypes.storyblok]: getStoryblokImage,
  [FCImageProviderTypes.shopify]: getFCImage,
};

/**
 * @description
 * FCImage provider, it should output an image url like so:
 * @example
 * {
 *   url: 'https://cdn3.joinfightcamp.com/500x500/filters:format(webp)/v4/fc-home-woman-punching-bag-001.png'
 * }
 */
export function getImage(src: string, imageOptions: IFCImageOptions) {
  const { modifiers } = imageOptions ?? {};
  const { fcProvider } = modifiers ?? {};

  const helperFunction = fcProvider ? fcProviderHelpers[fcProvider] : () => ({});

  return helperFunction(src, imageOptions);
}
