export default {
  title: 'FightCamp Trackers with Bag',
  id: 'fightcamp_trackers_with_bag',
  price: 49900,
  affirmPrice: 49900,
  oldPrice: null,
  monthly: 2079,
  months: 24,
  category: 'package',
  path: '/shop/products/fightcamp-trackers-bag/',
  internalPath: 'shop/products/fightcamp-trackers-bag/',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-fightcamp-base-with-bag-001.png',
  description: 'All the essentials, plus the bag',
  fbid: 'shopify_1924427087926_19085405028406',
  gmc_id: '2276347125729748',
};
