export interface ITimeoutOpts {
  cb: Function;
  min?: number;
  max?: number;
}

export interface IRecursiveTimeoutOpts extends ITimeoutOpts {
  conditionMet: () => boolean;
}

export enum FCImageProviderTypes {
  fcProvider = 'fcProvider',
  storyblok = 'storyblok',
  shopify = 'shopify',
}
