/* eslint-disable @typescript-eslint/no-shadow */
import products from '@/shared/datasets/variants';
import newStore from '@/shared/store/newStore';
import sharedActions from '@/shared/store/actions';
import * as shopify from '~/store/actions/shopify';
import sharedMutations from '@/shared/store/mutations';
import { MembershipIds } from '@/shared/constants/MembershipIds';

export const namespaced = false;

export const state = () => ({
  CURRENT_VERSION: 100,
  cart: [],
  cartSyncCompleted: false,
  cartCoupon: null,
  cartCouponError: null,
  cartUrlCouponMessage: null,
  cartDrawerOpen: false,

  amountoff: null,
  percentageoff: null,

  membershipTaxes: null,

  processingTotalCost: false,

  contact: null,
  shippingAddress: null,

  contactEmail: '',
  contactPhoneNumber: '',
  phoneNumberInternationalFormat: '',
  contactError: {
    message: '',
    element: '',
  },
  contactValid: {
    email: true,
    phoneNumber: true,
  },

  email: null,
  shippingInfo: null,

  shippingFirstName: '',
  shippingLastName: '',
  shippingAddress1: '',
  shippingAddress2: '',
  shippingCity: '',
  shippingPostalCode: '',
  shippingCountry: 'US',
  shippingState: '',
  shippingError: {
    message: '',
    element: '',
  },
  shippingValid: {
    firstName: true,
    lastName: true,
    address: true,
    city: true,
    state: true,
    postalCode: true,
    country: true,
  },

  shippingMethod: '',
  shippingMethodError: {
    message: '',
    element: '',
  },

  order: null,
  customerId: null,
  confirmation: null,
  new_customer: false,
  affirmCheckoutToken: null,
  stripeToken: null,
  affirmToken: null,
  paymentMethod: null,
  membershipType: {
    type: 'fightcampplanC',
    key: 'FightCamp_1_MonthC',
    name: 'Monthly',
  },
  currentUser: null,
  userToken: null,
  dealItems: [],
  accessoryCoupons: {
    EXTRA50: 14900,
    EXTRA20: 7500,
    GEARUP30: 3000,
    GEARUP75: 7500,
    GEARUP150: 15000,
  },
  paymentIntentId: null,
  draftOrderId: null,
  checkoutId: null,
  setupIntentId: null,
  shopifyCheckoutEmail: '',
  currentCheckoutTab: '',
  ...newStore.state,
});

export const mutations = {
  ...sharedMutations,
  ...newStore.mutations,
  ...shopify.mutations,
  setOrderIds(state, { draftOrderId, paymentIntentId }) {
    state.paymentIntentId = paymentIntentId;
    state.draftOrderId = draftOrderId;
  },
  setCheckoutId(state, id) {
    state.checkoutId = id;
  },
  setSetupIntentId(state, setupIntentId) {
    state.setupIntentId = setupIntentId;
  },
  clearShipping(state) {
    state.shipping = {
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      phoneNumber: '',
      country: '',
    };
  },
  setPhoneNumberInternationalFormat(state, number) {
    state.phoneNumberInternationalFormat = number;
  },
  clearStoreItems(state) {
    state.paymentIntentId = null;
    state.draftOrderId = null;
    state.checkoutId = null;
    state.setupIntentId = null;
  },
  setFinalCostBreakdown(state, costBreakdown) {
    state.order.costBreakdown = costBreakdown;
  },
};

export const actions = {
  ...sharedActions,
  ...newStore.actions,
  ...shopify.actions,
};

export const getters = {
  ...newStore.getters,
  ...shopify.shopifyGetters,
  CURRENT_VERSION: state => state.CURRENT_VERSION,

  /**
   *  Cart Checkout
   */
  cart: state => state.cart,
  cartSyncCompleted: state => state.cartSyncCompleted,
  cartCoupon: state => state.cartCoupon,
  cartCouponError: state => state.cartCouponError,
  cartUrlCouponMessage: state => state.cartUrlCouponMessage,
  cartDrawerOpen: state => state.cartDrawerOpen,

  /**
   *  Checkout
   */
  currentUser: state => state.currentUser,
  shippingAddress: state => state.shippingAddress,
  contact: state => state.contact,
  contactEmail: state => state.contactEmail.toLowerCase() || state.checkoutEmail.toLowerCase(),
  processingTotalCost: state => state.processingTotalCost,
  stripeToken: state => state.stripeToken,
  amountoff: state => state.amountoff,
  order: state => state.order,
  confirmation: state => state.confirmation,
  affirmCheckoutToken: state => state.affirmCheckoutToken,
  paymentMethod: state => state.paymentMethod,
  affirmToken: state => state.affirmToken,
  initStarted: state => state.initStarted,
  contactError: state => state.contactError,
  contactValid: state => state.contactValid,
  shippingError: state => state.shippingError,
  shippingValid: state => state.shippingValid,
  shippingMethod: state => state.shippingMethod,
  shippingMethodError: state => state.shippingMethodError,
  membershipTaxes: state => state.membershipTaxes,
  getPayemntIntentId: state => state.paymentIntentId,
  getCheckoutId: state => state.checkoutId,
  shipping: state => ({
    email: state.contactEmail.toLowerCase(),
    phoneNumber: state.contactPhoneNumber,

    firstName: state.shippingFirstName,
    lastName: state.shippingLastName,
    address: state.shippingAddress1,
    address2: state.shippingAddress2,
    city: state.shippingCity,
    state: state.shippingState,
    postalCode: state.shippingPostalCode,
    country: state.shippingCountry,
  }),
  // added this here since above shipping does not contain shipping data and did not want to mess with it incase control uses it
  shopifyShipping: (state, getters) => ({
    ...state.shipping,
    email: getters.contactEmail.toLowerCase(),
    phoneNumber: state.phoneNumberInternationalFormat,
  }),
  cartqty: state => state.cart.filter(i => i.type !== 'subs_only').reduce((acc, i) => acc + i.qty, 0),
  accessoryCoupons: state => state.accessoryCoupons,
  canApplyAccessoryCoupon: (state, getters) => (coupon) => {
    const isAccessoryCoupon = Object.keys(state.accessoryCoupons).includes(coupon);
    return isAccessoryCoupon && getters.accessoryTotal > 0;
  },
  accessoryTotal: state => state.cart.reduce((acc, item) => {
    const accessories = [
      'quickWraps_V2_S',
      'quickWraps_V2_L',
      'quickWraps_3p_SSS',
      'quickWraps_3p_LLL',
      'quickWraps_3p_SSL',
      'quickWraps_3p_LLS',
      'customGloves_V2_K',
      'customGloves_V2_S',
      'customGloves_V2_L',
      'fightcamp_care_kit',
      'fightcamp_conditioning_kit',
      'fightcamp_bag_ring',
      'fightcamp_traditional_wraps',
      'fightcamp_endurance_kit',
    ];

    const accessory = accessories.find(accessoryName => accessoryName === item.id);

    if (accessory) {
      const accessoryObject = products.find(product => product.id === accessory);
      const amountToAdd = accessoryObject.price * item.qty;
      return acc + amountToAdd;
    }
    return acc;
  }, 0),
  bundle: (state, getters) => state.cart.reduce((acc, item) => {
    if (getters.isShopifyOn && !getters.isGQOrder) {
      const product = getters.cartProducts.shopifyItems.find(product => product.variant.id === item.id);
      if (!product) {
        return acc;
      }
      const updatedProduct = {
        id: product.id,
        title: product.title,
        price: product.variant.price, // in cents
        category: product.productType,
        qty: item.qty,
        type: item.type,
        variantId: item.id,
      };
      acc.push(updatedProduct);
    } else {
      const desc = products.find(product => item.id === product.id);
      if (!desc) {
        return acc;
      }
      const updatedProduct = {
        ...desc,
        qty: item.qty,
        type: item.type,
        id: item.id,
      };
      acc.push(updatedProduct);
    }

    return acc;
  }, []),

  hasRestrictedItemsInCart: (_, getters) => getters.bundle.find(item => item.shippingRestricted),
  shippingRestricted: (state, getters) => (getters.hasRestrictedItemsInCart !== undefined) && (state.shippingState === 'AK' || state.shippingState === 'HI'),
  checkFieldsNotFilled: (state) => {
    if (state.shippingFirstName === '') {
      return 'shippingFirstName';
    }
    if (state.shippingLastName === '') {
      return 'shippingLastName';
    }
    if (state.shippingAddress1 === '') {
      return 'shippingAddress1';
    }
    if (state.shippingCity === '') {
      return 'shippingCity';
    }
    if (state.shippingPostalCode === '') {
      return 'shippingPostalCode';
    }
    if (state.shippingCountry === '') {
      return 'shippingCountry';
    }
    if (state.shippingState === '') {
      return 'shippingState';
    }
    return null;
  },
  isShippingFilled: (_, getters) => (getters.shipping.firstName
    && getters.shipping.lastName
    && getters.shipping.address
    && getters.shipping.city
    && getters.shipping.postalCode
    && getters.shipping.country
    && getters.shipping.state),

  isContactFilled: (_, getters) => (getters.shipping.phoneNumber && getters.shipping.email),

  summary_subtotal: (state, getters) => {
    if (getters.isShopifyOn && !getters.isGQOrder) {
      if (!state.order) {
        return getters.cartProducts.shopifyItems.reduce((acc, item) => acc + item.variant.price * item.variant.qty, 0);
      }
      return state.order.costBreakdown.productCost;
    }

    let subtotal = 0;
    if (state.order != null) {
      return state.order.costBreakdown.productCost;
    }
    getters.bundle.forEach((item) => {
      subtotal += item.qty * item.price;
    });
    return subtotal;
  },
  summary_tax: (state) => {
    if (state.order) {
      return state.order.costBreakdown.productTax;
    }
    return null;
  },
  summary_shipping: (state) => {
    if (state.order) {
      return state.order.costBreakdown?.productShipping;
    }
    return null;
  },
  summary_coupon: (state, getters) => {
    if (state.order) {
      return state.order.costBreakdown.discountAmount;
    }
    if (state.amountoff) {
      return state.amountoff;
    }
    if (state.percentageoff) {
      return -1 * getters.summary_subtotal * state.percentageoff / 100;
    }
    return null;
  },
  summary_total: (state, getters) => {
    if (state.order) {
      return state.order.costBreakdown.totalCost;
    }
    if (state.percentageoff) {
      return getters.summary_subtotal - getters.summary_subtotal * state.percentageoff / 100;
    }
    return getters.summary_subtotal - getters.amountoff;
  },
  summaryDuties: (state) => {
    if (state.order) {
      return state.order.totalDuties;
    }
    return null;
  },
  summaryTotalAffirm: (state, getters) => {
    if (!getters.isSubsInCart) {
      return getters.summary_total;
    }

    if (getters.isSubsInCart && state.order && state.paymentMethod === 'stripe') {
      return state.order.costBreakdown.totalCost + 93600;
    }

    if (state.paymentMethod === 'affirm') {
      return getters.summary_total;
    }

    return getters.summary_subtotal - getters.amountoff + 93600;
  },
  isSubsInCart: (state, getters) => {
    if (getters.isShopifyOn && !getters.isGQOrder) {
      return getters.membershipInCart;
    }
    let found;
    found = state.cart.find(item => item.type === 'subs' || item.type === 'subs_only');

    if (!found && getters.confirmation) {
      found = getters.confirmation.cart.find(item => item.type === 'subs' || item.type === 'subs_only');
    }

    return !!found;
  },
  isEquipInCart: (state) => {
    const found = state.cart.find(item => item.type === 'equip');
    return !!found;
  },
  packageInCart: (state, getters) => {
    if (getters.isShopifyOn && !getters.isGQOrder) {
      return getters.cartProducts.packageInCart;
    }
    const found = state.cart.find((item) => {
      const desc = products.find(product => item.id === product.id);
      return desc.subscriptionNeeded === true;
    });
    return found;
  },
  packageInCartName: (state) => {
    const productInCart = products.find(product => state.cart.find(item => item.id === product.id && product.subscriptionNeeded));

    return productInCart ? productInCart.productName : null;
  },
  isConnect: (_, getters) => getters.bundle.find(item => (['connect_V2_L', 'connect_V2_S'].includes(item.id))),
  orderID: (state) => {
    if (state.order === null) {
      return null;
    }
    return state.order.orderID;
  },
  hasPackageWithBag: (_, getters) => getters.bundle.some(product => product.items && product.items.find(item => item.name === 'Free-Standing Bag' || item.name === 'Boxing Bag')),
  isFreeStandingBag: (_, getters) => getters.bundle.some(product => product.id === 'fightcamp_bag_bag_ring_bundle' || product.id === 'freeStandingBag_V2' || product.id === 'freeStandingBag_V3'),
  hasSecondhandSubOnly: (_, getters) => getters.bundle.some(product => product.id === MembershipIds.TRANSFERRED && getters.bundle.length === 1),
  isGQOrder: (state) => {
    const gqItemInCart = state.cart.find(item => item.id === 'gq_fightcamp_trackers_S' || item.id === 'gq_fightcamp_trackers_L' || item.id === 'pt_fightcamp_trackers_S' || item.id === 'pt_fightcamp_trackers_L');
    return !!gqItemInCart;
  },
  shopifyCheckoutEmail: (state) => state.shopifyCheckoutEmail,
  isEligibleGen2Upgrade: (state) => !!state.order?.costBreakdown && state.order?.costBreakdown.couponName === 'UPGRADE1TO2G7X9Z4' && state.order?.costBreakdown.discountAmount,
  includesConsole: (_, getters) => getters.cartProducts.packageInCart?.product.tags.includes('includesConsole') ?? false,
  isGen2UpgradeInCart: (state) => {
    const devIds = [
      'gid://shopify/ProductVariant/41604714594393',
      'gid://shopify/ProductVariant/41604714561625',
      'gid://shopify/ProductVariant/41959638270041',
      'gid://shopify/ProductVariant/41959638302809',
    ];
    const prodIds = [
      'gid://shopify/ProductVariant/45869987791084',
      'gid://shopify/ProductVariant/45869987823852',
      'gid://shopify/ProductVariant/45413115756780',
      'gid://shopify/ProductVariant/45413115789548',
    ];

    if (process.env.NUXT_ENV_IS_DEV === 'true') {
      return state.cart.some(item => devIds.includes(item.id));
    }

    return state.cart.some(item => prodIds.includes(item.id));
  },
  // isNotInfoCheckoutTab used for Gen1 to Gen2 Upgrade, prevents user from remove coupon in payment and review tabs
  isNotInfoCheckoutTab: (state) => state.currentCheckoutTab.id === 'review' || state.currentCheckoutTab.id === 'payment',
  customerId: (state) => state.customerId,
};
