const attribution = require('./utils/attribution');
const otherOption = require('./utils/other');
const prefix = 'Television Ad'

const televisionAd = {
  id: `${prefix}`,
  question: 'Which ad did you see?',
  type: 'list',
  options: [
    {
      id: `${prefix} Built Different`,
      label: 'Built Different',
      image: 'https://cdn3.joinfightcamp.com/v4/fc-hdyhau-built-different-002.jpg',
      attribution: attribution.Television
    },
    {
      id: `${prefix} Family Holiday`,
      label: 'Family Holiday',
      image: 'https://cdn3.joinfightcamp.com/v4/fc-hdyhau-family-holiday-002.jpg',
      attribution: attribution.Television
    },
    {
      id: `${prefix} Family New Years 2020`,
      label: 'Family New Years 2020',
      image: 'https://cdn3.joinfightcamp.com/v4/fc-hdyhau-family-new-years-2020-002.jpg',
      attribution: attribution.Television
    },
    {
      id: `${prefix} Deck The Halls`,
      label: 'Deck The Halls',
      image: 'https://cdn3.joinfightcamp.com/v4/fc-hdyhaud-eck-the-halls-002.jpg',
      attribution: attribution.Television
    },
    {
      id: `${prefix} Anthem 30`,
      label: 'Anthem 30',
      image: 'https://cdn3.joinfightcamp.com/v4/fc-hdyhau-anthem-30-001.png',
      attribution: attribution.Television
    },
    {
      id: `${prefix} Wonderboy`,
      label: 'Wonderboy',
      image: 'https://cdn3.joinfightcamp.com/v4/fc-hdyhau-wonderboy-001.png',
      attribution: attribution.Television
    },
    {
      id: `${prefix} Guided Tutorials`,
      label: 'Guided Tutorials',
      image: 'https://cdn3.joinfightcamp.com/v4/fc-hdyhau-guided-tutorials-001.png',
      attribution: attribution.Television
    },
    {
      id: `${prefix} Guided Workouts`,
      label: 'Guided Workouts',
      image: 'https://cdn3.joinfightcamp.com/v4/fc-hdyhau-guided-workouts-001.png',
      attribution: attribution.Television
    },
    otherOption({ id: 'Television Other', attribution: attribution.Television }),
  ]
};

const Television = {
  id: 'Television',
  type: 'input',
  question: 'Which network did you see it on?',
  followup: televisionAd,
};

module.exports = Television;
