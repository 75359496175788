




























import {
  computed, defineComponent, PropType,
} from '@vue/composition-api';
// https://fightcamp.atlassian.net/browse/FLX-1221
// TODO: Reconcile ValidColors with BackgroundTheme global
import { ValidColors } from './types';

export default defineComponent({
  name: 'CollapsiblePreHeader',
  props: {
    id: {
      type: String,
      default: '',
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
      required: true,
    },
    bgColor: {
      type: String as PropType<ValidColors>,
      default: 'red',
      required: false,
    },
    textColor: {
      type: String as PropType<ValidColors>,
      default: 'white',
      required: false,
    },
  },
  setup(props, context) {
    function scrollDown(): void {
      context.emit('onScrollDown');
    }

    function toggleCollapsible(): void {
      context.emit('update:active', !props.active);
    }

    return {
      scrollDown,
      toggleCollapsible,
      getTextColor: computed<string>(() => `fc-text-${props.textColor}`),
      getCollapsiblePreHeaderClasses: computed<string[]>(() => {
        const classes = [];

        if (props.active) {
          classes.push('collapsible-preheader--active');
        }

        classes.push(`fc-bg-${props.bgColor}`);
        classes.push(`fc-text-${props.textColor}`);

        return classes;
      }),
    };
  },
});
