const attribution = require('./utils/attribution');
const otherOption = require('./utils/other');

const pinterestShare = {
  id: 'Pinterest Share',
  question: 'What was it?',
  type: 'list',
  options: [
    {
      id: 'Pinterst Share Pin',
      label: "A Pin from FightCamp's profile",
      attribution: attribution.UTM,
    },
    otherOption({ id: 'Other', attribution: attribution.Unknown }),
  ],
};

const pinterestInfluencer = {
  id: 'Pinterest Influencer',
  question: 'Who was it?',
  type: 'input',
  attribution: attribution.UTM,
};

const pinterestPost = {
  id: 'Pinterest Post',
  question: 'Who was it?',
  type: 'list',
  options: [
    {
      id: 'Pinterest Someone Known',
      label: "Someone you know personally",
      attribution: attribution.Referral,
    },
    {
      id: 'Pinterest Influencer Known',
      label: "Content Creator / Celebrity / Influencer you follow",
      followup: pinterestInfluencer,
      attribution: attribution.UTM,
    },
    otherOption({ id: 'Other', attribution: attribution.Unknown }),
  ],
};

const pinterestTag = {
  id: 'Pinterest Tag',
  question: 'What was it?',
  type: 'list',
  options: [
    {
      id: 'Pinterest FC Pin',
      label: "A Pin from FightCamp's Profile",
      attribution: attribution.UTM,
    },
    {
      id: 'Pinterest About FC Pin',
      label: "A Pin from someone about FightCamp",
      attribution: attribution.Referral,
    },
    otherOption({ id: 'Other', attribution: attribution.Unknown }),
  ],
};

const pinterestAd = {
  id: 'Pinterest Ad',
  question: 'What was in the ad?',
  type: 'input',
  attribution: attribution.Unknown,
};

const pinterest = {
  id: 'Pinterest',
  question: 'What did you see on Pinterest?',
  type: 'list',
  options: [
    {
      id: 'Pinterest Ad',
      label: 'Advertisement',
      followup: pinterestAd,
      attribution: attribution.Unknown,
    },
    {
      id: 'Pinterest Share',
      label: 'Someone shared something with you',
      followup: pinterestShare,
      attribution: attribution.Referral,
    },
    {
      id: 'Pinterest Post',
      label: 'Someone posted about FightCamp',
      followup: pinterestPost,
      attribution: attribution.UTM,
    },
    {
      id: 'Pinterest Tag',
      label: 'Someone tagged you in something',
      followup: pinterestTag,
      attribution: attribution.UTM,
    },
    otherOption({ id: 'Pinterest Other', attribution: attribution.Unknown }),
  ],
};

module.exports = pinterest;
