export const fightcampWeightedQuickWraps = {
  title: 'Weighted Quick Wraps',
  id: 'fightcamp_weighted_quick_wraps',
  price: 3900,
  category: 'accessory',
  description: 'Add a new challenge to your boxing or shadow boxing workout with 2.5 lb weighted quick wraps.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-weighted-quickwraps-001.png',
  tag: 'Used In Workouts',
};
