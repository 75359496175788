import { Store } from 'vuex';
import storeMutations from '~/store/constants/storeMutations';

interface GoogleMapsService {
  getCountry: (lat: number, lng: number) => Promise<string>;
}

export function runGeoLocationEffect(store: Store<any>, googleMapsService: GoogleMapsService, navigator: Navigator): void {
  if (!navigator) {
    return;
  }

  navigator.geolocation.getCurrentPosition(async (pos) => {
    const country = await googleMapsService.getCountry(pos.coords.latitude, pos.coords.longitude);
    store.commit(storeMutations.SET_GEOLOCATION_COUNTRY, country);
  });
}
