<template>
  <div
    class="checkbox">
    <input
      :id="id"
      type="checkbox"
      class="fc-screen-reader-only"
      :name="name"
      :value="checked"
      @input="toggleChecked()">
    <div
      class="checkbox__display"
      :class="checkBoxClass"
      :style="styles"
      @click="toggleChecked()">
      <div v-if="checked" class="checkbox__check-mark fc-flex fc-align-center fc-justify-center">
        <font-awesome-icon :icon="['fc', 'check-mark']" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from '@vue/composition-api';
  import { CheckboxProps } from './types';

  export default defineComponent({
    name: 'Checkbox',
    props: {
      id: {
        type: String,
        required: false,
        default: '',
      },
      name: {
        type: String,
        required: false,
        default: '',
      },
      value: {
        type: Boolean,
        required: false,
        default: false,
      },
      height: {
        type: String,
        required: false,
        default: '1.5rem',
      },
      width: {
        type: String,
        required: false,
        default: '1.5rem',
      },
    },
    setup(props: CheckboxProps, context) {
      const checked = computed<boolean>(() => !!props.value);

      return {
        checked,
        checkBoxClass: computed<string[]>(() => {
          const classes = [];

          if (checked.value) {
            classes.push('checked');
          }

          return classes;
        }),
        toggleChecked() {
          context.emit('input', !checked.value);
        },
        styles: computed(() => ({
          width: props.width,
          height: props.height,
        })),
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import "../../styles/exports";

  .checkbox {
    position: relative;

    &__display {
      box-sizing: content-box;
      border: 2px solid $color-black;
      border-radius: 100%;
      overflow: hidden;
      cursor: pointer;

      &.checked {
        border-color: $color-red;
        background: $color-red;
      }

      input[type="checkbox"]:focus + & {
        box-shadow: 0 0 4px $color-red-tint;
      }
    }

    &__check-mark {
      width: 100%;
      height: 100%;
      background: $color-red;

      svg path {
        fill: $color-white;
        stroke: $color-white;
      }
    }
  }
</style>
