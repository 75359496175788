// eslint-disable-next-line no-unused-vars
let authToken = '';
let userID = '';

let fightCampBackendLink = process.env.VUE_APP_API_URL;

const axios = require('axios');
const sessionData = require('../utils/sessionData');

export default {
  init(token, uid, apiLink) {
    authToken = token;
    userID = uid;
    fightCampBackendLink = apiLink;
  },

  calculateTotalCost(shippingInfo, email, productList, couponCode = null, membershipType = 'fightcampplanC', isShippingVariant = false) {
    const request = {
      productList,
      order: {
        currency: 'usd',
        email,
        items: [],
        shipping: {
          name: `${shippingInfo.firstName} ${shippingInfo.lastName}`,
          ...(shippingInfo.phoneNumber && {phone: shippingInfo.phoneNumber}),
          address: {
            line1: shippingInfo.address,
            line2: shippingInfo.address2,
            city: shippingInfo.city,
            state: shippingInfo.state,
            country: shippingInfo.country,
            postal_code: shippingInfo.postalCode,
          },
        },
      },
      membershipType,
      isShippingVariant,
    };

    if (couponCode) {
      request.order.coupon = couponCode;
    }

    return Promise.resolve()
      .then(() => axios.post(
        `${fightCampBackendLink}/calculateTotalCost`,
        JSON.stringify(request),
        {
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
        },
      )).then((responseJson) => {
        if (responseJson.data.costBreakdown) {
          return responseJson.data;
        }
        throw new Error(responseJson.data.message);
      }).catch((err) => {
        if (err.response.data.message) {
          console.log(err.response.data.message);
          throw new Error(err.response.data.message);
        } else {
          console.log(err);
          throw new Error(err);
        }
      });
  },


  authorizeAffirm(stripeOrderId, affirmToken, isPOS = false) {
    const request = {
      stripeOrderId,
      affirmToken,
      isPOS,
    };

    return Promise.resolve()
      .then(() => axios.post(`${fightCampBackendLink}/authorizeAffirm`,
        JSON.stringify(request), {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
      })).then((responseJson) => {
        if (responseJson.data.code) {
          throw new Error("Could not reach our servers! Don't worry, we'll be back soon");
        }

        return responseJson.data;
      }).catch((err) => {
        if (err.response.data.message) {
          console.log(err.response.data.message);
          throw new Error(err.response.data.message);
        } else {
          console.log(err);
          throw new Error(err);
        }
      });
  },

  createCustomer(stripeToken, email, shippingInfo) {
    const shipRequest = {
      name: `${shippingInfo.firstName} ${shippingInfo.lastName}`,
      phone: shippingInfo.phoneNumber,
      address: {
        line1: shippingInfo.address,
        line2: shippingInfo.address2,
        city: shippingInfo.city,
        state: shippingInfo.state,
        country: shippingInfo.country,
        postal_code: shippingInfo.postalCode,
      },
    };

    const request = {
      userID,
      stripeToken,
      email,
      shippingInfo: shipRequest,
    };

    return Promise.resolve()
      .then(() => axios.post(`${fightCampBackendLink}/createCustomer`, JSON.stringify(request), {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
      })).then((responseJson) => {
        if (responseJson.data.id) {
          return responseJson.data;
        }

        throw new Error(responseJson.data.message);
      }).catch((err) => {
        console.log(err);
        if (err.response.data.message) {
          console.log(err.response.data.message);
          if (err.response.data.message === 'Something went wrong, missing parameters') {
            throw new Error('Something went wrong, please check your email and credit card and try again');
          } else {
            throw new Error(err.response.data.message)
          }
        } else {
          console.log(err);
          throw new Error(err);
        }
      });
  },

  placeOrder({ orderId, productList, customerId, email, shipInfo, costBreakdown, affirmLoanId = null, uid, fulfillmentType, options = {}, newCustomer }) {
    const request = {
      orderId,
      productList,
      customerId,
      email,
      shippingInfo: shipInfo,
      costBreakdown,
      affirmLoanId,
      uid,
      options,
      fulfillmentType,
      sessionData: sessionData.get(),
      newCustomer,
    };
    return Promise.resolve()
      .then(() => axios.post(`${fightCampBackendLink}/chargeOrderAndSubscribe`, JSON.stringify(request), {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
      })).then((responseJson) => {
        if (responseJson.data.code == null) {
          return responseJson.data;
        }
        console.log('Weird Error');
        // throw new Error(responseJson);
        throw new Error("Could not reach our servers! Don't worry, we'll be back soon");
      }).catch((err) => {
        console.log(err);
        if (err.response.data.message) {
          console.log(err.response.data.message);
          throw new Error(err.response.data.message);
        } else {
          console.log(err);
          throw new Error(err);
        }
      });
  },

  textApp(textPhoneNumber, url = null) {
    const request = {
      phoneNumber: textPhoneNumber,
      userUID: userID,
      url
    };
    return axios.post('/api/sms/messages/install-app', JSON.stringify(request), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },

  textTryApp(textPhoneNumber) {
    const request = {
      phoneNumber: textPhoneNumber,
      userUID: userID,
    };
    return axios.post('/api/sms/messages/try-the-app', JSON.stringify(request), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },

  sendAmplitudeAnalytic(prop) {
    const request = {
      event: 'Post purchase',
      prop,
    };
    return axios.post(`${fightCampBackendLink}/analytics/clicks`, JSON.stringify(request), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },

  validateCoupon(couponCode, prodList, contactEmail) {
    const request = {
      couponCode,
      products: prodList,
      email: contactEmail,
    };
    const response = {
      couponValid: null,
      percentageOff: null,
      amountOff: null,
      description: null,
      error: null,
    };

    return axios.post(`${fightCampBackendLink}/validateCoupon`, JSON.stringify(request), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    })
      .then((couponResponse) => {
        response.couponValid = couponResponse.data.valid;
        response.percentageOff = couponResponse.data.percent_off;
        response.amountOff = couponResponse.data.amount_off;
        response.description = couponResponse.data.name;
        return response;
      })
      .catch((err) => {
        console.log(err);
        response.couponValid = false;
        response.error = err.response.data.message;
        return response;
      });
  },

  validateEmail(emailCheck) {
    const request = {
      email: emailCheck,
    };
    const response = {
      emailValid: null,
    };
    return axios.post(`${fightCampBackendLink}/validateEmail`, JSON.stringify(request), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    })
      .then((couponResponse) => {
        response.emailValid = couponResponse.data.valid;
        return response;
      })
      .catch((err) => {
        response.emailValid = false;
        return response;
      });
  },

  heardAboutUs(email, orderId, heardText, salesRep = null, followup = null, workoutPreference = null) {
    const request = {
      email,
      orderId,
      heardText,
      salesRep,
      followup,
      workoutPreference,
    };
    return axios.post(`${fightCampBackendLink}/HeardAboutUs`, JSON.stringify(request), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    })
      .then(() => true)
      .catch(() => false);
  },

  getTaxesForOrder(shippingInfo, membership) {
    const request = { 
      shippingInfo: {
        email: shippingInfo.email,
        address: shippingInfo.address,
        ...(shippingInfo.address2 && { address2: shippingInfo.address2 }),
        city: shippingInfo.city,
        state: shippingInfo.state,
        postalCode: shippingInfo.postalCode,
        country: shippingInfo.country,
        firstName: shippingInfo.firstName,
        lastName: shippingInfo.lastName,
        ...(shippingInfo.phoneNumber && { phoneNumber: shippingInfo.phoneNumber }), 
      },
      membershipType: membership.type 
    };

    return axios.post(`${fightCampBackendLink}/getTaxesForOrder`, JSON.stringify(request), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    })
      .catch(() => false);
  },

  upgradeMembership( {customerId, errorEventTracker, type }) {
    return axios.post('/.netlify/functions/upgradeMembership', { customerId, origin: 'Web Post Checkout', type }, {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'x-api-key': process.env.NUXT_ENV_UPGRADE_MEMBERSHIP_API_KEY,
      },
    })
    .catch(err => { 
      // Stripe error
      if (err.response && err.response.data) {
        errorEventTracker({caller: 'upgradeMembership api_client', error: err.response})
        throw new Error(err.response.data.message)
      }
      // Internal error 
      errorEventTracker({caller: 'upgradeMembership api_client', error: err})
      throw new Error("Oops,something went wrong!");
    });
  },

  testChargePreAffirm(customerId, email) {
    const request = {
      customerId,
      email,
    };

    return Promise.resolve()
      .then(() => axios.post(`${fightCampBackendLink}/testChargePreAffirm`, JSON.stringify(request), {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
      }))
      .then((responseJson) => {
        if (responseJson.data.code == null) {
          return responseJson.data;
        }
        console.log('Weird Error');
        // throw new Error(responseJson);
        throw new Error("Could not reach our servers! Don't worry, we'll be back soon");
      })
      .catch((err) => {
        if (err.response.data.message) {
          console.log(err.response.data.message);
          throw new Error(err.response.data.message);
        } else {
          console.log(err);
          throw new Error(err);
        }
      });
  },
};
