export default {
  title: 'Free Standing Bag',
  id: 'freeStandingBag_V2',
  price: 39900,
  monthly: 3325,
  months: 12,
  category: 'accessory',
  description: '',
  img_url: 'https://a.storyblok.com/f/152150/1000x1000/7da74119d4/bag_whitebackground.jpg',
  types:['Setup']
};
