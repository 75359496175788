import {
 onBeforeUnmount, Ref, ref, SetupContext, watchEffect,
} from '@vue/composition-api';
import { NavigationProps } from '../types';

export function useNavigationTimerAnimator(props: NavigationProps, context: SetupContext): Ref<HTMLElement | undefined> {
  const timerContainer = ref<HTMLElement>();
  let animationId: number;
  let initialTime = Date.now();
  let prevSlide = props.currentSlide;

  onBeforeUnmount(resetTimer);

  watchEffect((onInvalidate) => {
    if (!global.requestAnimationFrame) {
      return;
    }

    if (props.timerEnabled) {
      animationId = global.requestAnimationFrame(moveTimer);
    } else {
      resetTimer();
    }

    if (prevSlide > props.currentSlide) {
      backtrackNavigators();
      animationId = global.requestAnimationFrame(moveTimer);
    } else {
      prevSlide = props.currentSlide;
    }

    onInvalidate(resetTimer);
  });

  function moveTimer() {
    if (!props.timerEnabled || !global.requestAnimationFrame) {
      return;
    }

    const now = Date.now();
    const timePassed = now - initialTime;
    const translatePercentage = (timePassed / props.timerLimit!) * 100;
    const element = getNavigationHTMLElement(props.currentSlide);

    if (timePassed >= props.timerLimit!) {
      resetTimer();
      context.emit('slideSelect', (props.currentSlide + 1) % props.slides.length);
      animationId = global.requestAnimationFrame(moveTimer);
    } else if (element) {
      element.style.transform = `translateX(${translatePercentage}%)`;
      animationId = global.requestAnimationFrame(moveTimer);
    }
  }

  function backtrackNavigators() {
    if (!timerContainer.value) {
      return;
    }

    for (let index = props.currentSlide; index < timerContainer.value.children.length; index++) {
      const element = getNavigationHTMLElement(index);

      if (element) {
        element.removeAttribute('style');
      }
    }

    resetTimer();
    prevSlide = props.currentSlide;
  }

  function resetTimer() {
    if (global.cancelAnimationFrame) {
      global.cancelAnimationFrame(animationId);
    }

    const element = getNavigationHTMLElement(props.currentSlide);

    if (element) {
      initialTime = Date.now();
      element.style.transform = 'translateX(0%)';
    }
  }

  function getNavigationHTMLElement(index: number): HTMLElement | undefined {
    const listItem = timerContainer.value && (timerContainer.value.children[index] as HTMLElement);
    const collection = listItem && listItem.getElementsByClassName('navigation__timer');

    return collection && collection.item(0) as HTMLElement;
  }

  return timerContainer;
}
