/* eslint-disable */

const EMAIL_VALIDATOR = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
const PHONE_NUMBER_VALIDATOR = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const US_POSTAL_CODE_VALIDATOR = /^\d{5}(?:[-\s]\d{4})?$/;
const CA_POSTAL_CODE_VALIDATOR = /([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i;

export {
  EMAIL_VALIDATOR,
  PHONE_NUMBER_VALIDATOR,
  US_POSTAL_CODE_VALIDATOR,
  CA_POSTAL_CODE_VALIDATOR
};
