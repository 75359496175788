const attribution = require('./utils/attribution');
const youtubeChannel = require('./utils/channel');
const fightCampChannel = require('./utils/fightCampChannel');
const unknownChannel = require('./utils/unknownChannel');

const youtubeAd = {
  id: 'YouTube Ad',
  question: 'What was in the ad?',
  type: 'input',
};

const youtube = {
  id: 'Youtube',
  type: 'select',
  question: 'What did you see on Youtube?',
  options: [{
      id: 'Youtube Ad',
      label: 'Youtube Ad',
      followup: youtubeAd,
      attribution: attribution.PaidYoutube,
    }, 
    {
      id: 'FightCamp Youtube Channel',
      label: 'Video from FightCamp Channel',
      attribution: attribution.UTM,
      followup: fightCampChannel('Youtube Channel'),
    },
    {
      id: 'Youtube Channel',
      label: 'Video from Another Channel',
      attribution: attribution.UTM,
      followup: youtubeChannel('Youtube Channel'),
    },
    {
      id: 'Youtube Channel',
      label: 'Not Sure',
      attribution: attribution.Unknown,
      followup: unknownChannel('Youtube Channel'),
    },
  ],
};

module.exports = youtube;
