// eslint-disable-next-line import/no-extraneous-dependencies
import { Context, Plugin } from '@nuxt/types';
import { createGrowthbookInstance } from '~/services/GrowthBookService';

const growthbookPlugin: Plugin = (context: Context): void => {
  window.analytics.ready(async () => {
    await createGrowthbookInstance(context.store);
  });
};

export default growthbookPlugin;
