export default {
  title: 'FightCamp Kid Gloves',
  id: 'customGloves_V2_K',
  price: 4900,
  category: 'accessory',
  description: '6 oz gloves are designed for kids aged 12 and under. Get the whole family moving.',
  fbid: 'shopify_1948387803190_19346090229814',
  path: '/shop/equipment',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-equipment-kidgloves-001.jpg',
  marketplace_img_url: '/v4/fc-marketplace-accessory-kids-gloves-001.png',
  types: ['Essentials'],
};
