
















import { defineComponent, computed, PropType } from '@vue/composition-api';
import { ButtonSizes, ButtonAlignment, ButtonThemes } from './types';

export const BUTTON_TEXT_SIZES: ButtonSizes = {
  small: 'button-small',
  big: 'button-med',
  default: '',
};

export const BUTTON_ALIGNMENT: ButtonAlignment = {
  left: 'fc-button--left',
  center: '',
  right: 'fc-button--right',
};

export default defineComponent({
  name: 'FCButton',
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
    buttonTheme: {
      type: String as PropType<ButtonThemes>,
      required: false,
      default: 'button-primary',
    },
    customEventName: {
      type: String,
      required: true,
    },
    buttonSize: {
      type: String,
      required: false,
      default: 'default',
    },
    buttonAlignment: {
      type: String,
      required: false,
      default: 'left',
    },
    ariaLabel: {
      type: String,
      required: false,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, context) {
    const isDisabled = computed(() => props.buttonTheme === 'button-disabled' || props.loading);

    const themeClass = computed(() => {
      if (props.buttonTheme === 'button-text') return props.buttonTheme;
      if (isDisabled.value) return `button-layout button-disabled ${BUTTON_TEXT_SIZES[props.buttonSize as keyof ButtonSizes]} fc-button--disabled`;

      return `button-layout ${props.buttonTheme} ${BUTTON_TEXT_SIZES[props.buttonSize as keyof ButtonSizes]}`;
    });

    return {
      emitCustomEvent() {
        context.emit(props.customEventName);
      },
      isDisabled,
      getContainerClass: computed(() => (props.fullWidth ? 'fc-block' : 'fc-flex')),
      widthClass: computed(() => (props.fullWidth ? 'fc-button--full-width' : (BUTTON_ALIGNMENT)[props.buttonAlignment as keyof ButtonAlignment])),
      themeClass,
    };
  },
});
