<template>
  <div class="fc-container">
    <div class="features__toggle-container fc-flex-row-center fc-flex-wrap fc-margin-y-8">
      <button
        v-for="product in productsData"
        :key="product.id"
        class="features__toggle"
        :class="{active: currentId === product.id}"
        v-track-click="`${$options.name}_${product.id}_Click`"
        @click="viewProduct(product.id)">
        {{ product.buttonCta }}
      </button>
    </div>
    <div v-for="product in productsData" :key="product.image.alt">
      <div v-show="currentId === product.id" class="fc-flex fc-column md:fc-row">
        <transition
          v-if="currentId === product.id"
          name="fade"
          appear>
          <div class="features__image-container fc-flex">
            <img
              :key="product.image.src"
              class="features__image"
              :src="product.image.src"
              :alt="product.image.alt">
          </div>
        </transition>
        <div class="features__values-container fc-flex fc-column fc-justify-around fc-margin-x-8">
          <div
            v-for="value in product.values"
            :key="value.title"
            class="fc-text-center fc-margin-y-2">
            <p class="fc-text-red fc-margin-bottom-0">
              {{ value.title }}
            </p>
            <p class="fc-margin-0">
              {{ value.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from '@vue/composition-api';

  export default {
    name: 'ProductFeatures',
    props: {
      productsData: {
        type: Array,
        required: true,
      },
    },
    setup(props) {
      const currentId = ref(props.productsData[0].id);
      function viewProduct(id) {
        currentId.value = id;
      }

      return { viewProduct, currentId };
    },
  };
</script>

<style lang="scss" scoped>
@import "../../styles/exports";

.features {
  &__title {
    text-align: left;

    @media #{$tablet-up} {
      text-align: center;
    }
  }

  &__toggle-container {
    gap: get-block-size-level(4);

    @media #{$tablet-up} {
      gap: get-block-size-level(8);
    }
  }

  &__toggle {
    border: none;
    background-color: $color-egg-shell;
    color: $color-blue;
    border-radius: $input-radius;
    height: 30px;
    min-width: 90px;
    padding: 0;
    text-transform: capitalize;

    @media #{$tablet-up} {
      width: 130px;
    }

    &.active {
      background-color: $color-blue;
      color: $color-egg-shell;
    }
  }

  &__values-container {
    @media #{$tablet-up} {
      width: 60%;
      margin-top: 8rem;
      margin-bottom: 8rem;
    }
  }

  &__image-container {
    width: 100%;
    @media #{$tablet-up} {
      order: 2;
    }
  }

  &__image {
    overflow: hidden;
    width: 100%;
    object-position: 50% 50%;
    object-fit: cover;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
