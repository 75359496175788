/* eslint-disable max-classes-per-file */
import { Commit } from 'vuex';

export class DataError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'DataError';
  }
}

interface ShopifyError {
  field: string[];
  message: string;
}

export function handleShopifyAdminErrors(errors: ShopifyError[], commit: Commit): string[] {
  const formFieldsMap = new Map([
    ['city', 'city'],
    ['zip', 'postalCode'],
    ['provinceCode', 'state'],
    ['country', 'country'],
    ['email', 'email'],
    ['phone', 'phoneNumber'],
  ]);

  // check if any of the errors are related to the form fields
  const { matchingErrors, nonMatchingErrors } = errors.reduce((acc: { matchingErrors: ShopifyError[], nonMatchingErrors: ShopifyError[] }, error) => {
    const hasMatchingField = error.field.some(field => formFieldsMap.has(field));

    if (hasMatchingField) {
      acc.matchingErrors.push(error);
    } else {
      acc.nonMatchingErrors.push(error);
    }

    return acc;
  }, { matchingErrors: [], nonMatchingErrors: [] });

  const getTheFields = matchingErrors.flatMap(error => error.field.filter(field => formFieldsMap.has(field)));
  commit('SET_SHIPPING_INVALID', getTheFields, { root: true }); // this will highlight the fields in the form with red border

  const errorMessages = [
    ...(matchingErrors.length > 0 ? ['Please check the highlighted fields'] : []),
    ...nonMatchingErrors.map((error) => error.message),
  ];

  return errorMessages;
}
