import product from '@/shared/datasets/products';

export const protectFloor = {
  title: 'Protect Your Floor Bundle',
  text: 'Protect your home from scuffs & scrapes with heavy-duty flooring. Comes with 8 inter-locking tiles.',
  product: product.bundleProtectFloor,
  img_url: 'v4/fc-bundle-mat.png',
  marketplace_img_url: 'v4/fc-bundle-mat.png',
  img_alt: 'Black floor mat piece',
  item_id: 'fightcamp_bundle_protect_floor',
  sold_out: false,
  includes: [
    product.fightcampPremiumFlooring,
  ],
  types: ['Bundles'],
};
