import Vue from 'vue';
import Vuex from 'vuex';
import products from '../datasets/variants';
import helpers from '../store/helpers';
import { Mutations } from './mutationTypes';

Vue.use(Vuex);

const shippingFields = [
  'shippingFirstName',
  'shippingLastName',
  'shippingAddress1',
  'shippingAddress2',
  'shippingCity',
  'shippingPostalCode',
  'shippingCountry',
  'shippingState',
  '',
];

const contactFields = [
  'contactEmail',
  'contactPhoneNumber',
  'contactError',
];
export default {

  IDENTIFY_BY_EMAIL(state, email) {
    state.email = email;
  },

  SET_SHIPPING_INFO(state, shipping) {
    const cleanShipping = {};
    for (const propName in shipping) {
      if (shipping[propName]) {
        cleanShipping[propName] = shipping[propName];
      }
    }
    state.shippingInfo = cleanShipping;
  },

  SET_COUPON(state, coupon) {
    state.cartCoupon = coupon;
  },

  /**
   * setCart
   * @param {*} state
   * @param {*} items
   */

  setCart(state, items) {
    console.log(`store.js - setCart - ${JSON.stringify(items)}`);
    if (!items) {
      return;
    }
    const validItems = items.filter((item) => {
      const desc = products.find(product => item.id === product.id);
      return desc;
    });

    const subInCart = validItems.find(item => item.type === 'subs_only');
    const paymentMethod = {
      FightCamp_Affirm: 'affirm',
      FightCamp_1_MonthC: 'stripe',
    };

    const pm = subInCart && paymentMethod[subInCart.id] ? paymentMethod[subInCart.id] : null;

    state.cart = validItems;
    state.paymentMethod = pm;
    state.cartSyncCompleted = true;
  },

  /**
   * clearCart
   * @param {*} state
   */

  clearCart(state) {
    console.log('store.js - clearCart');
    state.cart = [];
    state.dealItems = [];
  },

  /**
   * insertItemToCart
   * @param {*} state
   * @param {*} item
   */

  insertItemToCart(state, _item) {
    const { id } = _item || {}
    console.log('store.js - insertItemToCart');
    state.customerId = null;
    state.stripeToken = null;

    const productDescription = products.find(product => id === product.id);
    if (productDescription == null) return;

    const itemInCart = state.cart.find(i => i.id === id);
    if (itemInCart) {
      itemInCart.qty += 1;
    } else {
      state.cart.push({
        ..._item,
        qty: 1
      });
    }

    if (productDescription.subscriptionNeeded) {
      // if a prepay membership type has already been selected, let's keep that one in the cart.
      const isPrepaySelected = ['prepay1year', 'prepay2years'].includes(state.membershipType.type);
      let id = isPrepaySelected ? state.membershipType.key : 'FightCamp_1_MonthC';

      // check if package is holiday bundle, if so, add the yearly sub to the cart
      if (productDescription.product.id === 'fightcamp_holiday_bundle') {
        id = 'fightcamp_subscription_yearly_included'
      }

      const sub = {
        id,
        type: 'subs_only',
        qty: 1,
      };

      state.cart.push(sub);

      state.dealItems.forEach((dealItem) => {
        state.cart.push({
          id: dealItem,
          type: 'deal',
          qty: 1,
        });
      });
    }
  },
  ADD_DEAL_ITEMS(state, item) {
    if (!state.dealItems.includes(item)) {
      state.dealItems.push(item);
    }
  },
  [Mutations.INSERT_DEAL_ITEMS]: function (state) {
    if (state.cart.find(item => item.type === 'deal')) {
      return;
    }

    state.dealItems.forEach((dealItem) => {
      state.cart.push({
        id: dealItem,
        type: 'deal',
        qty: 1,
      });
    });
  },
  [Mutations.REMOVE_DEAL_ITEMS]: function (state) {
    state.cart = state.cart.filter(i => !i.id.includes('_deal'));
    state.dealItems = [];
  },
  /**
 * SET_PAYMENT_METHOD
 * @param {*} state
 * @param {*} paymentMethod
 */
  SET_PAYMENT_METHOD(state, paymentMethod) {
    state.paymentMethod = paymentMethod;
  },
  SET_MEMBERSHIP_TYPE(state, membership) {
    const subs = [
      {
        id: 'FightCamp_1_MonthC',
        type: 'subs_only',
        qty: 1,
      },
      {
        id: 'FightCamp_1_Year',
        type: 'subs_only',
        qty: 1,
      },
      {
        id: 'FightCamp_2_Year',
        type: 'subs_only',
        qty: 1,
      },
      {
        id: 'FightCamp_1_Year_Disc',
        type: 'subs_only',
        qty: 1,
      },
      {
        id: 'FightCamp_2_Years_Disc',
        type: 'subs_only',
        qty: 1,
      },
      {
        id: 'GQ_FightCamp_1_Month',
        type: 'subs_only',
        qty: 1,
      },
      {
        id: 'Partner_FightCamp_1_Month',
        type: 'subs_only',
        qty: 1,
      }
    ];
    const membershipObject = subs.find(sub => sub.id === membership.key);

    state.cart = [
      ...state.cart.filter(i => i.type !== 'subs_only'),
      membershipObject,
    ];

    state.membershipType = membership;
  },

  /**
   * removeItemFromCart
   * @param {*} state
   * @param {*} item
   */

  removeItemFromCart(state, item) {
    console.log('store.js - removeItemFromCart');

    state.customerId = null;
    state.stripeToken = null;

    const desc = products.find(product => item.id === product.id);
    if (desc === undefined) {
      console.log(`${item.id} not found`);
      return;
    }

    const found = state.cart.find(i => i.id === item.id);
    if (found) {
      found.qty -= 1;
      if (found.qty <= 0) {
        state.cart = state.cart.filter(i => i.id !== item.id);
      }
    }

    if (desc.subscriptionNeeded) { // TODO do we need to do this in Shopify version?
      const subfound = state.cart.find(i => i.type === 'subs_only');
      if (subfound) {
        subfound.qty -= 1;
        if (subfound.qty <= 0) {
          state.cart = state.cart.filter(item => {
            const { type, id } = item;
            return type !== 'shipping' &&
              !id.includes('_deal') &&
              type !== 'subs_only' &&
              type !== 'warranty';
          });
        }
      }
    }
  },

  updateUser(state, user) {
    state.currentUser = user;
  },


  /**
   * updateContact
   * @param {*} state
   * @param {*} contact
   */

  updateContact(state, contact) {
    state.contact = contact;
  },

  /**
   *
   * @param {*} state
   * @param {*} shippingAddress
   */

  updateShippingAddress(state, shippingAddress) {
    state.shippingAddress = shippingAddress;
  },

  /**
   *
   * @param {*} state
   */

  clearShippingAddress(state) {
    state.shippingAddress = null;
  },

  /**
   *
   * @param {*} state
   * @param {*} token
   */

  updateStripeToken(state, token) {
    state.stripeToken = token;
  },

  /**
   *
   * @param {*} state
   * @param {*} token
   */

  updateAffirmToken(state, token) {
    state.affirmToken = token;
  },

  /**
*
* @param {*} state
* @param {*} token
*/

  updateAffirmCheckoutToken(state, token) {
    state.affirmCheckoutToken = token;
  },

  /**
   *
   * @param {*} state
   * @param {*} order
   */

  updateOrder(state, order) {
    state.order = order;
  },


  updateCoupon(state, coupon) {
    state.cartCoupon = coupon;
  },

  updateCouponError(state, error) {
    state.cartCouponError = error;
  },

  updateUrlCouponMessage(state, message) {
    state.cartUrlCouponMessage = message;
  },

  clearCouponError(state) {
    state.cartCouponError = '';
  },

  updateAmountOff(state, amountoff) {
    state.amountoff = amountoff;
  },

  updatePercentageOff(state, percentageoff) {
    state.percentageoff = percentageoff;
  },

  updateMembershipTaxes(state, amountToCollect) {
    state.membershipTaxes = amountToCollect;
  },

  updateCustomerId(state, customerId) {
    state.customerId = customerId;
  },

  setConfirmation(state, obj) {
    state.confirmation = obj;
  },

  setProcessingTotalCost(state, bool) {
    console.log(`setProcessingTotalCost ${bool}`);
    state.processingTotalCost = bool;
  },



  reset(state) {
    state.shippingAddress = null;
    state.stripeToken = null;
    state.affirmToken = null;
    state.paymentIntentId = null;
    state.checkoutId = null;
  },

  /**
   *  Drawer Control
   */

  toggleDrawer(state) {
    state.cartDrawerOpen = !state.cartDrawerOpen;
  },
  closeDrawer(state) {
    state.cartDrawerOpen = false;
  },
  openDrawer(state) {
    state.cartDrawerOpen = true;
  },
  /**
   *
   * @param {*} state
   */
  setInitStarted(state) {
    state.initStarted = true;
  },

  /**
   *
   * @param {*} state
   */
  setNewCustomer(state, isNewCustomer) {
    state.new_customer = isNewCustomer;
  },

  ...helpers.mapInputMutations(shippingFields),

  ...helpers.mapInputMutations(contactFields),

  clearShipping(state) {
    shippingFields.forEach((field) => {
      state[field] = '';
    });
    state.shippingCountry = 'US';
  },

  clearContact(state) {
    contactFields.forEach((field) => {
      state[field] = '';
    });
  },
  updatecontactError(state, val) {
    state.contactError = val;
  },

  updateshippingError(state, val) {
    const err = val.message ? val.message : val;
    state.shippingError = err;
  },
  clearcontactError(state) {
    state.contactError = {};
  },

  clearshippingError(state) {
    state.shippingError = {};
  },

  updateShippingValid(state, shippingValid) {
    state.shippingValid.lastName = shippingValid.lastName;
    state.shippingValid.firstName = shippingValid.firstName;
    state.shippingValid.address = shippingValid.address;
    state.shippingValid.city = shippingValid.city;
    state.shippingValid.state = shippingValid.state;
    state.shippingValid.postalCode = shippingValid.postalCode;
    state.shippingValid.country = shippingValid.country;
  },

  updateContactValid(state, contactValid) {
    state.contactValid.email = contactValid.email;
    state.contactValid.phoneNumber = contactValid.phoneNumber;
  },

  updateShippingMethod(state, method) {
    state.shippingMethod = method;
  },

  updateShippingMethodError(state, error) {
    state.shippingMethodError = error;
  },

  clearShippingMethodError(state) {
    state.shippingMethodError = {
      message: '',
      error: '',
    };
  },
};
