










































import {
  computed, ComputedRef, defineComponent, Ref, SetupContext, ref, onMounted,
} from '@vue/composition-api';
import { NavigationProps } from './types';
import { useNavigationTimerAnimator } from './hooks/useNavigationTimerAnimator';

type ButtonClassHandler = (index: number) => Record<string, boolean>;

interface NavigationBindings {
  buttonClass: ComputedRef<ButtonClassHandler>;
  timerContainer: Ref<HTMLElement | undefined>;
  timerClass: Ref<Record<string, boolean>>;
  isTabletUp: Ref<boolean>;
}

export default defineComponent<NavigationProps, NavigationBindings>({
  name: 'Navigation',
  props: {
    slides: {
      type: Array,
      default() {
        return [];
      },
    },
    currentSlide: {
      type: Number,
      default: -1,
    },
    timerEnabled: {
      type: Boolean,
      default: false,
    },
    timerLimit: {
      type: Number,
      default: 2000,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: NavigationProps, context: SetupContext): NavigationBindings {
    const timerContainer = useNavigationTimerAnimator(props, context);
    const timerClass = computed<Record<string, boolean>>(() => ({
      'navigation--hidden': !!props.isHidden,
    }));
    const buttonClass = computed<ButtonClassHandler>(() => (index) => ({
      'navigation__button--active': index === props.currentSlide,
      'navigation__button--ticking': props.timerEnabled && !props.isHidden,
      'navigation__button--ticked': props.timerEnabled && index < props.currentSlide,
    }));

    const isTabletUp = ref(false);

    onMounted(() => {
      isTabletUp.value = window.matchMedia('(min-width: 1025px)').matches;
    });

    return {
      buttonClass,
      timerContainer,
      timerClass,
      isTabletUp,
    };
  },
});
