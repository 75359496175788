/* eslint-disable no-param-reassign,camelcase */
import type { PluginFunction } from 'vue/types/plugin';
import type { VueConstructor } from 'vue';
import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import VueCompositionAPI from '@vue/composition-api';
import icons from '../../icons/icons';

interface AngieUiInstallation {
  __composition_api_installed__?: boolean;
}

export interface InstallOptions {
  skipCompositionApiInstall?: boolean;
}

export const install: PluginFunction<InstallOptions> = (Vue, options) => {
  if (!isVueCompositionApiInstalled(Vue) && ((options && !options.skipCompositionApiInstall) || !options)) {
    Vue.use(VueCompositionAPI);
  }

  Vue.component('FontAwesomeIcon', icons);
  Vue.component('FontAwesomeLayers', FontAwesomeLayers);
};

function isVueCompositionApiInstalled(Vue: VueConstructor): boolean {
  return (Vue as unknown as AngieUiInstallation).__composition_api_installed__ || false;
}
