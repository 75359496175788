export const bundleKeepFresh = {
  title: 'FightCamp Keep It Fresh Bundle',
  id: 'fightcamp_bundle_keep_fresh',
  price: 13900,
  fullPrice: 16400,
  category: 'accessory',
  description: 'You’re going to sweat a lot. Avoid a smelly workout with fresh gloves & wraps ready to go.',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-bundle-keep-fresh-001.png',
  images: [
    'fc-bundle-fresh-001.jpg',
    'fc-bundle-fresh-002.jpg',
    'fc-bundle-fresh-003.jpg',
  ],
  fbid: '',
};
