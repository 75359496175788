export default {
  title: 'FightCamp Gloves',
  id: 'fightcamp_gloves_v2_deal',
  price: 0,
  oldPrice: 14900,
  category: 'accessory',
  description: '',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-equipment-gloves-001.jpg',
  marketplace_img_url: '/v4/fc-marketplace-accessory-gloves-001.png',
  fbid: '',
  types: ['Essentials']
};
