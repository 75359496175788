//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import LazyHydrate from 'vue-lazy-hydration';
import syncCart from '~/middleware/_syncCart';
import BaseLayout from '~/components/BaseLayout.vue';
import EmailPopup from '~/components/EmailPopup.vue';
import navTypes from '~/store/constants/navTypes';

export default {
  name: 'Layout',
  components: {
    CartDrawer: () => import('~/components/checkout/components/CartDrawer.vue'),
    DefaultNavigationBar: () => import('~/components/layout/default/DefaultNavigationBar.vue'),
    NavigationBar: () => import('./NavigationBar.vue'),
    CheckoutNavBar: () => import('~/components/layout/checkout/CheckoutNavBar.vue'),
    GQNavBar: () => import('~/components/layout/checkout/GQNavBar.vue'),
    ConfirmationNavBar: () => import('~/components/layout/checkout/ConfirmationNavBar.vue'),
    Footer: () => import('~/components/layout/default/Footer.vue'),
    CheckoutFooter: () => import('~/components/layout/checkout/CheckoutFooter.vue'),
    BaseLayout,
    EmailPopup,
    LazyHydrate,
  },
  data: () => ({
    modalActive: true,
  }),
  computed: {
    ...mapGetters([
      'navType',
      'packageInCart',
    ]),
    checkoutNav() {
      switch (this.navType) {
      case navTypes.checkout:
        return true;
      default:
        return false;
      }
    },
    isNotCheckoutOrConfirmation() {
      return this.navType !== 'checkout' && this.navType !== 'confirmation';
    },
    isGQ() {
      return this.navType === 'gq';
    },
    footerComponent() {
      switch (this.navType) {
      case navTypes.confirmation:
      case navTypes.checkout:
        return 'CheckoutFooter';
      case navTypes.marketplace:
      case navTypes.gq:
        return '';
      default:
        return 'Footer';
      }
    },
    navbarProps() {
      if (this.navType === 'confirmation') return { bgColor: 'fc-bg-blue' };
      if (this.navType === 'checkout') return { bgColor: 'fc-bg-eggshell' };
      return { bgColor: 'fc-bg-white' };
    },
    nuxtTransitionProps() {
      switch (this.navType) {
      case navTypes.secondary:
      case navTypes.shop:
      case navTypes.checkout:
        return {
          name: 'fade',
          appear: true,
        };

      default:
        return {
          name: 'unknown',
          appear: true,
        };
      }
    },
  },
  beforeMount() {
    syncCart({ store: this.$store, route: this.$route });
  },
  beforeDestroy() {
    this.modalActive = false;
  },
};
