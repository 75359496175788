export default {
  title: 'FightCamp Full Zip Hoodie',
  id: 'fightcamp_full_zip_hoodie',
  price: 6500,
  category: 'accessory',
  description: 'Our favorite FightCamp Full Zip Hoodie in timeless black. This lightweight hooded full zip features a left chest logo, adjustable drawcords, and a blend of 65% cotton and 35% polyester, ensuring comfort in unisex sizing.',
  img_url: 'https://a.storyblok.com/f/152150/400x400/a81e191f67/preview-fc-zip-hoodie-black-001.png',
  fbid: '',
  marketplace_img_url: '',
  types: ['Apparel']
};