import { Experiment } from './types';
import { isEmpty } from '../../utils/isEmpty';

export interface ExperimentSelectOptions {
  variantValueOverride?: string;
}

const defaultOptions: ExperimentSelectOptions = {
  variantValueOverride: '1',
};

export function isExperimentSelected(experimentId: string, options?: ExperimentSelectOptions): boolean {
  const mergedOptions = {
    ...defaultOptions,
    ...(options || {}),
  };
  const overrideToggles = getOverrideToggles();
  const localExperiment = overrideToggles && overrideToggles[experimentId];
  let isSelected = false;

  if (!isEmpty(localExperiment)) {
    isSelected = localExperiment.variant === mergedOptions.variantValueOverride;
  } else if (window.google_optimize) {
    isSelected = window.google_optimize.get(experimentId) === mergedOptions.variantValueOverride;
  }

  return isSelected;
}

function getOverrideToggles(): Record<string, Experiment> {
  return JSON.parse(window.sessionStorage.getItem('__fc_local_experiment_override') || '{}');
}
