
const facebook = require('./facebook');
const instagram = require('./instagram');
const pinterest = require('./pinterest');
const tikTok = require('./tikTok');
const newspaper = require('./newspaper');
const youtube = require('./youtube');
const google = require('./google');
const onlineArticle = require('./onlineArticle');
const magazine = require('./magazine');
const appStore = require('./appstore');
const friendfamily = require('./friendfamily');
const mail = require('./mail');
const podcast = require('./podcast');
const television = require('./television');
const otherOption = require('./utils/other');
const influencer = require('./utils/influencer');
const attribution = require('./utils/attribution');
const employer = require('./employer');

const heardaboutus = {
  surveyId: 'heardaboutus',
  question: 'How did you *first* hear about FightCamp?',
  type: 'select',
  options: [{
      id: 'Facebook',
      label: 'Facebook',
      followup: facebook,
      attribution: attribution.UTM,
    },
    {
      id: 'Instagram',
      label: 'Instagram',
      followup: instagram,
      attribution: attribution.UTM,
    },
    {
      id: 'Pinterest',
      label: 'Pinterest',
      followup: pinterest,
      attribution: attribution.UTM,
    },
    {
      id: 'TikTok',
      label: 'TikTok',
      followup: tikTok,
      attribution: attribution.UTM,
    },
    {
      id: 'Newspaper',
      label: 'Newspaper',
      followup: newspaper,
      attribution: attribution.UTM,
    },
    {
      id: 'Youtube',
      label: 'Youtube',
      followup: youtube,
      attribution: attribution.UTM,

    },
    {
      id: 'Google',
      label: 'Google',
      followup: google('Google'),
      attribution: attribution.UTM,

    },
    {
      id: 'SearchEngine',
      label: 'Search Engine',
      followup: {
        id: 'SearchEngine Origin',
        question: 'What Search Engine Did You Use?',
        type: 'input',
        followup: google('SearchEngine Origin'),
        attribution: attribution.UTM,
      },
      attribution: attribution.UTM,
    },
    {
      id: 'OnlineArticle',
      label: 'Online Article',
      followup: onlineArticle,
      attribution: attribution.PR,
    },
    {
      id: 'Magazine',
      label: 'Magazine',
      followup: magazine,
      attribution: attribution.PR,

    },
    {
      id: 'Mail',
      label: 'Physical Mail',
      followup: mail,
      attribution: attribution.Mail,

    },
    {
      id: 'Podcast',
      label: 'Podcast',
      followup: podcast,
      attribution: attribution.Podcast,

    },
    {
      id: 'Television',
      label: 'Television',
      followup: television,
      attribution: attribution.Television,
    },
    {
      id: 'Influencer',
      label: 'Influencer',
      followup: influencer('Influencer'),
      attribution: attribution.Influencer,
    },
    {
      id: 'AppStore',
      label: 'App Store',
      followup: appStore,
      attribution: attribution.Appstore,
    },
    {
      id: 'Friend',
      label: 'Friend',
      followup: friendfamily('Friend'),
      attribution: attribution.Referral,
    },
    {
      id: 'Family',
      label: 'Family',
      followup: friendfamily('Family'),
      attribution: attribution.Referral,
    },
    {
      id: 'Colleague',
      label: 'Colleague',
      followup: friendfamily('Colleague'),
      attribution: attribution.Referral,
    },
    {
      id: 'Bing',
      label: 'Bing',
      followup: google('Bing'),
      attribution: attribution.UTM,
    },
    {
      id: 'Employer',
      label: 'Employer',
      followup: employer,
      attribution: attribution.BizDev,
    },
    otherOption({ id: 'Other', attribution: attribution.Unknown }),
  ],
};

module.exports = heardaboutus;
