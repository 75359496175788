// eslint-disable-next-line import/no-extraneous-dependencies
import { useAnalytics } from '@fc/app-common';
import { GrowthBook } from '@growthbook/growthbook';
import storeMutations from '~/store/constants/storeMutations';

interface Store {
  commit: (mutation: string, payload: any) => void;
}

export async function createGrowthbookInstance(store: Store) {
  const segmentAnonymousId = window.analytics.user().anonymousId();

  const isDev = process.env.NUXT_ENV_IS_DEV === 'true';

  const growthbookInstance = new GrowthBook({
    apiHost: process.env.NUXT_ENV_GROWTHBOOK_URL,
    clientKey: process.env.NUXT_ENV_GROWTHBOOK_KEY,
    attributes: {
      id: segmentAnonymousId,
    },
    trackingCallback: (experiment, result) => {
      // trackingCallback is called when evalFeature is called
      const exp = { id: experiment.key, variant: result.key };

      store.commit(storeMutations.REGISTER_GROWTHBOOK_EXPERIMENTS, exp);
      store.commit(storeMutations.SET_GROWTHBOOK_INITIALIZED, true);
    },
    enableDevMode: isDev,
  });

  await growthbookInstance.loadFeatures();
  const features = growthbookInstance.getFeatures();
  const featureKeys = Object.keys(features);

  const analytics = useAnalytics();

  if (analytics) {
    featureKeys.forEach((featureKey) => {
      const isOn = growthbookInstance.isOn(featureKey);
      store.commit(storeMutations.SET_FEATURE_FLAG, { key: featureKey, isOn });

      // evalFeature will trigger the trackingCallback and update the store
      const result = growthbookInstance.evalFeature(featureKey);
      analytics.trackEvent('Experiment', {
        name: featureKey,
        anonId: segmentAnonymousId,
        result,
      });
    });
  }
}
