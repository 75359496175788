export function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

export function setMinutesCookie(name, value, minutes) {
  let expires = '';
  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + (minutes * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

function fromEntriesPolyfill(iterable) {
  return [...iterable].reduce((_obj, [key, val]) => {
    const obj = _obj;
    obj[key] = val;
    return obj;
  }, {});
}

export function getCookie(name) {
  const cookies = document.cookie.split(';').map(item => item.trim());
  const cookiesArray = cookies.map(cookie => cookie.split('='));
  const cookiesObjects = fromEntriesPolyfill(cookiesArray);

  return cookiesObjects[name] ? cookiesObjects[name] : null;
}
