export default {
  title: 'FightCamp All In One',
  id: 'fightcamp_console_all_in_one',
  price: 179900,
  monthly: 7496,
  months: 24,
  category: 'package',
  path: '/shop/products/fightcamp-all-in-one/',
  internalPath: 'shop/products/fightcamp-all-in-one/',
  img_url: 'https://a.storyblok.com/f/152150/2000x2000/b93e958edd/fc-ultimate-bundle-package-001.jpg', // TODO - Update image
  description: 'The ultimiate FightCamp bundle', // TODO - Update description
};
