<template>
  <li
    ref="slide"
    class="slide"
    role="listitem"
    aria-roledescription="slide"
    :aria-hidden="ariaHidden"
    :style="slideStyle"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd">
    <slot />
  </li>
</template>

<script lang="ts">
  import {
    computed,
    ComputedRef,
    defineComponent, inject, onMounted, Ref, ref, watchEffect,
  } from '@vue/composition-api';
  import { CarouselContext, Slide } from './types';
  import { Tokens } from './tokens';

  interface SlideProps {
    screenLabel: string;
  }

  interface SlideBindings {
    slide: Ref<HTMLElement | undefined>;
    slideStyle: ComputedRef<CSSStyleDeclaration>;
    ariaHidden: ComputedRef<'true' | 'false'>;
    handleTouchStart(event: Event): void;
    handleTouchMove(event: Event): void;
    handleTouchEnd(event: Event): void;
  }

  export default defineComponent<SlideProps, SlideBindings>({
    name: 'Slide',
    props: {
      screenLabel: {
        type: String,
        required: true,
      },
    },
    setup(props): SlideBindings {
      const slide = ref<HTMLElement>();
      const slides = inject<Slide[]>(Tokens.slides);
      const context = inject<CarouselContext>(Tokens.carouselContext);
      let slideIndex = -1;
      let startY = 0;
      let startX = 0;

      if (slides) {
        slideIndex = slides.length;
        slides.push({ screenLabel: props.screenLabel });
      }

      onMounted(() => {
        if (slide.value) {
          slide.value.addEventListener('dragstart', (e) => e.preventDefault());
        }
      });

      function removeTabindex(node: HTMLElement) {
        for (let i = 0; i < node.children.length; i++) {
          const child: any = node.children[i];
          removeTabindex(child);
          child.removeAttribute('tabindex');
        }
      }

      function addTabIndex(node: HTMLElement) {
        for (let i = 0; i < node.children.length; i++) {
          const child: any = node.children[i];
          addTabIndex(child);
          child.setAttribute('tabindex', '-1');
        }
      }

      watchEffect(() => {
        if (slide.value && isActive()) {
          removeTabindex(slide.value);
        } else if (slide.value) {
          addTabIndex(slide.value);
        }
      });

      function isActive(): boolean {
        return !!context && context.currentIndex.value === slideIndex;
      }

      function isCurrentThreeSlideView(): boolean {
        return !!context && context.isThreeSlideView && isActive() || false;
      }

      return {
        slide,
        slideStyle: computed<CSSStyleDeclaration>(() => {
          const widthPercentage = context ? context.getWidthPercentage() : 1;
          const transformValue = isCurrentThreeSlideView() ? 'scale(1.1)' : '';
          const transitionValue = isCurrentThreeSlideView() ? 'transform 1s' : '';

          return {
            width: `${widthPercentage * 100}%`,
            transform: transformValue,
            transition: transitionValue,
          } as CSSStyleDeclaration;
        }),
        ariaHidden: computed<'true' | 'false'>(() => (isActive() ? 'false' : 'true')),
        handleTouchStart(event: Event): void {
          startY = (event as TouchEvent).touches[0].clientY;
          startX = (event as TouchEvent).touches[0].clientX;

          if (context) {
            context.startSlideDrag(slideIndex, event);
          }
        },
        handleTouchMove(event: Event): void {
          const currentY = (event as TouchEvent).touches[0].clientY;
          const currentX = (event as TouchEvent).touches[0].clientX;

          if (Math.abs(startY - currentY) <= Math.abs(startX - currentX)) {
            if (event.cancelable) {
              event.preventDefault();
            }

            if (context) {
              context.continueSlideDrag(event);
            }
          }
        },
        handleTouchEnd(): void {
          if (context) {
            context.endSlideDrag();
          }
        },
      };
    },
  });
</script>

<style lang="scss" scoped>
  .slide {
    position: relative;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    flex-shrink: 0;
  }
</style>
