
import { StoryblokComponent } from 'storyblok-js-client';
import { PropType, defineComponent } from '@vue/composition-api';
import { Announcement, BackgroundTheme } from '@fc/angie-ui';
import { getAngieUIComponent } from '~/selectors/selectorsMap';

export default defineComponent({
  name: 'StoryblokAnnouncement',
  inheritAttrs: false,
  components: {
    Announcement,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    route: {
      type: String,
      default: null,
    },
    theme: {
      type: String as PropType<BackgroundTheme>,
      default: 'blue',
    },
    content: {
      type: Array as PropType<StoryblokComponent<string>[]>,
      default: () => [],
    },
  },
  methods: {
    handleClick() {
      if (this.route) {
        this.$router.push(this.route);
      }
    },
    getProps(comp: StoryblokComponent<string>) {
      return getAngieUIComponent(comp?.component, comp) ?? comp;
    },
  },

});
