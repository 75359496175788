import product from '@/shared/datasets/products';

export const family = {
  title: 'Family Bundle',
  text: 'Fun for the whole family. Add these gloves to get everyone involved.',
  product: product.bundleFamily,
  img_url: 'https://a.storyblok.com/f/152150/603x477/60d999145f/fightcamp-family-bundle.jpg',
  img_alt: '2 pairs of white adult boxing gloves and 2 pairs of white kids boxing gloves',
  item_id: 'fightcamp_family_bundle',
  sold_out: false,
  includes: [
    product.glovesv2,
    product.glovesv2,
    product.kidsglovesv2,
    product.kidsglovesv2,
  ],
  discount: 'SAVE 24%',
  types: ['Bundles'],
};
