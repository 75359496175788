import type { PluginObject } from 'vue/types/plugin';
import type{ VueConstructor } from 'vue';
import { appendAntiFlickerRule } from './appendAntiFlickerRule';
import { installOptimize } from './installOptimize';
import { OptimizeOptions } from './types';
import { installLocalToggleOverrideHandler } from './installLocalToggleOverrideHandler';

export const Optimize: PluginObject<OptimizeOptions> = {
  install(Vue: VueConstructor, options?: OptimizeOptions): void {
    if (options) {
      installLocalToggleOverrideHandler(options);
      appendAntiFlickerRule(options);
      installOptimize(options);
    }
  },
};

export * from './isExperimentSelected';
