//
//
//
//
//

import buttonStyleMixin from '../mixins/button-style';

export default {
  name: 'ButtonLink',
  mixins: [buttonStyleMixin],
  data() {
    return {
      validAttrs: {},
    };
  },
  props: {
    to: { type: String, default: '/' },
  },
  mounted() {
    const { target = '_blank', href, ...attrs } = this.$attrs;

    this.validAttrs = {
      target,
      ...(attrs || {}),
    };
  },
};
