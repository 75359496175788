const attribution = require('./utils/attribution');
const otherOption = require('./utils/other');

const tikTokAd = {
  id: 'TikTok Ad',
  question: 'What was in the ad?',
  type: 'input',
  attribution: attribution.Unknown,
};

const tikTokShare = {
  id: 'TikTok Share',
  question: 'What was it?',
  type: 'list',
  options: [
    {
      id: 'TikTok Share Video',
      label: "A video from FightCamp's profile",
      attribution: attribution.UTM,
    },
    otherOption({ id: 'Other', attribution: attribution.Unknown }),
  ],
};

const tikTokInfluencer = {
  id: 'TikTok Influencer',
  question: 'Who was it?',
  type: 'input',
  attribution: attribution.UTM,
};

const tikTokPost = {
  id: 'TikTok Post',
  question: 'Who was it?',
  type: 'list',
  options: [
    {
      id: 'TikTok Someone Known',
      label: "Someone you know personally",
      attribution: attribution.Referral,
    },
    {
      id: 'TikTok Influencer Known',
      label: "Content Creator / Celebrity / Influencer you follow",
      followup: tikTokInfluencer,
      attribution: attribution.UTM,
    },
    otherOption({ id: 'Other', attribution: attribution.Unknown }),
  ],
};

const tikTokTag = {
  id: 'TikTok Tag',
  question: 'What was it?',
  type: 'list',
  options: [
    {
      id: 'TikTok FC Profile Video',
      label: "A video from FightCamp's Profile",
      attribution: attribution.UTM,
    },
    {
      id: 'TikTok Video About FC',
      label: "A video from someone about FightCamp",
      attribution: attribution.UTM,
    },
    otherOption({ id: 'Other', attribution: attribution.Unknown }),
  ],
};

const tikTokFeed = {
  id: 'TikTok Feed',
  question: 'What was it?',
  type: 'list',
  options: [
    {
      id: 'TikTok FC Profile Video',
      label: "A video from FightCamp's Profile",
      attribution: attribution.UTM,
    },
    {
      id: 'TikTok Video About FC',
      label: "A video from someone about FightCamp",
      attribution: attribution.UTM,
    },
    otherOption({ id: 'Other', attribution: attribution.Unknown }),
  ],
};

const tikTok = {
  id: 'TikTok',
  question: 'What did you see on TikTok?',
  type: 'list',
  options: [
    {
      id: 'TikTok Ad',
      label: 'Advertisement',
      followup: tikTokAd,
      attribution: attribution.Unknown,
    },
    {
      id: 'TikTok Share',
      label: 'Someone shared something with you',
      followup: tikTokShare,
      attribution: attribution.UTM,
    },
    {
      id: 'TikTok Post',
      label: 'Someone posted about FightCamp',
      followup: tikTokPost,
      attribution: attribution.UTM,
    },
    {
      id: 'TikTok Tag',
      label: 'Someone tagged you in something',
      followup: tikTokTag,
      attribution: attribution.UTM,
    },
    {
      id: 'TikTok For You Feed',
      label: 'Saw FightCamp in the For You feed',
      followup: tikTokFeed,
      attribution: attribution.UTM,
    },
    otherOption({ id: 'TikTok Other', attribution: attribution.Unknown }),
  ],
};

module.exports = tikTok;
