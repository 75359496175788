import Vue from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from '@nuxt/types';
import BugsnagWrapper from '../services/BugSnagService';

const bugsnagPlugin: Plugin = ({ store }, inject) => {
  const Bugsnag = BugsnagWrapper.getInstance({ experiments: store?.state?.app?.experiments ?? [] });

  const bugsnagVue = Bugsnag.getPlugin('vue');
  bugsnagVue?.installVueErrorHandler(Vue);

  console.log('Injecting Bugsnag');
  inject('bugsnag', Bugsnag);
};

export default bugsnagPlugin;
