import BrochureModal from '~/components/BrochureModal.vue';
import EventBus from '@/shared/scripts/eventbus';

export const brochureModal = {
  component: BrochureModal,
  config: [
    {},
    {
      adaptive: true,
      height: 'auto',
      width: '95%',
      maxWidth: 870,
    },
    {
      closed: () => {
        EventBus.$emit('brochureModalClosed');
      },
    },
  ],
};
