//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref } from '@vue/composition-api';

export default {
  name: 'ProductFeatures',
  props: {
    productsData: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const currentId = ref(props.productsData[0].id);
    function viewProduct(id) {
      currentId.value = id;
    }

    return { viewProduct, currentId };
  },
};
