const attribution = require('./utils/attribution');
const otherOption = require('./utils/other');

const Mail = {
  id: 'Mail',
  type: 'list',
  question: 'What did you receive in the Mail?',
  options: [{
      id: 'Mail Postcard',
      label: 'A postcard with FightCamp info',
      attribution: attribution.Mail,
    },
    {
      id: 'Mail Letter',
      label: 'A letter about FightCamp',
      attribution: attribution.Mail,
    },
    {
      id: 'Mail Pamphlet',
      label: 'A folded Pamphlet from FightCamp',
      attribution: attribution.Mail,
    },
    otherOption({ id: 'Mail Other', attribution: attribution.Mail }),
  ],
};


module.exports = Mail;
