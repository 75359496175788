
import { defineComponent, onMounted } from '@vue/composition-api';
import { useTabs, Tabs } from './useTabs';

export default defineComponent({
  name: 'Tabs',
  setup(_, context) {
    const { tabs, setActiveTab, activeTab } = useTabs(context);

    onMounted(() => {
      const firstTab = Object.keys(tabs[0])[0];
      setActiveTab(firstTab);
    });

    return {
      tabs,
      setActiveTab,
      activeTab,
      getTabIdentifier: (tab: Tabs) => Object.keys(tab)[0],
    };
  },
});
