import axios from 'axios';

export default {
  async getJobPostings() {
    try {
      return await axios.get('https://ats.comparably.com/api/v1/lvr/fightcamp/postings?mode=json');
    } catch (err) {
      console.log(err);
    }

    return [];
  },
};
