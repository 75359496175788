import productIndex from '@/shared/datasets/products';
import { accessoriesContent } from '~/components/products/content/accessories.js';
import bundles from '~/components/products/content/bundles.js';

export function getProduct(productId) {
  const items = Object.values(productIndex);

  let item = accessoriesContent.find(acc => acc.id === productId);
  if (item) { return item; }

  item = Object.values(bundles).find(b => b.item_id === productId);
  if (item) { return item; }

  item = items.find(i => i.id === productId);
  if (item) { return item; }

  return item;
}

export function getIncludedItems(products) {
  if (products) {
    return products.map((productId) => getProduct(productId));
  }
  return [];
}

export function getProducts(productIds) {
  return productIds.map(productId => ({
    ...getProduct(productId.id),
    slug: productId.slug,
  }));
}
