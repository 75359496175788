export const fightcamp2023HolidayBundleUltimate = {
  title: 'FightCamp The Works Bundle',
  id: 'fightcamp_2023_holiday_bundle_ultimate',
  price: 40000,
  oldPrice: 50000,
  category: 'accessory',
  img_url: 'https://a.storyblok.com/f/152150/1440x1280/aac6fe1c44/ultimatebundle.jpg',
  images: [],
  fbid: '',
};
