








import {
  onMounted, ref, onUnmounted, computed, defineComponent,
} from '@vue/composition-api';
import { IntersectionObserverInit } from 'intersection-observer';
import { AnimationMode } from './types';

export default defineComponent({
  name: 'ScrollTransition',
  props: {
    animationType: {
      type: String,
      required: false,
      default: AnimationMode.FADE,
    },
    threshold: {
      type: Array,
      required: false,
      default: () => ([0]),
    },
    rootMargin: {
      type: String,
      required: false,
      default: '0px',
    },
  },
  setup(props) {
    const target = ref<HTMLElement>();
    const animate = ref<boolean>(false);
    const animationClass = computed(() => [
      props.animationType || AnimationMode.FADE,
      { enabled: animate.value },
    ]);

    const options: IntersectionObserverInit = { root: null, rootMargin: props.rootMargin, threshold: props.threshold as number[] };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        animate.value = entry.isIntersecting || entry.boundingClientRect.y < 0;
      });
    }, options);

    onMounted(() => {
      if (target.value) {
        observer.observe(target.value);
      }
    });

    onUnmounted(() => {
      if (target.value) {
        observer.unobserve(target.value);
      }

      observer.disconnect();
    });

    return {
      animate,
      target,
      animationClass,
    };
  },
});
