export default {
  title: 'FightCamp Bag',
  id: 'fightcamp_bag_legacy',
  price: 59900,
  monthly: 8292,
  months: 12,
  category: 'accessory',
  description: '',
  img_url: '',
};
