export const bundleTv = {
  title: 'FightCamp Put It On the TV Bundle',
  id: 'fightcamp_bundle_tv',
  price: 5900,
  fullPrice: 6300,
  category: 'accessory',
  description: 'Display your workout on the big screen & get fully immersed in the FightCamp experience.',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-bundle-tv-001.png',
  images: [
    'fc-bundle-tv-cable-001.jpg',
    'fc-bundle-tv-cable-002.jpg',
    'fc-bundle-tv-cable-003.jpg',
  ],
  fbid: '',
  types: ["Bundles"]
};
