import {
  transformLinkButtonContent,
  transformShortTextContent,
  transformMediaBackgroundContent,
  transformUniqueContent,
  transformCarouselContent,
} from './selectors';

export const ANGIE_UI_COMPONENTS_MAP = new Map([
  ['LinkButton', transformLinkButtonContent],
  ['ShortText', transformShortTextContent],
  ['MediaBackground', transformMediaBackgroundContent],
  ['Unique', transformUniqueContent],
  ['Carousel', transformCarouselContent],
]);

export const getAngieUIComponent = (component = '', data) => {
  if (component == null) return null;
  if (data == null) return null;

  const helper = ANGIE_UI_COMPONENTS_MAP.get(component) ?? (() => null);
  return helper(data);
};
