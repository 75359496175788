import { OptimizeOptions } from './types';

export function appendAntiFlickerRule(options: OptimizeOptions): void {
  if (options.antiFlickerEnabled && !hasAntiFlicker()) {
    document.head.append(
      createStyle(),
      createScript(options.optimizeId),
    );
  }
}

const antiFlickerIds = {
  style: 'fc-anti-flicker-styles',
  script: 'fc-anti-flicker-scripts',
} as const;

function hasAntiFlicker(): boolean {
  return Object.values(antiFlickerIds)
    .map((id) => !!document.getElementById(id))
    .every((elementExists) => elementExists);
}

function createStyle(): HTMLStyleElement {
  const styleElem: HTMLStyleElement = document.createElement('style');

  styleElem.id = antiFlickerIds.style;
  styleElem.setAttribute('data-testid', antiFlickerIds.style);
  styleElem.innerHTML = '.async-hide { opacity: 0 !important}';

  return styleElem;
}

function createScript(gtmId: string): HTMLScriptElement {
  const scriptElem: HTMLScriptElement = document.createElement('script');

  scriptElem.id = antiFlickerIds.script;
  scriptElem.setAttribute('data-testid', antiFlickerIds.script);
  scriptElem.innerHTML = `
    (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
    h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
    (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
    })(window,document.documentElement,'async-hide','dataLayer',4000,
    {'${gtmId}':true});
  `;

  return scriptElem;
}
