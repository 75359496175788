const apparel = require('../apparel');
const attribution = require('./attribution');
const otherOption = require('./other');

const whichInfluencer = (id) => ({
  id,
  question: 'Which influencer first shared FightCamp with you?',
  type: 'input',
  attribution: attribution.UTM,
  followup: apparel({ id, attribution: attribution.UTM }),
});

const Influencer = (id) => ({
  id,
  question: 'Which platform?',
  type: 'list',
  options: [
    {
      id: `${id} Facebook`,
      label: 'Facebook',
      attribution: attribution.UTM,
      followup: whichInfluencer(`${id} Facebook`),
    },
    {
      id: `${id} InstagramPost`,
      label: 'Instagram Post',
      attribution: attribution.UTM,
      followup: whichInfluencer(`${id} InstagramPost`),
    },
    {
      id: `${id} InstagramStory`,
      label: 'Instagram Story',
      attribution: attribution.UTM,
      followup: whichInfluencer(`${id} InstagramStory`),
    },
    {
      id: `${id} YouTube`,
      label: 'YouTube',
      attribution: attribution.Influencer,
      followup: whichInfluencer(`${id} YouTube`),
    },
    otherOption({ id: `${id} Other`, attribution: attribution.Unknown, followup: whichInfluencer(`${id} Other`) }),
  ],
});

module.exports = Influencer
