export const bundleFamily = {
  title: 'FightCamp Family Bundle',
  id: 'fightcamp_family_bundle',
  price: 29900,
  fullPrice: 39600,
  category: 'accessory',
  description: 'Fun for the whole family. Add these gloves to get everyone involved.',
  img_url: 'https://a.storyblok.com/f/152150/603x477/60d999145f/fightcamp-family-bundle.jpg',
  images: [
    'https://a.storyblok.com/f/152150/603x477/60d999145f/fightcamp-family-bundle.jpg'
  ],
  fbid: '',
};
