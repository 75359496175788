






















import { computed, defineComponent } from '@vue/composition-api';
import { CheckboxProps } from './types';

export default defineComponent({
  name: 'Checkbox',
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      type: String,
      required: false,
      default: '1.5rem',
    },
    width: {
      type: String,
      required: false,
      default: '1.5rem',
    },
  },
  setup(props: CheckboxProps, context) {
    const checked = computed<boolean>(() => !!props.value);

    return {
      checked,
      checkBoxClass: computed<string[]>(() => {
        const classes = [];

        if (checked.value) {
          classes.push('checked');
        }

        return classes;
      }),
      toggleChecked() {
        context.emit('input', !checked.value);
      },
      styles: computed(() => ({
        width: props.width,
        height: props.height,
      })),
    };
  },
});
