export default {
  title: 'FightCamp Gloves',
  id: 'fightcamp_gloves',
  price: 14900,
  oldPrice: 17900,
  category: 'accessory',
  description: '',
  img_url: '',
  fbid: 'shopify_742223380534_8963688726582',
  marketplace_img_url: '/v4/fc-marketplace-accessory-gloves-001.png',
};
