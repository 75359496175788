export default {
  title: 'FightCamp Endurance Kit',
  id: 'fightcamp_endurance_kit',
  price: 17900,
  category: 'accessory',
  description: '',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-accessories-endurance-kit-001.png',
  types: ['Bundles'],
};
