import Vue from 'vue';

/* eslint-disable-next-line */
export const windowResizeMixin = Vue.extend({
  data() {
    return {
      mixinResize: {
        initialWidth: 900,
        initialHeight: 600,
      },
    };
  },
  methods: {
    initialDimensions() {
      this.mixinResize.initialHeight = window.innerHeight;
      this.mixinResize.initialWidth = window.innerWidth;
    },
  },
  mounted() {
    this.initialDimensions();
  },
});
