export const fightcampHdmiCable = {
  title: '6-ft HDMI Cable',
  id: 'fightcamp_hdmi_cable',
  price: 1400,
  category: 'accessory',
  description: 'Use this 6 foot HDMI cable to extend your workout to a big screen.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-hdmi-cable-001.png',
  types: ['Setup']
};
