<template>
  <div
    class="carousel__wrapper"
    role="group"
    aria-roledescription="carousel"
    :aria-labelledby="ariaHeadingId">
    <NavigationArrows
      v-if="displayArrowNavigation"
      :slides="slides"
      :current-slide="currentSlide"
      :is-hidden="isHidden"
      :arrow-navigation-theme="arrowNavigationTheme"
      @slideSelect="handleSlideSelect" />
    <p :id="ariaHeadingId" class="fc-screen-reader-only">
      {{ screenLabel }}
    </p>
    <div class="carousel__inner">
      <ul
        ref="slider"
        class="carousel__slider"
        aria-live="polite"
        role="list">
        <slot />
      </ul>
    </div>
    <div class="carousel__navigation">
      <navigation
        v-if="!hideNavigation"
        :slides="slides"
        :current-slide="currentSlide"
        :timer-enabled="isTimerEnabled"
        :timer-limit="timerLimit"
        :is-hidden="isHidden"
        @slideSelect="handleSlideSelect" />
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed, defineComponent, provide, Ref, shallowReactive, watch,
  } from '@vue/composition-api';
  import {
    CarouselContext, CarouselMode, CarouselParams, CarouselProps, Slide, SliderOptions,
  } from './types';
  import { Tokens } from './tokens';
  import Navigation from './Navigation.vue';
  import NavigationArrows from './NavigationArrows.vue';
  import { useCarouselContext } from './hooks/useCarouselContext';
  import { useTimerController } from './hooks/useTimerController';
  import { slugify } from '../../utils';

  interface CarouselBindings {
    ariaHeadingId: string;
    slider: Ref<HTMLElement | undefined>;
    slides: Slide[];
    currentSlide: Ref<number>;
    handleSlideSelect(index: number): void;
    isTimerEnabled: Ref<boolean>;
    isHidden: Ref<boolean>;
  }

  export default defineComponent({
    name: 'Carousel',
    components: {
      Navigation,
      NavigationArrows,
    },
    props: {
      screenLabel: {
        type: String,
        required: true,
      },
      snapThreshold: {
        type: Number,
        default: 0.3,
      },
      mode: {
        type: String,
        default: CarouselMode.DEFAULT,
      },
      slideWidthPercentage: {
        type: Number,
        default: 1,
      },
      timerLimit: {
        type: Number,
        default: 2000,
      },
      infiniteSlidesEnabled: {
        type: Boolean,
        default: true,
      },
      hideNavigation: {
        type: Boolean,
        default: false,
      },
      threeSlideView: {
        type: Boolean,
        default: false,
      },
      displayArrowNavigation: {
        type: Boolean,
        default: false,
      },
      arrowNavigationTheme: {
        type: String,
        default: 'dark-gray',
      },
    },
    setup(props: CarouselProps, context): CarouselBindings {
      const {
        carouselContext,
        slider,
      } = useCarouselContext(props as CarouselParams);
      const isTimerEnabled = useTimerController({ slider, mode: props.mode });
      const isHidden = computed<boolean>(() => isTimerEnabled && props.mode === CarouselMode.TIMER_ON_HOVER);
      const slides = shallowReactive<Slide[]>([]);

      provide<Slide[]>(Tokens.slides, slides);
      provide<CarouselContext>(Tokens.carouselContext, carouselContext);

      watch(() => carouselContext.currentIndex.value, (newIndex, oldIndex) => {
        if (newIndex !== oldIndex) {
          context.emit('slideChange', newIndex);
        }
      });

      return {
        ariaHeadingId: `carousel-heading-${slugify(props.screenLabel)}`,
        slider,
        slides,
        currentSlide: carouselContext.currentIndex,
        isHidden,
        isTimerEnabled,
        handleSlideSelect(index: number, options?: SliderOptions): void {
          carouselContext.slideToIndex(index, options);
        },
      };
    },
  });
</script>

<style lang="scss" scoped>
  .carousel {
    &__wrapper {
      width: 100%;
      overflow: hidden;
      position: relative;
    }

    &__slider {
      margin: 0;
      padding: 0;
      display: flex;
      flex-flow: row nowrap;
      transform-style: preserve-3d;
      transition: transform 0.3s ease-out;
      transform: translateX(0);
    }
  }
</style>
