import { IRecursiveTimeoutOpts, ITimeoutOpts } from './helpers.types';

const US_POSTAL_CODE_VALIDATOR = /^\d{5}(?:[-\s]\d{4})?$/;
const CA_POSTAL_CODE_VALIDATOR = /([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i;

export function isEmpty(value: any) {
  return !!(value == null || value.length <= 0);
}

export function variableTimeout(opts: ITimeoutOpts) {
  const {
    cb,
    min = 300,
    max = 600,
  } = opts || {};
  let timeout: NodeJS.Timeout;

  return function executable() {
    timeout = global.setTimeout(() => {
      cb();
      clearTimeout(timeout);
    }, Math.min(min, Math.random() * max));
  };
}

export function recursiveTimeout(opts: IRecursiveTimeoutOpts) {
  const maxRecursions = 500;
  let recursionsCounter = 0;

  const callback = () => {
    if (recursionsCounter > maxRecursions || opts.conditionMet()) return;

    variableTimeout({
      ...opts,
      cb: callback,
    })();

    opts.cb();
    recursionsCounter += 1;
  };

  variableTimeout({
    ...opts,
    cb: callback,
  })();
}

export function getRelativeFileName(url: string) {
  if (url == null || !url.length) return '';
  return `/_nuxt/${url.split('/').pop()}`;
}

export function chunkArray(array: any[], size: number) {
  const result = [];

  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    result.push(chunk);
  }

  return result;
}

export function isPostalCodeValid(postalCode: string | number) {
  return postalCode && (US_POSTAL_CODE_VALIDATOR.test(postalCode.toString()) || CA_POSTAL_CODE_VALIDATOR.test(postalCode.toString().replace(/\W+/g, '')));
}
