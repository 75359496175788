const attribution = require('./utils/attribution');
const otherOption = require('./utils/other');
const postInfluencer = require('./utils/influencer');

const instagramShare = {
  id: 'Instagram Share',
  question: 'What was it?',
  type: 'list',
  options: [{
      id: 'Instagram Share Story',
      label: "A story from FightCamp's profile",
      attribution: attribution.UTM,
    },
    {
      id: 'Instagram Share Post',
      label: "A post from FightCamp's profile",
      attribution: attribution.UTM,
    },
    {
      id: 'Instagram Share Website',
      label: 'A link to the FightCamp website',
      attribution: attribution.Referral,
    },
    {
      id: 'Instagram Share App',
      label: 'A link to the FightCamp App',
      attribution: attribution.Referral,
    },
    {
      id: 'Instagram Share Message',
      label: 'A DM talking about FightCamp',
      attribution: attribution.Referral,
    },
    otherOption({ id: 'Instagram Share Other', attribution: attribution.Unknown }),
  ],
};

const instagramPost = {
  id: 'Instagram Post',
  question: 'Who was it?',
  type: 'list',
  options: [{
      id: 'Instagram Post Referral',
      label: 'Someone you know personally',
      attribution: attribution.Referral,
    },
    {
      id: 'Instagram Post Influencer',
      label: 'Content Creator / Celebrity / Influencer you follow',
      followup: postInfluencer('Instagram Post Influencer'),
      attribution: attribution.UTM,

    },
    otherOption({ id: 'Instagram Post Other', attribution: attribution.Unknown }),
  ],
};

const instagramTag = {
  id: 'Instagram Tag',
  question: 'What was it?',
  type: 'list',
  options: [{
      id: 'Instagram Tag Profile',
      label: "A post from FightCamp's profile",
      attribution: attribution.UTM,
    },
    {
      id: 'Instagram Tag Post',
      label: 'A post from someone about FightCamp',
      attribution: attribution.Referral,
    },
    otherOption({ id: 'Instagram Tag Other', attribution: attribution.Unknown }),
  ],
};

const instagramDiscovery = {
  id: 'Instagram Discovery',
  question: 'What was it?',
  type: 'list',
  options: [{
      id: 'Instagram Discovery FightCamp',
      label: "A post from FightCamp's profile",
      attribution: attribution.UTM,
    },
    {
      id: 'Instagram Discovery Post',
      label: 'A post from someone about FightCamp',
      attribution: attribution.Referral,
    },
    otherOption({ id: 'Instagram Discovery Other', attribution: attribution.Unknown }),
  ],
};

const instagramAd = {
  id: 'Instagram Ad',
  question: 'What was in the ad?',
  type: 'input',
};

const instagram = {
  id: 'Instagram',
  question: 'What did you see on Instagram?',
  type: 'list',
  options: [{
      id: 'Instagram Ad',
      label: 'Advertisement',
      followup: instagramAd,
      attribution: attribution.PaidSocial,
    },
    {
      id: 'Instagram Share',
      label: 'Someone shared something with you',
      followup: instagramShare,
      attribution: attribution.Referral,

    },
    {
      id: 'Instagram Post',
      label: 'Someone posted about FightCamp',
      followup: instagramPost,
      attribution: attribution.Referral,

    },
    {
      id: 'Instagram Tag',
      label: 'Someone tagged you in something',
      followup: instagramTag,
      attribution: attribution.Referral,

    }, {
      id: 'Instagram Discovery',
      label: 'Saw FightCamp in the discovery feed',
      followup: instagramDiscovery,
      attribution: attribution.UTM,

    },
    otherOption({ id: 'Instagram Other', attribution: attribution.Unknown }),
  ],
};


module.exports = instagram;
