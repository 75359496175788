import axios from 'axios';
import {
  ICostBreakdown, IShippingRateDetails, ICartItem,
} from '~/store/types';

const fightCampBackendLink = process.env.VUE_APP_API_URL;

interface IOrderShippingDetails {
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  phone: string;
}

export interface IOrderRequestBody {
  order: {
    currency: string;
    email: string;
    itemList: ICartItem[];
    shippingDetails: IOrderShippingDetails;
    tags?: string[];
  };
  shippingRateDetails: IShippingRateDetails | null;
  costBreakdown: ICostBreakdown;
  customerId: string | null;
  setupIntentId: string | null;
  draftOrderId: string;
  paymentMethod: string ;
}

type CreateOrderParams = {
 requestBody: IOrderRequestBody;
}

export interface IOrdersService {
  getOrder(paymentIntentId: string): Promise<any>;
  createOrder({ requestBody }: CreateOrderParams): Promise<any>;
  updateOrder({ paymentIntentId, requestBody }: { paymentIntentId: string; requestBody: any }): Promise<any>;
  confirmOrder({ requestBody }: { requestBody: any }): Promise<any>;
  getCustomer({ email, id }: { email?: string; id?: string }): Promise<any>;
  createCustomer(requestBody: any): Promise<any>;
  updateCustomer({ id, requestBody }: { id: string; requestBody: any }): Promise<any>;
  createSetupIntent({ customerId, paymentMethodId }: {customerId: string; paymentMethodId: string}): Promise<any>;
}

// TODO write tests now and mock axios
export default {
  getOrder(paymentIntentId: string) {
    return axios.get(`${fightCampBackendLink}/orders?paymentIntentId=${paymentIntentId}`, {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  createOrder({ requestBody }: CreateOrderParams) {
    console.log('requestBody', requestBody);
    return axios.post(`${fightCampBackendLink}/orders`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  updateOrder({ paymentIntentId, requestBody }) {
    return axios.put(`${fightCampBackendLink}/orders/${paymentIntentId}`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  confirmOrder({ requestBody }) {
    return axios.post(`${fightCampBackendLink}/orders/confirm`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  getCustomer({ email = '', id = '' }: { email?: string, id?: string } = {}) {
    /*
      The email parameter is passed to encodeURIComponent to ensure that any special characters
      (including '+') are properly encoded. This is necessary because in URLs, '+' is interpreted as a space.
      So, if you want to include an actual '+' character in a URL, you need to URL encode it as '%2B'.
      encodeURIComponent takes care of this encoding for us.
    */

    if (!email && !id) {
      throw new Error('Either email or id must be provided');
    } else {
      const queryParam = email ? `email=${encodeURIComponent(email)}` : `id=${encodeURIComponent(id)}`;
      return axios.get(`${fightCampBackendLink}/customers?${queryParam}`, {
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
      });
    }
  },
  createCustomer(requestBody) {
    return axios.post(`${fightCampBackendLink}/customers`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  updateCustomer({ id, requestBody }: { id: string; requestBody: any }) {
    return axios.put(`${fightCampBackendLink}/customers/${id}`, JSON.stringify(requestBody), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
  createSetupIntent({ customerId, paymentMethodId }: {customerId: string; paymentMethodId: string}) {
    return axios.post(`${fightCampBackendLink}/setup-intents`, JSON.stringify({ customerId, paymentMethodId }), {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    });
  },
};
