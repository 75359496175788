export const fightcampLaundryBag = {
  title: 'Laundry Bag',
  id: 'fightcamp_laundry_bag',
  price: 900,
  category: 'accessory',
  description: 'Throw your quick wraps in the Wrap Laundry Bag to prevent them from getting tangled in the wash.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-laundry-bag-001.png',
  types: ['Care']
};
