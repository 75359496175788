//
//
//
//
//
//
//
//
//
//
//
//

import PunchGame from '~/components/error/PunchGame.vue';
import storeMutations from '~/store/constants/storeMutations';

export default {
  name: 'error',
  components: {
    PunchGame,
  },
  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: null,
        message: null,
      }),
    },
  },
  mounted() {
    this.$store.commit(storeMutations.SET_MODAL_SUPPRESSION, true);
  },
  destroyed() {
    this.$store.commit(storeMutations.SET_MODAL_SUPPRESSION, false);
  },
  computed: {
    errorMsg() {
      return this.error.statusCode === 404
        ? 'Sorry, the page you were looking for doesn\'t exist.'
        : this.error.message;
    },
  },
};
