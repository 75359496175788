export const fightcampWaterBottle = {
  title: 'Premium Water Bottle',
  id: 'fightcamp_water_bottle',
  price: 1900,
  category: 'accessory',
  description: 'Keep hydrated during even the most intense workouts with our premium 16oz water bottle.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-water-bottle-001.png',
  types: ['Training']
};
