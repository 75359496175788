export default function toggleScroll(disableScroll: boolean = false) {
  const body = document?.getElementsByClassName('layout-body')[0] as HTMLElement;
  const html = document?.documentElement as HTMLElement;

  if (disableScroll) {
    body.classList.add('no-scroll');
    html.classList.add('no-scroll');
  } else {
    body.classList.remove('no-scroll');
    html.classList.remove('no-scroll');
  }
}
