<template>
  <div :class="[getContainerClass, {'fc-button__container--disabled': isDisabled}]">
    <button
      type="button"
      class="fc-button"
      :class="[widthClass, themeClass]"
      :aria-label="ariaLabel"
      @click="emitCustomEvent">
      <span v-if="loading" class="progess-container">
        <span class="progress fc-inline-block" />
      </span>
      <slot />
    </button>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, PropType } from '@vue/composition-api';
  import { ButtonSizes, ButtonAlignment, ButtonThemes } from './types';

  export const BUTTON_TEXT_SIZES: ButtonSizes = {
    small: 'button-small',
    big: 'button-med',
    default: '',
  };

  export const BUTTON_ALIGNMENT: ButtonAlignment = {
    left: 'fc-button--left',
    center: '',
    right: 'fc-button--right',
  };

  export default defineComponent({
    name: 'FCButton',
    props: {
      loading: {
        type: Boolean,
        default: false,
        required: false,
      },
      buttonTheme: {
        type: String as PropType<ButtonThemes>,
        required: false,
        default: 'button-primary',
      },
      customEventName: {
        type: String,
        required: true,
      },
      buttonSize: {
        type: String,
        required: false,
        default: 'default',
      },
      buttonAlignment: {
        type: String,
        required: false,
        default: 'left',
      },
      ariaLabel: {
        type: String,
        required: false,
        default: null,
      },
      fullWidth: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props, context) {
      const isDisabled = computed(() => props.buttonTheme === 'button-disabled' || props.loading);

      const themeClass = computed(() => {
        if (props.buttonTheme === 'button-text') return props.buttonTheme;
        if (isDisabled.value) return `button-layout button-disabled ${BUTTON_TEXT_SIZES[props.buttonSize as keyof ButtonSizes]} fc-button--disabled`;

        return `button-layout ${props.buttonTheme} ${BUTTON_TEXT_SIZES[props.buttonSize as keyof ButtonSizes]}`;
      });

      return {
        emitCustomEvent() {
          context.emit(props.customEventName);
        },
        isDisabled,
        getContainerClass: computed(() => (props.fullWidth ? 'fc-block' : 'fc-flex')),
        widthClass: computed(() => (props.fullWidth ? 'fc-button--full-width' : (BUTTON_ALIGNMENT)[props.buttonAlignment as keyof ButtonAlignment])),
        themeClass,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import "../../styles/exports";

  .fc-button {
    &__container--disabled {
      cursor: not-allowed;
    }

    &--full-width {
      width: 100%;
    }

    &--disabled {
      pointer-events: none;
    }

    &--left {
      margin: get-spacing-level(0) auto get-spacing-level(0) get-spacing-level(0);
    }

    &--right {
      margin: get-spacing-level(0) get-spacing-level(0) get-spacing-level(0) auto;
    }

    &-loading {
      margin: get-spacing-level(0) 3px get-spacing-level(0) -8px;
    }
  }

  .button-text {
    background: none;
    border: none;
  }

  .progess-container {
    margin: get-spacing-level(0) 3px get-spacing-level(0) -8px;
  }

  .progress {
    width: 12px;
    height: 12px;
  }

  .progress::after {
    content: " ";
    display: block;
    width: 9px;
    height: 9px;
    margin: get-spacing-level(0);
    border-radius: 50%;
    border: 2px solid $color-white;
    border-color: $color-white transparent $color-white transparent;
    animation: progress 1.2s linear infinite;
  }

  @keyframes progress {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
