export const fightcampBagRingBpi = {
  title: 'FightCamp Bag Ring',
  id: 'fightcamp_bag_ring_bpi',
  price: 9900,
  category: 'accessory',
  description: 'The Bag Ring keeps the FightCamp Boxing Bag directly in front of you. No more sliding or adjusting the free-standing bag in between rounds.',
  path: '/shop/equipment',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-equipment-bag-ring-001.jpg',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-bagring-001.png',
  types: ['Setup']
};
