// eslint-disable-next-line import/no-extraneous-dependencies
import { ImageOptions, ProviderGetImage } from '@nuxt/image';
import { withBase, joinURL, parseURL } from 'ufo';

const storyblokCDN = 'https://a.storyblok.com';

export const getImage: ProviderGetImage = (src: string, { modifiers = {}, baseURL: _ }: ImageOptions = {}) => {
  const {
    fit,
    smart,
    width = '0',
    height = '0',
    filters = {},
    format,
    quality,
  } = modifiers;

  const doResize = width !== '0' || height !== '0';
  if (format) {
    filters.format = `${format}`;
  }
  if (quality) {
    filters.quality = `${quality}`;
  }
  const _filters = Object.entries(filters || {}).map((e) => `${e[0]}(${e[1]})`).join(':');
  const options = joinURL(
    fit ? `fit-${fit}` : '',
    doResize ? `${width}x${height}` : '',
    smart ? 'smart' : '',
    _filters ? `filters:${_filters}` : '',
  );
  const { pathname } = parseURL(src);
  const url = withBase(joinURL(pathname, '/m/', options), storyblokCDN);

  return {
    url,
  };
};
