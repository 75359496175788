
import { defineComponent, computed } from '@vue/composition-api';
// import { ShortTextProps } from './types';

export default defineComponent({
  name: 'ShortText',
  props: {
    text: {
      type: String,
      required: true,
    },
    textTheme: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: true,
    },
    isBold: {
      type: Boolean,
      default: false,
    },
    textAlignment: {
      type: String,
      default: 'fc-text-center',
    },
    isUpperCase: {
      type: Boolean,
      default: false,
    },
    paddingProperties: {
      type: Object,
      default: () => {},
    },
    elementName: {
      type: String,
      default: 'p',
    },
    maxWidth: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    return {
      element: computed(() => (props.elementName ? props.elementName : 'p')),
      styles: computed(() => ({
        maxWidth: props.maxWidth,
      })),
      getShortTextClasses: computed(() => {
        const classes = [] as string[];

        if (props.isUpperCase) classes.push('fc-uppercase');
        if (props.isBold) classes.push('fc-bold');
        if (props.paddingProperties?.paddingTop) classes.push(props.paddingProperties.paddingTop);
        if (props.paddingProperties?.paddingBottom) classes.push(props.paddingProperties.paddingBottom);
        if (props.paddingProperties?.paddingLeft) classes.push(props.paddingProperties.paddingLeft);
        if (props.paddingProperties?.paddingRight) classes.push(props.paddingProperties.paddingRight);

        classes.push(`fc-text-${props.textColor}`);
        classes.push(props.textAlignment);
        classes.push(`fc-${props.textTheme}`);

        return classes;
      }),
    };
  },
});
