export default {
  title: 'FightCamp Bag Ring',
  id: 'fightcamp_bag_ring',
  price: 10900,
  category: 'accessory',
  description: '',
  path: '/shop/equipment',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-equipment-bag-ring-001.jpg',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-bagring-001.png',
};
