export default {
  title: 'Conditioning Kit',
  id: 'fightcamp_conditioning_kit',
  price: 15900,
  category: 'accessory',
  description: '',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-equipment-conditioning-kit-002.png',
  types: ['Bundles'],
};
