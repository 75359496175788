import { MembershipIds } from '@/shared/constants/MembershipIds';

const Bundles = [
  {
    id: 'DVG2L5',
    items: [
      {
        id: getFreeStandingBagId(),
        type: 'equip',
      },
    ],
  },
  {
    id: 'A63H6Z',
    items: [
      {
        id: getProtectFloorBundleId(),
        type: 'equip',
      },
    ],
  }, {
    id: 'G4D5KL',
    items: [
      {
        id: getFreeStandingBagId(),
        type: 'equip',
      },
      {
        id: getProtectFloorBundleId(),
        type: 'equip',
      },
    ],
  },
  {
    id: 'M0P0O4',
    items: [
      {
        id: 'FightCamp_1_MonthB',
        type: 'subs',
      },
    ],
  },
  {
    id: 'R8AVJZ',
    items: [
      {
        id: getFreeStandingBagId(),
        type: 'equip',
      },
      {
        id: getBagRingId(),
        type: 'equip',
      },
    ],
  },
  {
    id: 'GCHLS3',
    items: [
      {
        id: getTransferredMembershipId(),
        type: 'subs',
      }
    ],
  },
];

function getTransferredMembershipId() {
  if (process.env.NUXT_ENV_SHOPIFY_ON === 'true' && process.env.NUXT_ENV_IS_DEV === 'true') {
    return 'gid://shopify/ProductVariant/40306989334617'
  }

  if (process.env.NUXT_ENV_SHOPIFY_ON === 'true' && process.env.NUXT_ENV_IS_PROD === 'true') {
    return 'gid://shopify/ProductVariant/45113935855852'
  }

  return MembershipIds.TRANSFERRED;
}

function getFreeStandingBagId() {
  if (process.env.NUXT_ENV_SHOPIFY_ON === 'true' && process.env.NUXT_ENV_IS_DEV === 'true') {
    return 'gid://shopify/ProductVariant/40584970469465'
  }

  if (process.env.NUXT_ENV_SHOPIFY_ON === 'true' && process.env.NUXT_ENV_IS_PROD === 'true') {
    return 'gid://shopify/ProductVariant/45113921437932'
  }

  return 'freeStandingBag_V2';
}

function getProtectFloorBundleId() {
  if (process.env.NUXT_ENV_SHOPIFY_ON === 'true' && process.env.NUXT_ENV_IS_DEV === 'true') {
    return 'gid://shopify/ProductVariant/40043190157401'
  }

  if (process.env.NUXT_ENV_SHOPIFY_ON === 'true' && process.env.NUXT_ENV_IS_PROD === 'true') {
    return 'gid://shopify/ProductVariant/42890469671148'
  }

  return 'fightcamp_bundle_protect_floor';
}

function getBagRingId() {
  if (process.env.NUXT_ENV_SHOPIFY_ON === 'true' && process.env.NUXT_ENV_IS_DEV === 'true') {
    return 'gid://shopify/ProductVariant/40045135855705'
  }

  if (process.env.NUXT_ENV_SHOPIFY_ON === 'true' && process.env.NUXT_ENV_IS_PROD === 'true') {
    return 'gid://shopify/ProductVariant/42890543726828'
  }

  return 'fightcamp_bag_ring_bpi';
}

function getParameterByName(_name, _url) {
  let url = _url;
  let name = _name;
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default {
  getBundle(url) {
    const bundleId = getParameterByName('bundle', url);
    const isShopifyId = /^\d+$/.test(bundleId); // checks if id is only numbers

    if (isShopifyId) {
      return [
        {
          id: `gid://shopify/ProductVariant/${bundleId}`,
          type: 'equip',
        }
      ]
    }

    if (!bundleId) {
      return null;
    }
    const bundle = Bundles.find(b => b.id === bundleId);
    if (!bundle) {
      return null;
    }
    return bundle.items;
  },
  getCoupon(url) {
    return getParameterByName('coupon', url);
  },
  getSurvey(url) {
    return getParameterByName('svid', url);
  },
  // for Customer Dashboard Console + Trackers Upgrade Promotion
  getStripeCustomerID(url) {
    return getParameterByName('id', url);
  }
};
