export default {
  title: 'Punch Tracker Sleeves',
  id: 'fightcamp_tracker_sleeves',
  price: 1400,
  category: 'accessory',
  description: 'Unlock the ability to use your FightCamp trackers with traditional wraps.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-punch-sleeves-001.png',
};
