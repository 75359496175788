<template>
  <section
    role="dialog"
    class="announcement fc-text-center fc-flex fc-column fc-align-center fc-justify-center"
    :class="`announcement__theme-${theme} fc-bg-theme__${theme}`"
    @click="$emit('sectionClicked')">
    <slot />
  </section>
</template>

<script lang="ts">
  import { PropType, defineComponent } from '@vue/composition-api';
  import { BackgroundTheme } from '../../utils/global';

  export default defineComponent({
    name: 'Announcement',
    props: {
      theme: {
        type: String as PropType<BackgroundTheme>,
        default: BackgroundTheme.red,
        required: false,
        validator: (theme: BackgroundTheme) => !!BackgroundTheme[theme],
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import "../../styles/exports";
  @import "../../styles/utilities/typography";

  .announcement {
    min-height: 2.75rem;
    padding: get-spacing-level(4);

    a {
      font-family: $font-stack-regular;
      margin: 0;
    }

    & &__heading {
      @extend .fc-uppercase;
      @extend .fc-heading-3;

      margin-top: get-spacing-level(1);
      margin-bottom: get-spacing-level(2);
    }
  }
</style>
