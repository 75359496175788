<template>
  <svg
    class="fc-cart"
    :class="{'fc-cart--hover': changeColorOnHover}"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    aria-labelledby="cart icon"
    fill="none"
    role="presentation">
    <title
      id="cart icon"
      lang="en">cart icon</title>
    <g id="Icon Base">
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8846 3.93843V6.40191C14.657 5.82563 18.1562 5.92554 21.1154 6.34417V3.9503C20.7686 3.90633 20.3708 3.85771 19.946 3.80912C18.5518 3.64962 16.9464 3.5 15.8882 3.5C14.7742 3.5 13.2247 3.64941 11.9078 3.80807C11.5372 3.85272 11.1906 3.89739 10.8846 3.93843ZM22.8295 1.15303C23.5676 1.25948 24.1154 1.89196 24.1154 2.63767V6.90983C26.0317 7.36639 27.5829 7.93115 28.6533 8.44902C29.1711 8.69954 29.5 9.22408 29.5 9.79929C29.5 13.4172 29.362 18.5329 28.8743 22.7675C28.632 24.8711 28.2944 26.842 27.8122 28.32C27.5739 29.0502 27.2681 29.7638 26.8507 30.3235C26.436 30.8797 25.7377 31.5 24.7255 31.5L7.27448 31.5C6.26232 31.5 5.56395 30.8797 5.14925 30.3235C4.73191 29.7637 4.42605 29.0502 4.18779 28.32C3.70555 26.842 3.36802 24.8711 3.12572 22.7675C2.63798 18.5329 2.5 13.4172 2.5 9.79928C2.5 9.18267 2.87735 8.62887 3.45122 8.40328C4.94812 7.81483 6.43054 7.35209 7.88461 6.99649V2.63767C7.88461 1.89862 8.42292 1.2697 9.15311 1.15564L9.16704 1.15348L9.20394 1.1478L9.34245 1.12684C9.46214 1.1089 9.63482 1.08344 9.84919 1.05296C10.2776 0.992068 10.8744 0.910877 11.549 0.829604C12.8786 0.669424 14.5809 0.5 15.8882 0.5C17.1358 0.5 18.894 0.669211 20.287 0.828555C20.9949 0.909535 21.6288 0.990427 22.0858 1.05107C22.3145 1.08141 22.4993 1.10675 22.6275 1.12456L22.7757 1.14536L22.8295 1.15303ZM5.50377 10.8376C5.52863 14.2963 5.68129 18.7367 6.10602 22.4242C6.34189 24.472 6.65275 26.2031 7.03981 27.3894C7.22574 27.9592 7.40057 28.3115 7.53247 28.5L24.4675 28.5C24.5994 28.3115 24.7743 27.9592 24.9602 27.3894C25.3473 26.2031 25.6581 24.472 25.894 22.4242C26.3211 18.7155 26.4731 14.2453 26.4966 10.7781C22.7707 9.29493 14.5054 7.63031 5.50377 10.8376Z"
        fill="#0F1E3B" />
    </g>
    <circle
      v-show="isItemInCart && displayQty"
      cx="26"
      cy="8"
      r="8" />
    <text
      v-show="isItemInCart && displayQty"
      x="26"
      y="10"
      text-anchor="middle"
      alignment-baseline="middle"> {{ cartQty }} </text>
  </svg>
</template>

<script lang="ts">
  import { defineComponent, computed } from '@vue/composition-api';
  import { FCCartProps } from './types';

  export default defineComponent({
    name: 'FCCart',
    props: {
      displayQty: {
        type: Boolean,
        required: false,
        default: false,
      },
      width: {
        type: String,
        required: false,
        default: '30px',
      },
      height: {
        type: String,
        required: false,
        default: '30px',
      },
      viewBox: {
        type: String,
        required: false,
        default: '0 0 53.052 47.174',
      },
      cartQty: {
        type: Number,
        required: false,
        default: 0,
      },
      changeColorOnHover: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    setup(props: FCCartProps) {
      const isItemInCart = computed(() => (props?.cartQty ?? 0) > 0);

      return {
        isItemInCart,
      };
    },
  });
</script>

<style scoped lang="scss">
@import "../../styles/exports";

.fc-cart {
  path {
    fill: $color-black;
  }

  &--hover {
    &:hover {
      path {
        fill: $color-red;
      }
    }
  }

  circle {
    fill: $color-red;
  }

  text {
    font: get-font-size-level(4) $font-stack-heading;
    text-anchor: middle;
    fill: $color-white;
  }
}
</style>
