import Vue from 'vue';
import debounce from 'lodash/debounce';
import {
  desktopBreakpoint,
  mobileBreakpoint,
  tabletOnlyBreakpoint,
  tabletUpBreakpoint,
  // @ts-ignore
} from '@fc/angie-ui/dist/styles/_js_exports.scss';

/* eslint-disable-next-line */
export const windowMatchMediaMixin = Vue.extend({
  data() {
    return {
      mixinMatchMedia: {
        mobileMatcher: {} as MediaQueryList,
        tabletOnlyMatcher: {} as MediaQueryList,
        tabletUpMatcher: {} as MediaQueryList,
        desktopMatcher: {} as MediaQueryList,
        isMobile: false,
        isTabletOnly: false,
        isTabletUp: false,
        isDesktop: false,
      },
    };
  },
  methods: {
    debouncedMatchMedia() { },
    removeMQListener(mq: MediaQueryList, cb: () => void) {
      if (mq.removeEventListener) {
        mq.removeEventListener('change', cb);
      } else {
        mq.removeListener(cb);
      }
    },
    addMQListener(mq: MediaQueryList, cb: () => void) {
      if (mq.addEventListener) {
        mq.addEventListener('change', cb);
      } else {
        mq.addListener(cb);
      }
    },
    handleMatchMedia() {
      this.mixinMatchMedia.isMobile = this.mixinMatchMedia.mobileMatcher.matches;
      this.mixinMatchMedia.isTabletOnly = this.mixinMatchMedia.tabletOnlyMatcher.matches;
      this.mixinMatchMedia.isTabletUp = this.mixinMatchMedia.tabletUpMatcher.matches;
      this.mixinMatchMedia.isDesktop = this.mixinMatchMedia.desktopMatcher.matches;
    },
  },
  mounted() {
    this.mixinMatchMedia.mobileMatcher = window.matchMedia(mobileBreakpoint);
    this.mixinMatchMedia.tabletOnlyMatcher = window.matchMedia(tabletOnlyBreakpoint);
    this.mixinMatchMedia.tabletUpMatcher = window.matchMedia(tabletUpBreakpoint);
    this.mixinMatchMedia.desktopMatcher = window.matchMedia(desktopBreakpoint);

    this.handleMatchMedia();
    this.debouncedMatchMedia = debounce(this.handleMatchMedia, 150);

    this.addMQListener(this.mixinMatchMedia.mobileMatcher, this.debouncedMatchMedia);
    this.addMQListener(this.mixinMatchMedia.tabletOnlyMatcher, this.debouncedMatchMedia);
    this.addMQListener(this.mixinMatchMedia.tabletUpMatcher, this.debouncedMatchMedia);
    this.addMQListener(this.mixinMatchMedia.desktopMatcher, this.debouncedMatchMedia);
  },
  destroyed() {
    this.removeMQListener(this.mixinMatchMedia.mobileMatcher, this.debouncedMatchMedia);
    this.removeMQListener(this.mixinMatchMedia.tabletOnlyMatcher, this.debouncedMatchMedia);
    this.removeMQListener(this.mixinMatchMedia.tabletUpMatcher, this.debouncedMatchMedia);
    this.removeMQListener(this.mixinMatchMedia.desktopMatcher, this.debouncedMatchMedia);
  },
});
