import {
  onBeforeUnmount, onMounted, ref, Ref,
} from '@vue/composition-api';
import { CarouselMode } from '../types';

export interface TimerControllerOptions {
  slider: Ref<HTMLElement | undefined>;
  mode?: CarouselMode;
}

export function useTimerController({ slider, mode }: TimerControllerOptions): Ref<boolean> {
  const isTimerEnabled = ref<boolean>(false);

  onMounted(() => {
    if (slider.value && mode === CarouselMode.TIMER_ON_HOVER) {
      slider.value.addEventListener('mouseenter', enableTimer);
      slider.value.addEventListener('mouseleave', disableTimer);
    } else if (mode === CarouselMode.TIMER_ON_LOAD && isTabletUp()) {
      enableTimer();
    }
  });

  onBeforeUnmount(() => {
    if (slider.value) {
      slider.value.removeEventListener('mouseenter', enableTimer);
      slider.value.removeEventListener('mouseleave', disableTimer);
    }
  });

  function isTabletUp(): boolean {
    return window.matchMedia('(min-width: 1025px)').matches;
  }

  function enableTimer(): void {
    isTimerEnabled.value = true;
  }

  function disableTimer(): void {
    isTimerEnabled.value = false;
  }

  return isTimerEnabled;
}
