//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@vue/composition-api';
/*
  TODO sperate the icon from SocialIcons component so there will be two components
  1. Icon component (unders /Atoms)
  2. IconGroup (under /Molecules)
  based off of https://gumdrops.gumgum.com/?path=/story/atoms-icon--default
*/
export default {
  name: 'SocialIcons',
  props: {
    icons: {
      type: Array,
      required: true,
      validator(icons) {
        return icons.every(icon => icon.name && icon.url);
      },
    },
    bgColor: {
      type: String,
      default: 'blue',
      validator(bgColor) {
        return bgColor === 'red' || bgColor === 'blue';
      },
    },
    iconSize: {
      type: String,
      default: 'default',
      validator(iconSize) {
        return iconSize === 'default' || iconSize === 'big' || iconSize === 'small';
      },
    },
    prefixAriaLabel: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const iconData = {
      facebook: ['fab', 'facebook-f'],
      instagram: ['fab', 'instagram'],
      youtube: ['fab', 'youtube'],
      pinterest: ['fab', 'pinterest'],
      linkedin: ['fab', 'linkedin-in'],
      twitter: ['fab', 'twitter'],
      website: ['fa', 'desktop'],
      tiktok: ['fab', 'tiktok'],
      rss: ['fas', 'rss'],
    };

    const iconsToDisplay = computed(() => props.icons.filter(icon => iconData[icon.name])
      .map(icon => ({
        ...icon,
        icon: iconData[icon.name],
      })));

    function getAriaLabel(iconName) {
      return props.prefixAriaLabel ? `${props.prefixAriaLabel} ${iconName}`: iconName;
    }

    return {
      iconsToDisplay,
      getBgColor: computed(() => (props.bgColor === 'red' ? 'fc-bg-red' : 'fc-bg-blue')),
      iconBubbleSize: computed(() => `social-icons__bubble--${props.iconSize}`),
      iconSvgSize: computed(() => `social-icons__icon--${props.iconSize}`),
      getAriaLabel,
    };
  },
};
