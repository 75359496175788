<template>
  <div class="navigation-arrows">
    <button
      class="navigation-arrows__button--left"
      :class="`navigation-arrows__button--${arrowNavigationTheme}`"
      type="button"
      @click="$emit('slideSelect', getLastSlide(), {
        ignoreInfiniteMode: true,
      })">
      <font-awesome-icon class="navigation-arrows__icon" :icon="['fas', 'chevron-left']" />
    </button>
    <button
      class="navigation-arrows__button--right"
      :class="`navigation-arrows__button--${arrowNavigationTheme}`"
      type="button"
      @click="$emit('slideSelect', getNextSlide(), {
        ignoreInfiniteMode: true,
      })">
      <font-awesome-icon class="navigation-arrows__icon" :icon="['fas', 'chevron-right']" />
    </button>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent, ref, onMounted,
  } from '@vue/composition-api';
  import { NavigationArrowsProps } from './types';

  export default defineComponent({
    name: 'NavigationArrows',
    props: {
      slides: {
        type: Array,
        default() {
          return [];
        },
      },
      currentSlide: {
        type: Number,
        default: -1,
      },
      isHidden: {
        type: Boolean,
        default: false,
      },
      arrowNavigationTheme: {
        type: String,
        default: 'dark-gray',
      },
    },
    setup(props: NavigationArrowsProps) {
      const isTabletUp = ref(false);
      const slideCount = ref(props.currentSlide);

      function getNextSlide() {
        if (slideCount.value === (props.slides.length - 1)) {
          slideCount.value = 0;
          return 0;
        }
        slideCount.value += 1;
        return slideCount.value;
      }

      function getLastSlide() {
        if (slideCount.value === (props.slides.length - 1)) {
          slideCount.value = 0;
          return 0;
        }

        if (slideCount.value === 0) {
          slideCount.value = props.slides.length -1;
          return slideCount.value;
        }

        slideCount.value -= 1;
        return slideCount.value;
      }

      onMounted(() => {
        isTabletUp.value = window.matchMedia('(min-width: 1025px)').matches;
      });

      return {
        isTabletUp,
        slideCount,
        getNextSlide,
        getLastSlide,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import "../../styles/exports";

  .navigation-arrows {
    height: 100%;
    width: 100%;
    position: absolute;

    &__button {
      &--dark-gray {
        color: $color-dark-gray;

        &:hover {
          @media #{$desktop} {
            color: $color-egg-shell;
          }
        }
      }

      &--blue {
        color: $color-blue;

        &:hover {
          @media #{$desktop} {
            color: $color-dark-gray;
          }
        }
      }

      &--left {
        height: 80%;
        position: absolute;
        z-index: 5;
        background: none;
        border: none;
        cursor: pointer;
        outline: inherit;
        padding-left: 1rem;
      }

      &--right {
        padding-right: 1rem;
        height: 80%;
        right: 0;
        position: absolute;
        z-index: 5;
        background: none;
        border: none;
        cursor: pointer;
        outline: inherit;
      }
    }

    &__icon {
      height: 2rem;
    }
  }
</style>
