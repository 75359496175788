function arrayToObject(fields) {
  return fields.reduce((prev, path) => {
    const key = path.split('.').slice(-1)[0];

    return {
      ...prev,
      [key]: path,
    };
  }, {});
}

export default {
  mapInputBinding(fields) {
    const mapped = {};
    fields.forEach((field) => {
      mapped[field] = {
        get() {
          return this.$store.state.checkout[field];
        },
        set(value) {
          this.$store.commit(`update${field}`, value);
        },
      };
    });
    return mapped;
  },
  mapInputMutations(fields) {
    const mapped = {};
    fields.forEach((field) => {
      mapped[`update${field}`] = (state, val) => {
        state[field] = val;
      };
    });
    return mapped;
  },
  /**
   * Adds two way binding to input fields and the vuex store to be used with v-model.
   * @param {*} inputs
   * @returns {Array} Bound inputs with a getter and setter.
   */
  mapInputs(inputs) {
    const shippingObj = Array.isArray(inputs) ? arrayToObject(inputs) : inputs;

    return Object.keys(shippingObj).reduce((prev, key) => {
      const path = shippingObj[key];
      const field = {
        get() {
          return this.$store.getters.getField(path);
        },
        set(value) {
          this.$store.commit('SET_FIELD', { path, value });
        },
      };

      // eslint-disable-next-line no-param-reassign
      prev[key] = field;

      return prev;
    }, {});
  },
};
