const attribution = require('./utils/attribution');

const magazine = {
  id: 'Magazine',
  question: 'Which magazine was it?',
  type: 'input',
  attribution: attribution.PR,
};

module.exports = magazine;
