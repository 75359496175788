const attribution = require('./attribution');
const otherOption = require('../utils/other');
const unknownChannel = require('./unknownChannel');

module.exports = prefix => ({
  id: `${prefix}`,
  question: 'What was the first video you saw from the FightCamp Channel?',
  type: 'list',
  options: [
    {
      id: 'Youtube Shorts',
      label: 'Youtube Shorts',
      attribution: attribution.OrganicYoutube,
    },
    {
      id: 'Full Workout',
      label: 'Full Workout',
      attribution: attribution.OrganicYoutube,
    },
    {
      id: 'Fitness Tutorial',
      label: 'Fitness Tutorial',
      attribution: attribution.OrganicYoutube,
    },
    {
      id: 'Drill',
      label: 'Drill',
      attribution: attribution.OrganicYoutube,
    },
    {
      id: 'Combo',
      label: 'Combo',
      attribution: attribution.OrganicYoutube,
    },
    {
      id: 'Stretching',
      label: 'Stretching',
      attribution: attribution.OrganicYoutube,
    },
    {
      id: 'Equipment Reviews',
      label: 'Equipment Reviews',
      attribution: attribution.OrganicYoutube,
    },
    {
      id: 'Equipment Tutorials',
      label: 'Equipment Tutorials',
      attribution: attribution.OrganicYoutube,
    },
    otherOption({ id: 'FightCamp Youtube Other', attribution: attribution.UTM, followup:  unknownChannel('FightCamp Youtube') }),
  ],
});
