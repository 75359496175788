/* eslint-disable */
const EMAIL_VALIDATOR = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;

const IMAGE_URL_VALIDATOR = /^.*\.(jpg|jpeg|png|webp)$/i;

const EMPTY_STRING_VALIDATOR = new RegExp('(?![\s\S])');
const CSS_LENGTH_UNITS_VALIDATOR = new RegExp('(-?\d*\.?\d+(%|px|rem|vh|vw)|0)');

const VALID_CSS_UNITS_VALIDATOR = new RegExp(`^${[
  EMPTY_STRING_VALIDATOR.source,
  '(auto)',
  CSS_LENGTH_UNITS_VALIDATOR.source,
].join('|')}$`, 'g')

const FOUR_DIGITS_VALIDATOR = new RegExp('([0-9]{0,4})', 'g');
const IMAGE_UNITS_VALIDATOR = new RegExp('(rem|px|vw)', 'g');
const IMAGE_PROVIDER_SIZES_VALIDATOR = new RegExp('(xs|sm|md|lg|xl|xxl|2xl)', 'g');

const IMAGE_DIMENSIONS_VALIDATOR = new RegExp(`${FOUR_DIGITS_VALIDATOR.source}${IMAGE_UNITS_VALIDATOR.source}`, 'g');
const IMAGE_SIZES_QUERY_VALIDATOR = new RegExp(`${IMAGE_PROVIDER_SIZES_VALIDATOR.source}:[ ]*${IMAGE_DIMENSIONS_VALIDATOR.source}`, 'g');

const STORYBLOK_IMAGE_DIMENSIONS = new RegExp('([0-9]{0,})x([0-9]{0,})', 'g');

export {
  EMAIL_VALIDATOR,
  IMAGE_URL_VALIDATOR,
  FOUR_DIGITS_VALIDATOR,
  IMAGE_UNITS_VALIDATOR,
  IMAGE_PROVIDER_SIZES_VALIDATOR,
  IMAGE_DIMENSIONS_VALIDATOR,
  IMAGE_SIZES_QUERY_VALIDATOR,
  STORYBLOK_IMAGE_DIMENSIONS,
  EMPTY_STRING_VALIDATOR,
  CSS_LENGTH_UNITS_VALIDATOR,
  VALID_CSS_UNITS_VALIDATOR,
};
