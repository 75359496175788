// eslint-disable-next-line import/no-extraneous-dependencies
import { Context } from '@nuxt/types';
import navTypes from '~/store/constants/navTypes';
import storeMutations from '~/store/constants/storeMutations';
import syncCart from './_syncCart';

export default async function checkout({
  store,
  redirect,
  route,
  from,
}: Context) {
  if ((from ?? {}).name === 'shop-checkout' && route.name === 'shop-confirmation') return null;

  const { queryItems } = await syncCart({ route, store });
  if (!queryItems && store.state.checkout.cart.length === 0 && store.state.checkout.cartSyncCompleted) {
    return redirect('/shop');
  }

  if (route.name === 'shop-gq-checkout' || route.name === 'shop-partner-checkout') {
    store.commit(storeMutations.SET_NAV_TYPE, navTypes.gq);
    return null;
  }

  store.commit(storeMutations.SET_NAV_TYPE, navTypes.checkout);
  return null;
}
