function throttle(callback, delay) {
  let timeout;
  let lastState;
  let currentArgs;
  const throttled = (state, ...args) => {
    currentArgs = args;
    if (timeout && state === lastState) return;
    lastState = state;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(state, ...currentArgs);
      timeout = 0;
    }, delay);
  };
  throttled.clear = () => {
    clearTimeout(timeout);
  };
  return throttled;
}

export default class VisibilityState {
  constructor(el) {
    this.el = el;
    this.observer = null;
    this.frozen = false;
  }

  get threshold() {
    return (this.options.intersection && this.options.intersection.threshold) || 0;
  }

  createObserver(options, vnode) {
    if (this.observer) {
      this.destroyObserver();
    }

    if (this.frozen) return;

    this.options = options;

    this.callback = (result, entry) => {
      try {
        this.options.callback(result, entry, this.options.value);
        if (result && this.options.once) {
          this.frozen = true;
          this.destroyObserver();
        }
      } catch (error) {
        console.error(error);
      }
    };
    // Throttle
    if (this.callback && this.options.throttle) {
      this.callback = throttle(this.callback, this.options.throttle);
    }

    this.oldResult = undefined;

    this.observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (this.callback) {
        // Use isIntersecting if possible because browsers can report isIntersecting as true, but intersectionRatio as 0, when something very slowly enters the viewport.
        const result = entry.isIntersecting && entry.intersectionRatio >= this.threshold;
        if (result === this.oldResult) return;
        this.oldResult = result;
        this.callback(result, entry);
      }
    }, this.options.intersection);

    // Wait for the element to be in document
    vnode.context.$nextTick(() => {
      if (this.observer) {
        this.observer.observe(this.el);
      }
    });
  }

  destroyObserver() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }

    // Cancel throttled call
    if (this.callback && this.callback.clear) {
      this.callback.clear();
      this.callback = null;
    }
  }
}
