export default {
  title: 'FightCamp Console',
  id: 'fightcamp_console',
  price: 39900,
  affirmPrice: 39900,
  oldPrice: null,
  monthly: 1663,
  months: 24,
  category: 'package',
  path: '/shop/products/fightcamp-console/',
  internalPath: 'shop/products/fightcamp-console/',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-fightcamp-base-001.png',
  description: 'Our starter package',
  fbid: '',
  gmc_id: '',
};
