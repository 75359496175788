





















import { v4 as uuid } from 'uuid';
import { PropType } from 'vue';
import { FCImageProviderTypes } from '~/utils/helpers.types';
import { windowMatchMediaMixin } from '~/mixins/matchMedia';
import { windowResizeMixin } from '~/mixins/resize';
import { NuxtPictureProps } from './types';

export enum FCImageContainerType {
  block = 'block',
  cover = 'cover',
}
export const FCImageContainerTypeMap: Map<FCImageContainerType, string> = new Map([
  [FCImageContainerType.block, 'fc-image--block'],
  [FCImageContainerType.cover, 'fc-image--cover'],
]);

export default {
  name: 'FCImage',
  mixins: [windowMatchMediaMixin, windowResizeMixin],
  props: {
    fcModifiers: {
      type: Object as PropType<{}>,
      required: false,
      default: () => {},
    },
    fcProvider: {
      type: String as PropType<FCImageProviderTypes>,
      required: false,
      default: FCImageProviderTypes.fcProvider,
    },
    mobilePositioning: {
      type: Boolean,
      default: false,
    },
    displayType: {
      type: String as PropType<FCImageContainerType>,
      required: false,
      default: '',
    },
    nuxtImageProps: {
      type: Object as PropType<Partial<NuxtPictureProps>>,
      required: true,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      key: uuid(),
    };
  },
  computed: {
    provideWebpFormat(): 'webp' | null {
      return this.$device.isSafari ? null : 'webp';
    },
    imageContainerClasses(): string[] {
      return [
        this.imageContainerType ?? '',
        this.mobilePositioning ? 'img-mobile-positioning' : '',
      ];
    },
    imageContainerType(): string | undefined {
      return FCImageContainerTypeMap.get(this.displayType as FCImageContainerType);
    },
    imageProps(): Object {
      const { loading, ...rest } = this.nuxtImageProps ?? {};
      return { ...rest, loading: loading ?? 'lazy' };
    },
  },
};
