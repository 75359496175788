import {
  reactive, ref, InjectionKey, provide, inject, Ref, onUnmounted, computed,
  SetupContext,
} from '@vue/composition-api';

export interface TabData {
  name: string,
}

export interface Tabs {
  [key: string]: TabData,
}

const tabsInjectionKey = Symbol('tabs') as InjectionKey<{
  registerTab: (identifier: string, tabData: TabData) => void,
  deregisterTab: (identifier: string) => void
  activeTab: Ref<string>,
}>;

export const useTabs = (context: SetupContext) => {
  const tabs = reactive<Tabs[]>([]);

  const registerTab = (identifier: string, tabData: TabData) => {
    tabs.push({ [identifier]: tabData });
  };

  const deregisterTab = (identifier: string) => {
    const index = tabs.findIndex(tab => Object.keys(tab)[0] === identifier);
    if (index !== -1) {
      tabs.splice(index, 1);
    }
  };

  const activeTab = ref<string>();

  provide(tabsInjectionKey, {
    registerTab,
    deregisterTab,
    activeTab,
  });

  const setActiveTab = (identifier: string) => {
    activeTab.value = identifier;
    const tabData = tabs.find(tab => Object.keys(tab)[0] === identifier);

    if (!tabData) return;
    const key = Object.keys(tabData)[0];
     context.emit('tabChanged', tabData[key]);
  };

  return {
    tabs,
    setActiveTab,
    activeTab,
  };
};

export const useTab = (tabData: TabData) => {
  const tabsInjection = inject(tabsInjectionKey);

  if (!tabsInjection) {
    throw new Error('Tabs were not provided');
  }

  const { registerTab, deregisterTab, activeTab } = tabsInjection;

  registerTab(tabData.name, tabData);

  onUnmounted(() => {
    deregisterTab(tabData.name);
  });

  const isActive = computed(() => (
    activeTab.value === tabData.name
  ));

  return {
    isActive,
  };
};
