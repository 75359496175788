export default {
  title: "FightCamp Women's Tank Black",
  id: 'fightcamp_womens_tank_black',
  price: 2200,
  category: 'accessory',
  description: 'Made with 100% combed cotton for a lightweight feel, this regular fit tank showcases the FightCamp logo in the center chest. Regular fit.',
  img_url: 'https://a.storyblok.com/f/152150/400x400/59f016aa7e/preview-fc-womens-black-tank-002.jpg',
  fbid: '',
  marketplace_img_url: '',
  types: ['Apparel']
};