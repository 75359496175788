import Vue from 'vue';

import { sectionIdMixin } from '~/mixins/sectionId';

// Make sure to pick a unique name for the flag
// so it won't conflict with any other mixin.

if (!Vue.__sectionId__) {
  Vue.__sectionId__ = true;
  Vue.mixin(sectionIdMixin);
}
