
const attribution = require('./utils/attribution');
const otherOption = require('./utils/other');
const youtubeChannel = require('./utils/channel');

const googleSearchArticle = id => ({
  id: `${id} Publisher`,
  question: 'Who published the article / review?',
  type: 'input',
  attribution: attribution.PR,
});

const googleSearch = id => ({
  id,
  type: 'list',
  question: 'What did you click on?',
  options: [{
    id: `${id} OnlineArticle`,
    label: 'Online Article / Review',
    followup: googleSearchArticle(`${id} OnlineArticle`),
    attribution: attribution.PR,
  }, {
    id: `${id} Youtube`,
    label: 'Youtube Video',
    followup: youtubeChannel(`${id} Youtube`),
    attribution: attribution.UTM,

  }, {
    id: `${id} Ad`,
    label: 'Advertisement',
    attribution: attribution.PaidSearch,
  }, {
    id: `${id} Blog`,
    label: 'FightCamp Blog',
    attribution: attribution.FCBlog,
  }, {
    id: `${id} Website`,
    label: 'FightCamp Website',
    attribution: attribution.UTM,
  },
  otherOption({ id: `${id} Other`, attribution: attribution.Unknown }),
 ],
});

const google = id => ({
  id: `${id} Search`,
  question: 'What did you search?',
  type: 'input',
  followup: googleSearch(`${id} Search`),
  attribution: attribution.UTM,
});


module.exports = google;
