import { isEmpty } from '~/utils/helpers';
import { LOCAL_EXPERIMENT_OVERRIDE_KEY } from '../constants/localStorage';

export function getExperimentById(state, id) {
  let overrideTogglesMap;
  const isProd = process.env.NUXT_ENV_IS_PROD === 'true';

  if (!isProd && process.client) {
    overrideTogglesMap = JSON.parse(window.sessionStorage.getItem(LOCAL_EXPERIMENT_OVERRIDE_KEY));
  }

  return !!process.env.NUXT_ENV_ENABLE_OVERRIDABLE_TOGGLES && hasToggleOverride(overrideTogglesMap, id)
    ? overrideTogglesMap[id]
    : (state.experiments || []).find(exp => exp.id === id);
}

function hasToggleOverride(overrideToggles, id) {
  return overrideToggles && !isEmpty(overrideToggles[id]);
}
