export default {
  title: 'FightCamp Wraps',
  id: 'fightcamp_quickwraps_v2_sale',
  price: 1900,
  oldPrice: 3900,
  category: 'accessory',
  description: 'These Quick Wraps are built to hold your Punch Trackers in place with wrist-wrapping & knuckle padding to protect your hands.',
  fbid: 'shopify_729274908726_8813415235638',
  path: '/shop/equipment',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-equipment-wraps-001.jpg',
  marketplace_img_url: '/v4/fc-marketplace-accessory-quickwraps-001.png',
  tag: '51% Off',
  types: ['Essentials']
};
