export default {
  title: 'FightCamp The Works',
  id: 'fightcamp_console_the_works',
  price: 139900,
  monthly: 5829,
  months: 24,
  category: 'package',
  path: '/shop/products/fightcamp-the-works/',
  internalPath: 'shop/products/fightcamp-the-works/',
  img_url: 'https://a.storyblok.com/f/152150/2000x2000/b93e958edd/fc-ultimate-bundle-package-001.jpg', // TODO - Update image
  description: 'The ultimiate FightCamp bundle', // TODO - Update description
};
