export default {
  title: 'FightCamp Wraps (3-Pack)',
  id: 'fightcamp_quickwraps_3pack',
  price: 9900,
  category: 'accessory',
  description: '',
  img_url: '/v4/fc-accessories-quickwraps-3pack-001.png',
  fbid: 'shopify_729274908726_8813415235638',
  marketplace_img_url: '/v4/fc-marketplace-accessory-quickwraps-pack-001.png',
  types: ['Essentials']
};
