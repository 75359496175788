export const bundleTrainFighterSale = {
  title: 'FightCamp Train Like a Fighter Bundle',
  id: 'fightcamp_bundle_train_fighter_sale',
  price: 8900,
  fullPrice: 11600,
  oldPrice: 11600,
  category: 'accessory',
  description: 'The best fighters keep their body guessing. Add a variety of strength and cardio training into your weekly routine.',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-bundle-train-fighter-001.png',
  images: [
    'fc-bundle-train-001.jpg',
    'fc-bundle-train-002.jpg',
    'fc-bundle-train-003.jpg',
  ],
  fbid: '',
};
