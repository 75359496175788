

















import { defineComponent, PropType, computed } from '@vue/composition-api';
import { isMobileEffect } from '~/effects/matchMedia';

export type TBgColor = 'fc-bg-eggshell' | 'fc-bg-blue' | 'fc-bg-white';

export default defineComponent({
  name: 'NavigationBar',
  props: {
    bgColor: {
      type: String as PropType<TBgColor>,
      default: 'fc-bg-white',
    },
  },
  setup() {
    // @ts-ignore
    const { $store } = useNuxtApp();
    const route = computed(() => $store.getters.currentRoute);
    const isMobile = isMobileEffect();

    const getPosition = computed(() => ((route.value.name === 'shop-products-fightcamp' && isMobile.value) ? 'static' : 'sticky'));

    return {
      getPosition,
    };
  },
});
