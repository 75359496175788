const enum navTypes {
  primary = 'primary',
  secondary = 'secondary',
  shop = 'shop',
  checkout = 'checkout',
  confirmation = 'confirmation',
  marketplace = 'marketplace',
  gq = 'gq',
}

export default navTypes;
