const attribution = require('./utils/attribution');
const otherOption = require('./utils/other');
const postInfluencer = require('./utils/influencer');

const facebookPostGroup = {
  id: 'Facebook Post Group',
  question: 'What facebook group was it?',
  type: 'input',
  attribution: attribution.Referral,
};

const facebookTagGroup = {
  id: 'Facebook Tag Group',
  question: 'What facebook group was it?',
  type: 'input',
  attribution: attribution.Referral,
};

const facebookShare = {
  id: 'Facebook Share',
  question: 'What was it?',
  type: 'list',
  options: [
    {
      id: 'Facebook Share Story',
      label: "A story from FightCamp's page",
      attribution: attribution.UTM,
    },
    {
      id: 'Facebook Share Post',
      label: "A post from FightCamp's page",
      attribution: attribution.UTM,
    },
    {
      id: 'Facebook Share Website',
      label: 'A link to the FightCamp website',
      attribution: attribution.Referral,
    },
    {
      id: 'Facebook Share App',
      label: 'A link to the FightCamp App',
      attribution: attribution.Referral,
    },
    {
      id: 'Facebook Share Message',
      label: 'A Facebook message talking about FightCamp',
      attribution: attribution.Referral,
    },
    {
      id: 'Facebook Share Community',
      label: 'Invited you to the FightCamp Facebook Community',
      attribution: attribution.Referral,
    },
    otherOption({ id: 'Facebook Share Other', attribution: attribution.Unknown }),
  ],
};


const facebookPost = {
  id: 'Facebook Post',
  question: 'Who was it?',
  type: 'list',
  options: [
    {
      id: 'Facebook Post Referral',
      label: 'Someone you know personally',
      attribution: attribution.Referral,
    },
    {
      id: 'Facebook Post Group',
      label: 'Someone in a Facebook group',
      followup: facebookPostGroup,
      attribution: attribution.Referral,
    },
    {
      id: 'Facebook Post Influencer',
      label: 'Content Creator / Celebrity / Influencer you follow',
      followup: postInfluencer('Facebook Post Influencer'),
      attribution: attribution.UTM,
    },
    otherOption({ id: 'Facebook Post Other', attribution: attribution.Unknown }),
  ],
};

const facebookTag = {
  id: 'Facebook Tag',
  question: 'What was it?',
  type: 'list',
  options: [
    {
      id: 'Facebook Tag Group',
      label: 'A post in a facebook group',
      followup: facebookTagGroup,
      attribution: attribution.Referral,
    },
    {
      id: 'Facebook Tag Page',
      label: 'A post from FightCamp\'s page',
      attribution: attribution.UTM,
    },
    {
      id: 'Facebook Tag Referral',
      label: 'A post from someone about FightCamp',
      attribution: attribution.Referral,
    },
    otherOption({ id: 'Facebook Tag Other', attribution: attribution.Unknown }),
  ],
};

const facebookAd = {
  id: 'Facebook Ad',
      question: 'What was in the ad?',
  type: 'input',
};

const facebook = {
  id: 'Facebook',
  type: 'list',
  question: 'What did you see on Facebook?',
  options: [{
      id: 'Facebook Ad',
      label: 'Advertisement',
      followup: facebookAd,
      attribution: attribution.PaidSocial,
    },
    {
      id: 'Facebook Share',
      label: 'Someone shared something with you',
      followup: facebookShare,
      attribution: attribution.Referral,
    },
    {
      id: 'Facebook Post',
      label: 'Someone posted about FightCamp',
      followup: facebookPost,
      attribution: attribution.Referral,

    },
    {
      id: 'Facebook Tag',
      label: 'Someone tagged you in something',
      followup: facebookTag,
      attribution: attribution.Referral,

    },
    otherOption({ id: 'Facebook Other', attribution: attribution.Unknown }),
  ],
};

module.exports = facebook;
