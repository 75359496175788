export default {
  title: 'Traditional Wraps',
  id: 'fightcamp_traditional_wraps',
  price: 1900,
  category: 'accessory',
  description: 'Crafted for those who love a traditional wrap, and prefer the flexibility to add padding & support where you need it most.',
  img_url: '',
  fbid: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-traditional-wraps-001.png',
  types: ['Essentials'],
  sold_out: true,
};
