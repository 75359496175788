<template>
  <div ref="target" class="scroll_transition">
    <div class="scroll_transition__animator" :class="animationClass">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
  import {
    onMounted, ref, onUnmounted, computed, defineComponent,
  } from '@vue/composition-api';
  import { IntersectionObserverInit } from 'intersection-observer';
  import { AnimationMode } from './types';

  export default defineComponent({
    name: 'ScrollTransition',
    props: {
      animationType: {
        type: String,
        required: false,
        default: AnimationMode.FADE,
      },
      threshold: {
        type: Array,
        required: false,
        default: () => ([0]),
      },
      rootMargin: {
        type: String,
        required: false,
        default: '0px',
      },
    },
    setup(props) {
      const target = ref<HTMLElement>();
      const animate = ref<boolean>(false);
      const animationClass = computed(() => [
        props.animationType || AnimationMode.FADE,
        { enabled: animate.value },
      ]);

      const options: IntersectionObserverInit = { root: null, rootMargin: props.rootMargin, threshold: props.threshold as number[] };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          animate.value = entry.isIntersecting || entry.boundingClientRect.y < 0;
        });
      }, options);

      onMounted(() => {
        if (target.value) {
          observer.observe(target.value);
        }
      });

      onUnmounted(() => {
        if (target.value) {
          observer.unobserve(target.value);
        }

        observer.disconnect();
      });

      return {
        animate,
        target,
        animationClass,
      };
    },
  });
</script>

<style lang="scss" scoped>
  .scroll_transition {
    &__animator {
      transition: all 1.5s cubic-bezier(0, 0.55, 0.45, 1);
      will-change: scroll-position;
      transform: translateZ(0);
      transform-style: preserve-3d;
      backface-visibility: hidden;

      &.fade {
        opacity: 0;

        &.enabled {
          opacity: 1;
        }
      }

      &.zoom-in {
        opacity: 0;
        transform: scale(2, 2);

        &.enabled {
          opacity: 1;
          transform: scale(1, 1);
        }
      }

      &.slide {
        opacity: 0;
        transform: translate3d(0, 300px, 0);

        &.enabled {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
</style>
