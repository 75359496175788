export default {
  title: 'FightCamp Mat',
  id: 'fightcamp_mat',
  price: 24900,
  oldPrice: 34900,
  category: 'accessory',
  description: '',
  img_url: '',
  marketplace_img_url: '/v4/fc-marketplace-accessory-flooring-001.png',
};
