








import { PropType, defineComponent, computed } from '@vue/composition-api';
import { BackgroundColor, FCCardProps } from './types';

export default defineComponent({
  name: 'FCCard',
  props: {
    bgColor: {
      type: String as PropType<BackgroundColor>,
      default: 'white',
      required: false,
      validator: (color: BackgroundColor) => typeof color === 'string',

    },
    noShadow: {
      type: Boolean,
      default: false,
      required: false,
    },
    noRadius: {
      type: Boolean,
      default: false,
      required: false,
    },
    noHoverEffect: {
      type: Boolean,
      default: true,
      required: false,
    },
    padding: {
      type: String,
      default: 'fc-padding-8',
      required: false,
    },
  },
  setup(props: FCCardProps) {
    return {
      cardClasses: computed(() => {
        const classes = [];
        classes.push(`fc-bg-${props.bgColor}`);
        classes.push(props.padding);

        if (props.noShadow) classes.push('no-shadow');
        if (props.noRadius) classes.push('no-radius');
        if (props.noHoverEffect) {
          classes.push('no-hover');
        } else {
          classes.push(`fc-border-${props.bgColor}`);
        }

        return classes;
      }),
    };
  },
});
