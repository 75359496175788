<template>
  <div class="announcement-snack-bar fc-hidden fc-bold fc-align-center fc-justify-between fc-padding-x-0 fc-padding-y-0 fc-margin-x-0 fc-margin-y-0" :class="getAnnouncementSnackBarClasses">
    <a
      class="announcement-snack-bar__text"
      :class="getTextColor"
      @click="routeToPage">
      {{ title }}<span class="announcement-snack-bar__cta-text fc-bold fc-inline-block"> {{ ctaText }} </span>
    </a>
    <FontAwesomeIcon
      class="announcement-snack-bar__icon"
      :icon="['fas', 'times']"
      @click="closeBar" />
  </div>
</template>

<script lang="ts">
  import {
    ref, computed, defineComponent, PropType,
  } from '@vue/composition-api';
  import { ValidColors } from './types';

  export default defineComponent({
    name: 'AnnouncementSnackBar',
    props: {
      title: {
        type: String,
        default: '',
        required: true,
      },
      ctaText: {
        type: String,
        default: '',
        required: false,
      },
      bgColor: {
        type: String as PropType<ValidColors>,
        default: 'red',
        required: false,
      },
      textColor: {
        type: String as PropType<ValidColors>,
        default: 'white',
        required: false,
      },
    },
    setup(props, context) {
      const active = ref<boolean>(true);

      function routeToPage(): void {
        context.emit('onRoutetoPage');
      }

      function closeBar(): void {
        active.value = !active.value;
      }

      return {
        active,
        routeToPage,
        closeBar,
        getAnnouncementSnackBarClasses: computed<string[]>(() => {
          const classes = [];

          if (active.value) {
            classes.push('announcement-snack-bar--active fc-flex fc-padding-x-5 fc-padding-y-2 fc-margin-x-1 fc-margin-y-1');
          }

          classes.push(`fc-bg-${props.bgColor}`);
          classes.push(`fc-text-${props.textColor}`);

          return classes;
        }),
        getTextColor: computed<string>(() => `fc-text-${props.textColor}`),
      };
    },
  });
</script>

<style scoped lang="scss">
  @import "../../styles/exports";

  .announcement-snack-bar {
    flex: 1 1 auto;
    text-align: center;
    font-size: get-font-size-level(6);
    border-radius: 5px;
    cursor: pointer;

    @media #{$tablet} {
      flex: 0 1 auto;
    }

    @media #{$desktop} {
      flex: 0 1 auto;
    }

    &__text {
      font-family: $font-stack-regular;
      font-size: get-font-size-level(5);
      white-space: wrap;

      @media #{$tablet-up} {
        font-size: get-font-size-level(6);
      }
    }

    &__cta-text {
      text-decoration: underline;
      white-space: nowrap;
      padding: get-spacing-level(0) get-spacing-level(1);
      text-transform: none;
    }

    &__icon {
      font-size: get-font-size-level(7);
      margin: get-spacing-level(0) get-spacing-level(1) get-spacing-level(0) get-spacing-level(3);
    }
  }
</style>
