// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from '@nuxt/types';
// docs for storefront-api-client can be found https://github.com/Shopify/shopify-api-js/tree/main/packages/storefront-api-client
import { createStorefrontApiClient } from '@shopify/storefront-api-client';
// @ts-ignore
import fetch from 'isomorphic-fetch';
import { ShopifyService } from '@fc/app-common';
import Bugsnag from '~/services/BugSnagService';

export const shopifyClient = createStorefrontApiClient({
  storeDomain: process.env.NUXT_ENV_SHOPIFY_STORE_DOMAIN || '',
  apiVersion: process.env.NUXT_ENV_SHOPIFY_API_VERSION || '',
  publicAccessToken: process.env.NUXT_ENV_SHOPIFY_STOREFRONT_TOKEN,
  // removing customFetchApi results in storefront-api-client error because fetch is undefined on serverside/build
  customFetchApi: fetch,
});

const eCommPlugin: Plugin = async (context, inject) => {
  const store = context.app.store;
  const id = process.env.NUXT_ENV_SHOPIFY_COLLECTION_ID ?? null;
  const eCommService = new ShopifyService(shopifyClient);

  try {
    if (store && id) {
      const resp = await eCommService.getCollection({ id });
      store.commit('SET_COLLECTION', resp);
    }
  } catch (error) {
    Bugsnag.notify(error as Error);
  }

  inject('eCommService', eCommService);
};

export default eCommPlugin;
