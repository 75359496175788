import product from '@/shared/datasets/products';

export const keepFresh = {
  title: 'Keep It Fresh Bundle',
  text: 'You’re going to sweat a lot. Avoid a smelly workout with fresh gloves & wraps ready to go. ',
  product: product.bundleKeepFresh,
  img_url: 'v4/fc-bundle-keep-fresh-001.png',
  img_alt: 'Set of glove freshners, glove bag, laundry bag, quick wraps 3-pack.',
  item_id: 'fightcamp_bundle_keep_fresh',
  sold_out: false,
  includes: [
    product.quickwraps3pack,
    product.fightcampLaundryBag,
    product.fightcampGloveFreshener,
    product.fightcampGloveBag,
  ],
  selects: {
    name: ' Size - Pair 1',
    key: 'pair1',
    hint: 'Select Quick Wraps sizes',
    options: [{
      key: 'fightcamp_bundle_keep_fresh_SSS',
      name: 'Small',
    },
    {
      key: 'fightcamp_bundle_keep_fresh_LLL',
      name: 'Large',
    },
    {
      key: 'fightcamp_bundle_keep_fresh_SSL',
      name: '2 Small + 1 Large',
    },
    {
      key: 'fightcamp_bundle_keep_fresh_SLL',
      name: '2 Large + 1 Small',
    },
    ],
  },
  discount: 'SAVE 15%',
  types: ['Bundles'],
};
