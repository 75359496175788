export function getAppBranchUrl(store) {
  return `${getBranchBaseUrl()}${buildQueryStringParameter(store)}`;
}

function getBranchBaseUrl() {
  return isProduction()
    ? 'https://fightcamp.app.link/RNKIJolqkkb'
    : 'https://fightcamp.test-app.link/SO2pU9eqkkb';
}

function buildQueryStringParameter(store) {
  const queryParams = {};

  if (store.getters.confirmation) {
    queryParams.fcid = store.getters.confirmation.uid;
    queryParams.fcoid = store.getters.confirmation.orderID;
    queryParams.fcsid = store.getters.confirmation.order && store.getters.confirmation.order.orderID;
  }

  const queryEntries = Object.entries(queryParams)
    .filter(([, value]) => value !== undefined)
    .map((entry) => entry.join('='));

  return queryEntries.length ? `/?${queryEntries.join('&')}` : '';
}

function isProduction() {
  return process.env.NODE_ENV === 'production';
}
