import { VideoPlayback, VideoProps } from '@fc/angie-ui/src/components/Video/types';

export interface MediaBackgroundProps {
  imageSrc?: string,
  imageAlt?: string,
  firstFrameImageUrl?: string,
  webmVideo?: string,
  mp4Video?: string,
  webmMobileVideo?: string,
  mp4MobileVideo?: string,
  isVideoOverlay?: boolean,
  mobileVideoPlayback?: VideoPlayback,
  tabletUpVideoPlayback?: VideoPlayback,
  mediaPosition?: Position,
  backgroundColor?: BGColor,
  isFullWidth?: boolean,
  videoComponent?: VideoProps,
  pictureResponsiveComponent?: any, // TODO update these props
}

export enum Position {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum BGColor {
  red = 'red',
  blue = 'blue',
  bluetint = 'bluetint',
  white = 'white',
  black = 'black',
  eggShell ='eggShell',
  darkgray = 'darkgray',
  lightgray = 'lightgray',
}

export interface ShowVideoValues {
  webmMobile: boolean,
  webmTabletUp: boolean,
  safariMobile: boolean,
  safariTabletUp: boolean,
}

export default {};
