// eslint-disable-next-line import/no-extraneous-dependencies
import { Context } from '@nuxt/types';
import navTypes from '~/store/constants/navTypes';
import storeMutations from '~/store/constants/storeMutations';

export default async function confirmation({
  store,
  route,
  redirect,
}: Context) {
  if (route.name === 'shop-confirmation' && store.state.checkout.confirmation == null) {
    return redirect({ path: '/' });
  }

  store.commit(storeMutations.SET_NAV_TYPE, navTypes.confirmation);
  return null;
}
