


























import {
  defineComponent, ref, watch, onMounted, PropType, computed,
} from '@vue/composition-api';
import { ISelect, SizeSelectorsProps } from './types';

export default defineComponent({
  name: 'SizeSelectors',
  emits: ['selectedValue'],
  props: {
    selects: {
      // TODO remove SizeSelectorsProps type once all accessories are moved to cms fc-products
      type: Array as PropType<ISelect[] | SizeSelectorsProps[]>,
      required: true,
      default: () => [
        {
          id: '',
          name: '',
          uid: '',
        },
      ],
    },
  },
  setup(props: any, context) {
    const selectedValue = ref(props.selects[0].id);

    watch(selectedValue, (newVal) => {
      context.emit('selectedValue', newVal);
    });

    onMounted(() => {
      context.emit('selectedValue', selectedValue.value);
    });

    const sizeDescription = computed(() => {
      const selectedOption = props?.selects?.options?.find((item: SizeSelectorsProps) => item.key === selectedValue.value);
      return selectedOption ? selectedOption.htc : '';
    });

    return {
      selectedValue,
      showSizeDescription: computed(() => props?.selects?.options[0]?.htc),
      sizeDescription,
    };
  },
});
