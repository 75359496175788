export default {
  title: 'FightCamp Gloves',
  id: 'fightcamp_gloves_v2',
  price: 14900,
  category: 'accessory',
  description: '',
  path: '/shop/equipment',
  img_url: 'https://cdn3.joinfightcamp.com/1000x0/v4/fc-equipment-gloves-001.jpg',
  fbid: 'shopify_742223380534_8963688726582',
  marketplace_img_url: '/v4/fc-marketplace-accessory-gloves-001.png',
  tag: 'Recommended',
  types: ['Essentials']
};
