export default {
  props: {
    disabled: Boolean,
    secondary: Boolean,
    primary: Boolean,
    dark: Boolean,
    red: Boolean,
    tertiary: Boolean,
    size: {
      type: String, default: 'normal',
    },
  },
  computed: {
    sizeStyle() {
      if (this.size === 'small') {
        return 'button-small';
      } if (this.size === 'big') {
        return 'button-big';
      }
      return '';
    },
    buttonStyle() {
      if (this.disabled) {
        return `button-layout button-disabled ${this.sizeStyle}`;
      }

      if (this.secondary && this.dark) {
        return `button-layout button-dark-secondary ${this.sizeStyle}`;
      }
      if (this.dark) {
        return `button-layout button-dark ${this.sizeStyle}`;
      }
      if (this.secondary && this.red) {
        return `button-layout button-red-secondary ${this.sizeStyle}`;
      }
      if (this.secondary) {
        return `button-layout button-secondary ${this.sizeStyle}`;
      }
      if (this.primary) {
        return `button-layout button-primary ${this.sizeStyle}`;
      }
      if (this.tertiary) {
        return `button-layout button-tertiary ${this.sizeStyle}`;
      }
      return `button-layout button-normal ${this.sizeStyle}`;
    },

  },
};
