// https://image.nuxtjs.org/advanced/custom-provider/
// eslint-disable-next-line import/no-extraneous-dependencies
import { ImageOptions, ProviderGetImage } from '@nuxt/image';
import { joinURL } from 'ufo';
import {
  constructImageDimensions,
  constructImageFilters,
} from '../helpers';
import { IImageFilters } from '../types';

/**
 * @description
 * FCImage provider, it should output an image url like so:
 * @example
 * {
 *   url: 'https://cdn3.joinfightcamp.com/500x500/filters:format(webp)/v4/fc-home-woman-punching-bag-001.png'
 * }
 */
export const getImage: ProviderGetImage = (src: string, imageOptions: ImageOptions) => {
  const {
    modifiers,
    sizes,
    baseUrl,
  } = imageOptions ?? {};
  const {
    currentWidth,
    apiImageFormat,
  } = modifiers ?? {};

  const filters: IImageFilters = {};

  if (apiImageFormat) {
    filters.format = apiImageFormat;
  }

  const url = joinURL(
    baseUrl,
    constructImageDimensions(currentWidth, sizes),
    constructImageFilters(filters),
    src,
  );

  if (src.includes('cdn.shopify.com')) { // TODO create proper provider for Shopify
    return {
      url: src,
    };
  }

  return {
    url,
  };
};
