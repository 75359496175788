const attribution = require('./utils/attribution');

const apparel = ({ id, attribution }) => ({
  id: `${id} Apparel`,
  question: 'Did you see them wearing FightCamp apparel?',
  type: 'select',
  options: [{
    id: `${id} Apparel`,
    label: 'Yes',
    attribution: attribution,
  },
  {
    id: id,
    label: 'No',
    attribution: attribution,
  }],
});

module.exports = apparel;
