
import { computed, defineComponent } from '@vue/composition-api';

export const AFFIRM_LOGOS = new Map([
  ['white', 'https://cdn-assets.affirm.com/images/white_logo-solid_bg.svg'],
  ['indigo', 'https://cdn-assets.affirm.com/images/black_logo-white_bg.svg'],
]);

export default defineComponent({
  name: 'AffirmLogo',
  props: {
    color: {
      type: String as () => 'white' | 'indigo',
      required: false,
      default: 'indigo',
    },
  },
  setup(props) {
    const logoData = AFFIRM_LOGOS;

    return {
      affirmLogo: computed(() => (logoData.has(props.color) ? logoData.get(props.color) : logoData.get('indigo'))),
    };
  },
});
