import product from '@/shared/datasets/products';

export const trainFighter = {
  title: 'Train Like a Fighter Bundle',
  text: 'The best fighters keep their body guessing. Add a variety of strength and cardio training into your weekly routine.',
  product: product.bundleTrainFighter,
  img_url: 'v4/fc-bundle-train-fighter-001.png',
  img_alt: 'Set of FightCamp resistance bands, jump rope, agility ladder, yoga mat',
  item_id: 'fightcamp_bundle_train_fighter',
  sold_out: true,
  includes: [
    product.fightcampResistanceBands,
    product.fightcampJumpRope,
    product.fightcampAgilityLadder,
    product.fightcampYogaMat,
  ],
  discount: 'SAVE 15%',
  types: ['Bundles'],
};
